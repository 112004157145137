/**=====================
    Badge CSS start
==========================**/
.badge {
    padding: $badge-padding;

    & + .badge {
        margin-left: 5px;
    }

    svg {
        width: $badge-svg-size;
        height: $badge-svg-size;
        padding-top: 3px;
    }
}

[class^="status-"] {
    span {
        padding: 5px 10px;
        text-transform: capitalize;
        display: inline-block;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 400;
    }
}

.status-danger,
.status-out_of_stock,
.status-failed,
.status-debit {
    span {
        color: #e22454;
        border: 1px solid #e22454;
    }
}

.status-success,
.status-in_stock,
.status-completed,
.status-approved,
.status-delivered,
.status-credit {
    span {
        color: #2f8733;
        border: 1px solid #2f8733;
    }
}

.status-pending,
.status-draft {
    span {
        color: #db8827;
        border: 1px solid #db8827;
    }
}

.status-processing {
    span {
        color: #0077b3;
        border: 1px solid #0077b3 ;
    }
}

.status-refund,
.status-cancelled,
.status-rejected {
    span {
        color: #d9534f;
        border: 1px solid #d9534f ;
    }
}

.status-awaiting_for_approval {
    span {
        color: #7e7e7e;
        border: 1px solid #7e7e7e ;
    }
}

.badge-theme {
    background-color: var(--theme-color);
}

// flat badge loop css
@each $pill-badge-name, $pill-badge-color in (primary, var(--theme-color)), (secondary, $secondary-color),
    (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color),
    (warning, $warning-color)
{
    .pill-badge-#{$pill-badge-name} {
        background-color: $pill-badge-color;
        box-shadow: -0.5px 4.33px 16px 0px rgba($pill-badge-color, 0.25);
        color: $white;
        border-radius: 0;
    }
}

.pill-badge-primary {
    background-color: var(--theme-color);
}

.pill-badge-secondary {
    background-color: var(--theme-secondary);
}

// round badge loop css
@each $round-badge-name, $round-badge-color in (primary, var(--theme-color)), (secondary, $secondary-color),
    (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color),
    (warning, $warning-color)
{
    .round-badge-#{$round-badge-name} {
        border-radius: 6px;
        background-color: $round-badge-color;
        box-shadow: -0.5px -6.67px 14px 0px rgba($round-badge-color, 0.18);
        color: $white !important;
    }
}

.round-badge-primary {
    background-color: var(--theme-color);
}

.round-badge-secondary {
    background-color: var(--theme-secondary);
}
