/**=====================
     Responsive CSS Start
==========================**/

/* ========= min and max scss for 1200 to 1366 screen ========= */
@media (max-width: 1836px) {
    .pie-chart {
        .apexcharts-legend-text {
            line-height: unset;
        }
    }
}

@media (max-width: 1800px) {
    // chat page
    .caller-img {
        display: none;
    }
}

@media screen and (max-width: 1660px) {
    .photos {
        ul {
            li {
                width: 26%;
            }
        }
    }

    .bar-chart {
        canvas {
            width: 100% !important;
        }
    }

    .chat-box {
        .chat-history {
            .call-icons {
                ul {
                    li {
                        border: 1px solid $dark-gray;
                    }
                }
            }

            .total-time {
                h2 {
                    color: $dark-gray;
                }
            }

            .call-content {
                .btn {
                    width: auto;
                    margin: 0 auto;
                }

                > div {
                    z-index: 7;
                    background-color: rgba($white, 0.75);
                    background-blend-mode: overlay;
                    width: 100%;
                    padding: 30px;
                }
            }

            .receiver-img {
                margin-top: 25px;
            }
        }
    }

    .chat-left-aside {
        .people-list {
            height: 520px;
        }
    }

    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-msg-box {
                    height: 440px;
                }
            }
        }

        .people-list {
            ul {
                max-height: 450px;
                overflow: auto;
            }
        }
    }

    .call-chat-body {
        .chat-box {
            .people-list {
                ul {
                    max-height: 550px;
                    overflow: auto;
                }
            }

            overflow: hidden;
            height: 625px;
        }
    }

    .caller-img {
        position: absolute;
        width: 100%;
        max-width: 100%;
        left: 15px;

        img {
            opacity: 0.7;
        }
    }

    // timeline small page
    .timeline-small {
        .media {
            .timeline-round {
                &.timeline-line-1 {
                    &:after {
                        bottom: -78px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .page-wrapper {
        &.horizontal-wrapper {
            &.material-type {
                .left-header {
                    .level-menu,
                    .mega-menu {
                        .nav-link {
                            svg {
                                margin-right: 0;
                            }

                            span {
                                display: none;
                            }
                        }
                    }
                }

                .page-header {
                    max-width: 1320px;
                }

                .page-body-wrapper {
                    .sidebar-wrapper,
                    .page-body {
                        max-width: 1320px;
                    }
                }
            }
        }
    }

    // chat page
    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-header {
                    .chat-menu-icons {
                        li {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1535px) {
    .custome-1-bg,
    .custome-2-bg,
    .custome-3-bg,
    .custome-4-bg {
        padding: 25px 20px !important;
    }
}

@media screen and (max-width: 1428px) {
    .category-box {
        .content-category {
            padding: 60px 20px 25px 20px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .custome-1-bg,
    .custome-2-bg,
    .custome-3-bg,
    .custome-4-bg {
        padding: 30px 40px !important;
    }

    .datepicker-dashboard {
        .datepicker--days-names {
            font-size: 18px;
            margin: 18px 0;
        }

        .datepicker--cells-days {
            height: 200px;
        }

        .datepicker {
            height: 360px;
        }

        .datepicker--nav-action {
            height: 30px;
            width: 30px;
        }

        .datepicker--nav-title {
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .btn-group {
        .btn {
            padding: 0.375rem 0.75rem;
        }
    }
}

@media screen and (max-width: 668px) {
    .custome-1-bg,
    .custome-2-bg,
    .custome-3-bg,
    .custome-4-bg {
        padding: 25px 20px !important;
    }
}

@media screen and (max-width: 1380px) {
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        td:nth-child(2) {
                            width: 100% !important;
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        td:first-child {
                            width: 100%;
                            display: block;

                            img {
                                width: 100% !important;
                            }
                        }

                        .room-title {
                            margin: 20px 0;
                        }

                        .room-detail {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1660px) and (min-width: 1200px) {
    .xl-none {
        display: none;
    }

    .xl-60 {
        max-width: 60%;
        flex: 0 0 60%;
    }

    .xl-50 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .xl-100 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .xl-23 {
        max-width: 23%;
        flex: 0 0 23%;
    }

    .xl-25 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .xl-40 {
        max-width: 40%;
        flex: 0 0 40%;
    }

    .xl-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
}

@media (max-width: 1280px) {
    .img-gallery-set1,
    .img-gallery-set5 {
        display: none;
    }

    .box-layout.page-wrapper.horizontal-wrapper {
        .page-header {
            padding-left: unset;
            max-width: unset;
            left: unset;
        }

        .page-body-wrapper {
            width: unset;
        }
    }
}

@media screen and (max-width: 1580px) and (min-width: 1200px) {
    .docs-buttons,
    .docs-toggles {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 1445px) {
    .user-table {
        tbody {
            tr {
                td:nth-child(2) {
                    min-width: 185px;
                }

                td:nth-child(3) {
                    min-width: 160px;
                }
            }
        }
    }
}

@media (max-width: 1470px) {
    //edit-profile
    .edit-profile {
        .profile-title {
            .media {
                display: block;
                margin-bottom: 10px;

                .media-body {
                    margin-left: unset;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) and (min-width: 1200px) {
    .flot-chart-container-small {
        height: 227px;
    }

    .left-header .mega-menu-container {
        min-width: 920px;
    }

    .call-chat-sidebar {
        max-width: 262px;
    }

    .product-box {
        .product-details {
            padding: 15px;

            p {
                margin-bottom: 10px;
            }
        }
    }

    .flot-chart-container {
        height: 130px;
    }

    .custom-card {
        .card-profile {
            img {
                height: 115px;
            }
        }

        .profile-details {
            h4 {
                font-size: 18px;
            }

            h6 {
                margin-bottom: 24px;
            }
        }

        .card-footer {
            > div {
                h3 {
                    font-size: 18px;
                }
            }
        }
    }

    .crm-activity {
        height: 291px;
        overflow-y: auto;

        .media {
            .media-body {
                .dates {
                    display: flex;
                }
            }
        }
    }

    .calender-widget {
        .cal-date {
            width: 95px;
            height: 95px;

            h5 {
                line-height: 1.4;
                padding: 13px;
            }
        }
    }

    // chat
    .chat-body {
        padding: 10px;
    }

    .chat-box {
        .chat-history {
            .call-content {
                .btn {
                    font-size: 15px;
                }
            }

            .total-time h2 {
                font-size: 30px;
            }

            .receiver-img {
                margin-top: 30px;
            }

            .call-icons {
                margin-top: 20px;
                margin-bottom: 20px;

                ul {
                    li {
                        width: 50px;
                        height: 50px;
                        padding: 7px;
                    }
                }
            }
        }

        .user-image {
            width: 30px;
            height: 30px;
            margin-top: 3px;
            margin-right: 3px;
        }

        .about {
            margin-top: 0;
        }

        .border-tab.nav-tabs {
            .nav-item {
                a {
                    padding: 13px 4px !important;
                    color: $semi-dark !important;
                    letter-spacing: 0;
                    font-size: 12px !important;
                    line-height: 6;
                }
            }
        }

        .chat-menu {
            .people-list {
                ul.list {
                    max-height: 376px;
                    overflow-y: auto;
                }
            }

            .user-profile {
                .image {
                    .avatar {
                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .icon-wrapper {
                        width: 30px;
                        height: 30px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .chat-left-aside {
        .people-list {
            height: 420px;

            ul {
                max-height: 376px;
                overflow-y: auto;
            }
        }

        .status-circle {
            top: 4px;
            left: 36px;
        }
    }

    //blog scss
    .blog-box {
        .blog-details-main {
            .blog-bottom-details {
                margin: 20px 10px;
            }

            .blog-social {
                li {
                    padding: 0px 10px;
                }
            }
        }
    }

    .blog-bottom-content {
        display: none;
    }

    .blog-box.blog-shadow {
        .blog-details {
            padding: 10px;
        }
    }

    .browser-widget {
        .media-img {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }

        img {
            height: 50px;
        }
    }

    // email-app
    .email-wrap {
        .email-left-aside {
            .email-app-sidebar {
                .media {
                    display: block;

                    .media-size-email {
                        width: 100%;
                        text-align: center;

                        .mr-3 {
                            margin-right: 0 !important;
                        }
                    }

                    .media-body {
                        text-align: center;
                    }
                }
            }
        }

        .email-right-aside {
            .email-body {
                .attachment {
                    ul {
                        li {
                            img {
                                width: 73px;
                                height: 73px;
                            }
                        }
                    }
                }

                .email-top {
                    line-height: 50px;

                    .float-right {
                        display: none !important;
                    }

                    h5 {
                        padding: 13px 0;
                    }
                }

                .inbox {
                    max-height: 668px;
                    overflow: auto;

                    .media {
                        display: block;

                        .media-size-email {
                            width: 100%;
                            text-align: center;

                            .mr-3 {
                                margin-right: 0 !important;
                            }
                        }
                    }

                    .media-body {
                        text-align: center;

                        h6 {
                            font-size: 14px;
                        }
                    }
                }

                .email-compose {
                    .cke_contents.cke_reset {
                        max-height: 144px;
                        width: 99%;
                    }

                    .compose-border {
                        margin: 0;
                    }
                }
            }
        }
    }

    // alert page
    .alert-dismissible {
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 181px;
        }
    }

    // touchspin page
    .bootstrap-touchspin {
        .touchspin-vertical-tab {
            .input-group {
                width: 96% !important;
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .left-header {
        padding: 0;
    }

    .bookmark-wrap {
        .task-sidebar {
            height: auto;
        }
    }

    // product page
    .grid-options {
        ul {
            li {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-img {
                    width: 25%;
                }
            }
        }
    }

    // cart page
    .cart {
        .qty-box {
            width: 40%;
        }
    }

    // alert page
    .alert-dismissible {
        strong {
            & ~ p {
                display: inline;
            }
        }
    }

    // timeline small page
    .timeline-small {
        .media {
            .media-body {
                p {
                    width: 95%;
                }
            }
        }
    }

    .map-js-height {
        height: 350px;
    }

    // chat page
    .chat-right-aside {
        flex: 0 0 60%;
        max-width: 60%;
        overflow: hidden;

        .chat {
            .chat-message {
                .smiley-box {
                    width: 45px;
                }
            }
        }
    }

    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-msg-box {
                    height: 364px;

                    .chat-user-img {
                        margin-top: -30px;
                    }

                    .message {
                        width: 100%;
                        padding: 15px;
                    }
                }

                .chat-header {
                    .chat-menu-icons {
                        li {
                            a {
                                i {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .chat-history {
            .receiver-img {
                img {
                    height: 150px;
                }
            }
        }
    }

    .call-chat-body {
        .chat-box {
            overflow: hidden;
            height: 565px;
        }
    }

    .calender-widget {
        .cal-desc {
            p {
                max-height: 88px;
                overflow: auto;
            }
        }
    }

    .equal-height-xl {
        height: 517px;
    }

    .authentication-main {
        .auth-innerright {
            background-size: cover;
        }
    }

    .grp-btns {
        display: inline-block;
    }

    .button-builder {
        .button-generator-bx {
            position: relative;
            width: 100%;
        }
    }

    .page-builder {
        .btn-group {
            display: inline-block;
            margin-bottom: 10px;
        }

        .btn-grid {
            margin-bottom: 15px;
            margin-right: 0px;
            border-right: none;
            display: inline-block;
        }

        .ge-canvas.ge-layout-desktop {
            margin-top: 50px;
        }
    }

    //ecommerce dashboard
    .ecommerce-widget {
        .total-num {
            font-size: 23px;
            letter-spacing: 0px;
        }
    }

    .support-ticket-font {
        .total-num {
            font-size: 22px;
        }
    }

    .order-history {
        table.dataTable.table thead th {
            padding-right: 50px;
        }
    }

    //default dashboard
    .custom-card {
        padding: 15px;

        .card-social {
            li {
                a {
                    padding: 12px;
                }
            }
        }
    }

    .map-chart {
        .chart-container {
            height: 300px;
        }
    }

    .speed-chart {
        .chart-container {
            height: 250px;
            margin-top: -40px;
            margin-bottom: 100px;
        }
    }

    .candidcahrt {
        height: 360px;
    }

    //chart widget
    .status-details {
        h3 {
            font-size: 18px;
        }
    }

    .chart-widget-top {
        .total-value {
            font-size: 20px;
        }
    }

    .status-widget {
        .card-body {
            padding: 10px;
        }
    }

    //general widget
    .user-status {
        table {
            thead {
                tr {
                    th {
                        &:nth-child(3) {
                            min-width: unset;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:first-child {
                            min-width: unset;
                        }
                    }
                }
            }
        }
    }

    .browser-widget {
        .media {
            flex-wrap: wrap;

            .media-body {
                h3 {
                    font-size: 20px;
                }

                p {
                    margin-bottom: 2px;
                }

                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .testimonial {
        i {
            font-size: 46px;
        }

        p {
            margin-top: 20px;
        }
    }

    .cal-date-widget .datepicker {
        margin: 0 auto;
        width: unset;
    }

    .company-card {
        padding: 20px;
    }

    .static-top-widget {
        .media-body {
            h3 {
                font-size: 30px;
            }

            .icon-bg {
                right: -21px;
            }
        }
    }

    .widget-joins {
        .media {
            padding: 20px;

            .media-body {
                h3 {
                    font-size: 20px;
                }
            }
        }
    }

    .redial-social-widget {
        width: 125px;
        height: 125px;

        i {
            width: 100px;
            height: 100px;
            font-size: 35px;
        }
    }

    //chat
    .chat-body {
        padding: 10px;
    }

    .chat-box {
        .chat-history {
            .call-content {
                .btn {
                    font-size: 15px;
                }
            }

            .total-time h2 {
                font-size: 30px;
            }

            .receiver-img {
                margin-top: 30px;
            }

            .call-icons {
                margin-top: 20px;
                margin-bottom: 20px;

                ul {
                    li {
                        width: 50px;
                        height: 50px;
                        padding: 7px;
                    }
                }
            }
        }

        .user-image {
            width: 45px;
            height: 45px;
        }

        .about {
            margin-top: 0;
        }

        .border-tab.nav-tabs {
            .nav-item {
                a {
                    padding: 13px 4px !important;
                    color: $semi-dark !important;
                    letter-spacing: 0;
                    font-size: 12px !important;
                    line-height: 6;
                }
            }
        }

        .chat-menu {
            .people-list {
                ul.list {
                    max-height: 455px;
                    overflow-y: auto;
                }
            }

            .user-profile {
                margin-top: 0;

                .image {
                    .avatar {
                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .icon-wrapper {
                        width: 30px;
                        height: 30px;
                        font-size: 13px;
                    }
                }

                .user-content {
                    p {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    h5 {
                        margin: 20px 0;
                    }

                    hr {
                        margin: 20px 0;
                    }
                }

                .follow {
                    .follow-num {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .chat-left-aside {
        .people-list {
            height: 490px;

            ul {
                max-height: 434px;
                overflow-y: auto;
            }
        }
    }

    .status-circle {
        top: 4px;
        left: 37px;
    }

    // mega-menu
    .mega-menu {
        .onhover-show-div {
            left: 164px;
        }
    }

    // image cropper page
    .img-cropper {
        .docs-toggles {
            margin-top: 8px;
        }
    }

    // touchspin page
    .bootstrap-touchspin {
        .dropdown-basic {
            .dropdown {
                .dropdown-content {
                    left: -21px;
                }
            }
        }
    }

    // datatable advance page
    #advance-5_wrapper {
        .dataTables_paginate {
            margin-bottom: 8px;
        }
    }

    // jsgrid-table page
    #batchDelete {
        .jsgrid-grid-header {
            .jsgrid-table {
                .jsgrid-header-row {
                    .jsgrid-header-cell {
                        &:first-child {
                            width: 67px !important;
                        }
                    }
                }
            }
        }

        .jsgrid-grid-body {
            .jsgrid-table {
                .jsgrid-row {
                    .jsgrid-cell {
                        &:first-child {
                            width: 67px !important;
                        }
                    }
                }
            }
        }
    }

    // forget password page
    .reset-password-box {
        .theme-form {
            .form-group {
                .btn {
                    padding: 6px 22px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) and (min-width: 1200px) {
    .page-wrapper {
        &.horizontal-wrapper {
            .left-header {
                flex: 0 0 32%;
                max-width: 32%;
            }

            .page-header {
                .header-wrapper {
                    .nav-right {
                        flex: 0 0 54%;
                        max-width: 54%;
                    }
                }
            }

            &.material-type {
                .page-header {
                    max-width: 1140px;
                }

                .page-body-wrapper {
                    .sidebar-wrapper,
                    .page-body {
                        max-width: 1140px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199.98px) {
    .left-header .mega-menu-container .mega-box .svg-icon ~ div h6 {
        display: none;
    }

    .wizard-4 {
        ul {
            &.anchor {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }

    .file-sidebar {
        padding-right: 12px;
    }

    .col-xl-7.b-center.bg-size {
        top: 0;
        height: 100vh;
        opacity: 0.5;
        position: fixed;
    }

    .login-card {
        background: transparent;
    }

    .knowledgebase-bg {
        height: 400px;
    }

    .knowledgebase-search {
        .form-inline {
            width: 400px;
            padding: 7px 45px;

            svg {
                top: 14px;
            }
        }
    }

    .btn-group {
        .btn {
            padding: 0.375rem 1.25rem;
        }
    }

    .link-section > div {
        &.active {
            > h6 {
                &:before {
                    content: "\f107";
                    transition: content 0.3s ease;
                }
            }
        }

        > h6 {
            position: relative;

            &:before {
                content: "\f105";
                font-family: fontAwesome;
                position: absolute;
                right: 10px;
                font-size: 18px;
                transition: content 0.3s ease;
            }
        }
    }

    .left-header {
        .mega-menu-container {
            position: fixed;
            width: 300px;
            border: 1px solid rgb(238, 238, 238);
            top: 0px;
            height: 100vh;
            left: unset;
            right: -300px;
            z-index: 99;
            transition: all 0.3s ease;
            overflow: scroll;
            min-width: unset;
            padding-right: 20px;

            .container-fluid {
                padding: 0;
            }

            &.d-block {
                right: 0;
                transition: all 0.3s ease;
                animation: fadeInRight 0.3s ease-in-out;
            }

            .mega-box {
                flex-basis: auto;

                & + .mega-box {
                    border-left: 0px;
                    padding-left: 15px;
                }

                .link-section {
                    .submenu-title {
                        display: flex;
                        justify-content: space-between;
                    }

                    .according-menu {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .alert-theme {
        min-width: 420px;
    }

    .mega-menu-header {
        .header-wrapper {
            .vertical-mobile-sidebar {
                display: block;
                margin-right: 15px;
            }
        }
    }

    // sidebar css
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    position: unset;
                    padding: 0;

                    > ul {
                        li {
                            .submenu {
                                li {
                                    &:first-child {
                                        padding-left: 15px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .mobile-sidebar {
                    .switch-sm {
                        .switch {
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }

    // social app page
    .photos {
        ul {
            li {
                width: auto;

                &:nth-child(3n) {
                    margin-right: 15px;
                }
            }
        }
    }

    .user-profile {
        .hovercard {
            .user-image {
                .share-icons {
                    position: absolute;
                    right: 45px;
                    top: -22px;

                    li {
                        .social-icon {
                            width: 40px;
                            height: 40px;

                            i {
                                font-size: 18px;
                            }
                        }

                        color: #fff;
                        font-size: 25px;
                    }
                }
            }
        }
    }

    // timeline small page
    .timeline-small {
        .media {
            .timeline-round {
                &.timeline-line-1 {
                    &:after {
                        height: 33px;
                        bottom: -52px;
                    }
                }

                &.small-line {
                    &:after {
                        height: 18px;
                        bottom: -34px;
                    }
                }

                &.medium-line {
                    &:after {
                        height: 30px;
                        bottom: -52px;
                    }
                }
            }

            .media-body {
                p {
                    width: 100%;
                }
            }
        }
    }

    .lg-mt-col {
        margin-top: 30px;
    }

    .xl-none {
        display: none;
    }

    .user-status {
        table {
            tbody {
                tr {
                    td {
                        .d-inline-block {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    .lg-mt {
        margin-top: 30px;
    }

    .new-products {
        .owl-carousel {
            .product-box {
                &.row {
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-img {
                    width: 32%;
                }
            }
        }
    }

    .debit-card {
        order: 1;
    }

    .typography {
        .row {
            div {
                & + div {
                    margin-top: 30px;
                }
            }
        }
    }

    .listing {
        .card-body {
            .row {
                > div {
                    & + div {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .flot-chart-container {
        height: 200px;
    }

    .img-cropper {
        #putData {
            margin-bottom: 10px;
        }

        .docs-toggles {
            > .dropdown {
                margin-bottom: 0;
            }
        }
    }

    .error-wrapper {
        .maintenance-icons {
            li {
                i {
                    color: $light-color;
                    font-size: 40px;
                }

                &:nth-child(2) {
                    i {
                        font-size: 80px;
                        margin-left: 90px;
                    }
                }

                &:nth-child(3) {
                    i {
                        font-size: 120px;
                        margin-top: -75px;
                        margin-right: 110px;
                    }
                }
            }
        }
    }

    div {
        &.dataTables_wrapper {
            div {
                &.dataTables_paginate {
                    & ~ .dataTables_filter {
                        display: block;
                        width: 100%;
                        margin-top: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .jvector-map-height {
        height: 280px;
    }

    .blog-list {
        .blog-details {
            p {
                margin-bottom: 5px;
            }
        }
    }

    .custom-card {
        .card-profile {
            .card-footer {
                > div {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .equal-height-lg {
        min-height: unset !important;
    }

    //mega menu
    .mega-menu {
        .onhover-show-div {
            height: 435px;
            overflow-x: scroll;
            left: 215px;
        }

        .mega-bg {
            display: none;
        }

        .lg-mt {
            margin-top: 25px;
        }
    }

    .button-builder {
        .button-generator-bx {
            position: relative;
            width: 100%;
        }
    }

    //blog
    .blog-single {
        .comment-box {
            ul {
                .comment-social {
                    margin-left: 0;
                }
            }
        }
    }

    //error and maintenace
    .error-wrapper {
        .maintenance-heading {
            margin-top: 30px;

            .headline {
                font-size: 50px;
                margin-top: -15%;
                margin-bottom: 0px;
            }

            .cloud-second {
                margin-top: -25px;
            }
        }
    }

    // general widget
    .user-status {
        table {
            tbody {
                tr {
                    td {
                        &:first-child {
                            min-width: 278px;
                        }
                    }
                }
            }
        }
    }

    .testimonial {
        p {
            margin-top: 17px;
        }
    }

    .contact-form {
        padding: 10px;

        .theme-form {
            .form-icon {
                margin-top: -57px;
            }
        }
    }

    .calender-widget {
        .cal-date {
            width: 90px;
            height: 90px;

            h5 {
                font-size: 16px;
                padding: 18px;
            }
        }

        .cal-desc {
            padding: 14px;

            p {
                max-height: 114px;
                overflow-y: auto;
            }
        }
    }

    .browser-widget {
        padding: 0px;

        img {
            height: 50px;
        }

        .media {
            .media-body {
                h4 {
                    font-size: 17px;
                }
            }

            .media-img {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }

    .static-top-widget {
        .media-body {
            padding-left: 20px;
        }
    }

    .widget-joins {
        .media {
            .media-body {
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    //ecommerce dashboard
    .crm-overall {
        .overall-chart {
            height: 200px;
        }
    }

    // eccommerce product
    .product-table {
        table.dataTable thead > tr > th {
            padding-right: 50px;
        }
    }

    //chat
    .chat-menu-icons {
        display: block;
        clear: both;
    }

    .chat-menu {
        right: 0;
        border-top: 1px solid $light-semi-gray;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 120px;
        position: absolute !important;
        z-index: 7;
        background-color: $white;
        transition: all linear 0.3s;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
            transition: all linear 0.3s;
            padding-bottom: 25px;
        }
    }

    .chat-box {
        .chat-menu {
            .people-list {
                ul.list {
                    max-height: 444px;
                    overflow-y: auto;
                }
            }
        }

        .btn-lg {
            font-size: 12px;
        }

        .people-list {
            ul {
                height: auto;
            }
        }

        .chat-right-aside {
            .chat {
                .chat-header {
                    height: auto;

                    .chat-menu-icons li a i {
                        font-size: 19px;
                    }
                }

                .chat-message {
                    margin: 0;
                }
            }
        }

        .chat-history {
            .total-time h2 {
                font-size: 25px;
            }

            .row {
                .col-sm-7 {
                    padding-right: 0;
                }
            }
        }

        .border-tab.nav-tabs {
            width: 100%;

            .nav-item {
                margin-right: 5px;
            }
        }

        .chat-left-aside {
            .people-list {
                height: 493px;
            }
        }

        .chat-history {
            .call-content {
                > div {
                    padding-top: 70px;
                }
            }
        }

        .toogle-bar {
            display: inline-block;
            margin-right: 0 !important;
        }
    }

    .chat-right-aside {
        flex: 0 0 100%;
        max-width: calc(100% - 15px);
        overflow: hidden;
    }

    .call-chat-body {
        .chat-box {
            overflow: auto;
            height: auto;
        }
    }

    // Email app
    .email-wrap {
        .email-right-aside {
            .email-body {
                .b-r-light {
                    border-right: none !important;
                }
            }

            .radius-left {
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
            }
        }

        .row {
            .col-xl-3 {
                & + .col-xl-3 {
                    padding-right: 15px;
                }
            }

            .col-xl-6 {
                padding-left: 15px;
            }
        }
    }

    // icons
    .icon-lists {
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 209px;
        }
    }

    // tour page
    .like-comment-lg-mb {
        margin-bottom: 12px;
    }

    // ribbons page
    .ribbon-space-bottom {
        top: 9px;
    }

    // touchspin page
    .bootstrap-touchspin {
        .touchspin-vertical-tab {
            .input-group {
                width: 98.5% !important;
            }
        }
    }

    // jsgrid-table page
    #batchDelete {
        .jsgrid-grid-header {
            .jsgrid-table {
                .jsgrid-header-row {
                    .jsgrid-header-cell {
                        &:first-child {
                            width: 95px !important;
                        }
                    }
                }
            }
        }

        .jsgrid-grid-body {
            .jsgrid-table {
                .jsgrid-row {
                    .jsgrid-cell {
                        &:first-child {
                            width: 95px !important;
                        }
                    }
                }
            }
        }
    }

    // user-profile page
    .profile-img-style {
        #aniimated-thumbnials-3 {
            text-align: center;
        }
    }

    // vertical page
    .vertical-menu-mt {
        margin-top: 80px !important;

        .xl-none {
            display: block;
        }
    }

    // calender page
    .calendar-wrap {
        .fc-day-grid-event {
            width: 87%;
        }
    }

    // product-page
    .product-page-main {
        .product-page-details {
            h3 {
                font-size: 26px;
            }
        }
    }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    .page-wrapper {
        &.horizontal-wrapper {
            &.material-type {
                .page-header {
                    max-width: 930px;
                }

                .page-body-wrapper {
                    .sidebar-wrapper,
                    .page-body {
                        max-width: 930px;
                    }
                }
            }
        }
    }

    .left-header {
        .mega-menu,
        .level-menu {
            .nav-link {
                svg {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .order-menu-section {
        padding: 0;
    }

    .simplebar-mask {
        top: 220px;
    }

    .datepicker-dashboard {
        margin-bottom: 30px;
    }

    .project-cards {
        .tab-content {
            margin-bottom: -20px;
        }
    }

    .project-box {
        margin-bottom: 20px;
    }

    .sidebar-links {
        &::-webkit-scrollbar-thumb {
            opacity: 1;
            visibility: visible;
        }
    }

    .left-header {
        .mega-menu,
        .level-menu {
            .nav-link {
                svg {
                    margin-right: 0;
                }

                span {
                    display: none;
                }
            }
        }
    }

    .left-header {
        .level-menu {
            .nav-link {
                margin-left: 6px;
            }
        }
    }

    .page-wrapper {
        &.modern-type {
            .simplebar-mask {
                top: 70px;
            }
        }

        &.compact-sidebar {
            .simplebar-mask {
                top: 87px;
            }

            .page-header {
                margin-left: 0;
                width: calc(100% - 0px);
            }

            .page-body-wrapper {
                div.sidebar-wrapper {
                    &.close_icon {
                        .sidebar-main {
                            .sidebar-links {
                                li {
                                    .sidebar-link {
                                        &.active {
                                            ~ .sidebar-submenu {
                                                display: none !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .page-body {
                    margin-top: 60px;
                }
            }
        }

        &.only-body {
            .page-header {
                .header-wrapper {
                    .search-full {
                        .Typeahead-menu {
                            top: 65px;
                        }
                    }
                }
            }
        }

        &.advance-layout {
            .page-body-wrapper {
                .sidebar-wrapper {
                    position: fixed !important;
                    top: 0 !important;
                }
            }
        }
    }

    .product-box {
        .modal {
            .modal-header {
                .product-box {
                    .product-details {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    .search-form {
        .form-group {
            &:before {
                top: 33px;
            }

            &:after {
                top: 35px;
            }
        }
    }

    .mobile-title {
        &.d-none {
            padding: 11px 30px 10px;
        }
    }

    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    .profile-dropdown {
                        top: 50px;
                    }
                }

                .search-full {
                    input {
                        line-height: 33px;
                    }

                    .form-group {
                        .close-search {
                            top: 11px;
                        }

                        &:before {
                            top: 48%;
                        }
                    }
                }
            }
        }

        .current_lang {
            .lang {
                span {
                    &.lang-txt {
                        display: none;
                    }
                }
            }
        }
    }

    .customizer-contain {
        top: 62px;
    }

    .mobile-clock-widget {
        #date {
            margin-top: 20px;
        }
    }

    .m-r-30 {
        margin-right: 20px;
    }

    .card.full-card {
        top: 60px;
        width: calc(100vw - 0px);
    }

    .vertical-mobile-sidebar {
        right: 75px;
        top: 18px;
    }

    .gallery {
        margin-bottom: -20px;
    }

    .my-gallery {
        figure {
            margin-bottom: 20px;
        }
    }

    #aniimated-thumbnials {
        figure {
            &:nth-child(12),
            &:nth-child(11),
            &:nth-child(10),
            &:nth-child(9) {
                margin-bottom: 20px;
            }
        }
    }

    .photoswipe-pb-responsive {
        padding-bottom: 25px !important;
    }

    // datatable css
    table {
        &.dataTable {
            &.fixedHeader-locked,
            &.fixedHeader-floating {
                left: 0 !important;
                width: 100% !important;
            }
        }
    }

    // main header css
    .page-wrapper {
        .section-space {
            padding: 60px 0;
        }

        .card {
            margin-bottom: 30px;

            .card-body {
                .tab-content {
                    .m-t-30 {
                        margin-top: 20px !important;
                    }

                    .m-b-30 {
                        margin-bottom: 20px !important;
                    }
                }
            }

            .card-header,
            .card-footer {
                padding: 20px;

                .tab-content {
                    .m-t-30 {
                        margin-top: 20px !important;
                    }

                    .m-b-30 {
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .page-body-wrapper {
            .page-title {
                padding-top: 25px;
                padding-bottom: 25px;
            }

            .footer-fix {
                margin-left: 0px;
                padding-right: 15px;
                width: calc(100% - 0px) !important;
            }
        }

        &.compact-wrapper {
            .page-header {
                margin-left: 0 !important;
                width: calc(100% - 0px) !important;

                .header-wrapper {
                    .maximize {
                        display: none;
                    }

                    .header-logo-wrapper {
                        display: flex;
                    }

                    .toggle-sidebar {
                        position: unset;
                        margin-right: 15px;
                        border-right: 1px solid #ddd;
                        padding-right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &:before {
                            display: none;
                        }
                    }

                    .toggle-nav {
                        display: none;
                    }
                }
            }

            .page-body-wrapper {
                div.sidebar-wrapper {
                    top: 0;

                    .logo-wrapper {
                        display: flex;
                        align-items: center;

                        .back {
                            font-size: 16px;
                        }

                        .back-btn {
                            display: inline-block;
                            position: relative;
                            font-size: 20px;
                        }

                        .toggle-sidebar {
                            display: none;
                        }
                    }

                    .sidebar-main {
                        .sidebar-links {
                            height: calc(100vh - 75px);
                        }
                    }

                    &.close_icon {
                        left: 0;
                        transform: translate(0);
                        width: 260px !important;
                        [dir="rtl"] & {
                            left: unset;
                            right: 0;
                        }
                    }
                }

                .page-body {
                    margin: 75px 0 0 !important;
                    padding: 25px 15px 45px;
                }
            }
        }

        &.compact-sidebar {
            .page-header {
                .header-wrapper {
                    .maximize {
                        display: none;
                    }
                }
            }
        }

        &.modern-type {
            .page-body-wrapper {
                div.sidebar-wrapper {
                    margin: 0 !important;

                    .logo-wrapper {
                        display: block;
                    }
                }
            }
        }

        &.material-icon {
            .page-header {
                padding-left: 0;
            }

            .page-body-wrapper {
                .page-body {
                    padding-left: 15px;
                }
            }
        }

        .page-header {
            .header-wrapper {
                margin: 0;
                padding: 12px 30px;
                justify-content: space-between;

                .nav-right {
                    .notification {
                        top: 10px;
                    }
                }

                > .mobile-toggle {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }

                .header-logo-wrapper {
                    margin-right: 0;
                }
            }

            .header-logo-wrapper {
                .logo-wrapper {
                    img {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .page-body-wrapper {
        .page-body,
        footer {
            #customer-review {
                .owl-stage-outer {
                    width: 100% !important;
                }
            }

            .calender-widget {
                .cal-desc {
                    p {
                        max-height: 101px;
                    }
                }
            }
        }
    }

    .default-according {
        .card {
            margin-bottom: 10px !important;

            .card-header {
                padding: 12px 20px;
            }
        }
    }

    // general widget page
    .crm-activity {
        > {
            li {
                + li {
                    margin-top: 10px;
                    padding-top: 10px;
                }
            }
        }
    }

    // add post page
    .add-post {
        .dropzone {
            margin-bottom: 20px;
        }
    }

    // avatar page
    .customers {
        &.avatar-group {
            margin-right: 20px;
        }
    }

    //social app page css
    .socialprofile {
        .social-btngroup {
            margin: 20px 0;
        }

        .social-group {
            margin-top: 20px;
        }
    }

    .social-status {
        form {
            .form-group {
                .form-control-social {
                    margin-bottom: 20px;
                }
            }
        }

        .media {
            margin-bottom: 20px;
        }
    }

    .timeline-content {
        p,
        .comments-box {
            margin-top: 20px;
        }
    }

    .social-chat {
        margin-top: 20px;

        .media-body {
            padding: 20px;
        }

        .your-msg,
        .other-msg {
            margin-bottom: 20px;
        }
    }

    .social-network {
        span {
            margin-bottom: 20px;
        }
    }

    .details-about {
        + .details-about {
            margin-top: 20px;
        }
    }

    .activity-log {
        .my-activity {
            + .my-activity {
                margin-top: 20px;
            }
        }
    }

    .new-users-social {
        margin-bottom: 20px;
    }

    // timeline small page css
    .timeline-small {
        .media {
            margin-bottom: 20px;

            .media-body {
                h6 {
                    margin-bottom: 20px;
                }

                p {
                    width: 75%;
                }
            }

            .timeline-round {
                width: 45px;
                height: 45px;
                top: -14px;

                &.timeline-line-1 {
                    &:after {
                        bottom: -41px;
                        height: 25px;
                    }
                }

                &.small-line {
                    &:after {
                        bottom: -26px;
                        height: 14px;
                    }
                }

                &.medium-line {
                    &:after {
                        bottom: -33px;
                        height: 17px;
                    }
                }

                svg {
                    top: 14px;
                }
            }
        }
    }

    // helper classes page start
    .helper-classes {
        padding: 20px;
    }

    // helper classes page ends
    .floated-customizer-btn,
    .floated-customizer-panel {
        display: none;
    }

    .page-builder {
        .ge-canvas {
            &.ge-editing {
                .row {
                    padding: 20px;
                }
            }
        }
    }

    .weather-widget-two {
        .bottom-whetherinfo {
            .whether-content {
                top: 24px;
            }
        }
    }

    .social-widget-card {
        .b-b-light {
            padding: 20px;
            margin-bottom: 20px;
        }
    }

    .ecommerce-widget {
        .progress-showcase {
            margin-top: 20px;
        }
    }

    .crm-overall {
        margin: 0 -20px -20px;
    }

    .product-page-main {
        padding: 20px;
    }

    .grid-options {
        ul {
            li {
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    .feature-products {
        form {
            .form-group {
                i {
                    right: 20px;
                }
            }
        }
    }

    .tabbed-card ul {
        padding: 18px 15px;
    }

    .tabbed-card {
        ul {
            top: -7px;
            right: 0;

            &.border-tab {
                &.nav-tabs {
                    padding: 0;

                    .nav-item {
                        .nav-link {
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }

    .tilt-showcase {
        .tilt-image {
            text-align: center;
        }

        .mt-4 {
            margin-top: 20px !important;
        }
    }

    .todo {
        .todo-list-wrapper {
            .mark-all-tasks {
                top: 20px;
                right: 20px;
            }
        }
    }

    .custom-card {
        padding-bottom: 0;

        .card-footer {
            padding: 0 15px 15px 15px !important;
        }

        .card-header {
            border: none;
        }

        .dashboard-card {
            padding: 0;
        }

        .card-profile {
            img {
                height: 155px;
            }
        }
    }

    .display-1 {
        font-size: 4rem;
    }

    .display-2 {
        font-size: 3.5rem;
    }

    .display-3 {
        font-size: 2.5rem;
    }

    .display-4 {
        font-size: 1.5rem;
    }

    .card-absolute {
        .card-header {
            padding: 10px 15px !important;
        }
    }

    .comingsoon {
        .comingsoon-inner {
            .countdown {
                .time {
                    width: 80px;
                    height: 80px;
                    font-size: 26px;
                }
            }
        }
    }

    .calendar-wrap {
        .basic-calendar {
            .external-events {
                .fc-event {
                    & + .fc-event {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .owl-theme {
        .owl-nav {
            &.disabled {
                & + .owl-dots {
                    margin-bottom: -10px;
                    margin-top: 15px;
                }
            }
        }
    }

    .card {
        .card-header {
            h5 {
                font-size: 18px;
            }

            .card-header-right {
                top: 11px;
                right: 15px;
            }
        }
    }

    .page-builder {
        .ge-addRowGroup {
            width: 100%;
        }
    }

    //user profile
    .user-profile {
        .hovercard {
            .info {
                .user-designation {
                    margin-bottom: 20px;
                }
            }
        }
    }

    // search page
    .search-list {
        justify-content: center;
    }

    //blog
    .blog-single {
        .comment-box {
            .comment-social {
                li {
                    padding-left: 15px;
                }
            }
        }
    }

    //error and maintenace
    .error-wrapper {
        padding: 35px 0;

        .sub-content {
            font-size: 14px;
            line-height: 25px;
            margin-top: 0px;
        }

        .error-heading {
            margin-top: 30px;

            .headline {
                font-size: 180px;
                margin-top: 0;
            }
        }

        .maintenance-heading {
            margin-top: 30px;
        }
    }

    // editor_container
    .editor_container {
        .CodeMirror {
            min-height: 250px;
            height: 250px;
        }
    }

    //search
    .search-page {
        .info-block {
            flex-wrap: wrap;
        }

        .tab-content {
            .pb-4 {
                padding-bottom: 20px !important;
            }
        }
    }

    //coming soon
    .comingsoon {
        .comingsoon-inner {
            img {
                width: 150px;
            }
        }
    }

    //business dashboard
    .calender-widget {
        .cal-desc {
            padding: 10px 20px 20px !important;

            p {
                max-height: 100px;
            }
        }
    }

    .contact-form {
        padding-top: 10px !important;

        .theme-form {
            padding: 20px;
            margin-top: 27px;

            .form-icon {
                margin-top: -47px;
            }
        }
    }

    //crm dashboard
    .user-status {
        &.product-chart {
            max-height: 518px;
            overflow: auto;
        }
    }

    // general widget
    .widget-joins {
        .media {
            .media-body {
                i {
                    float: none !important;
                }
            }
        }
    }

    .xl-none {
        display: block;
    }

    //blog
    .top-radius-blog {
        width: 100%;
    }

    .sm-100-w {
        width: 100%;
        height: 100%;
    }

    /*Form Wizard One start*/
    .form-wizard {
        .btn-mb {
            margin-bottom: 20px;
        }
    }

    /*Form Wizard One ends*/

    /*Form Wizard Two Start*/
    .stepwizard {
        margin-bottom: 20px;
    }

    /*Form Wizard Two Ends*/

    /*Form Wizard Three Start*/
    .f1 {
        .f1-steps {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    /*Form Wizard Three ends*/

    /*Form Wizard Four Start*/
    .wizard-4 {
        ul.anchor {
            padding-right: 20px;
            width: 40%;

            li {
                a {
                    &.done {
                        h4 {
                            &:before {
                                top: -8px;
                            }
                        }
                    }
                }

                h4 {
                    padding: 11px 18px;
                }

                + li {
                    a {
                        padding-left: 65px;
                    }
                }
            }
        }

        .step-container {
            width: 60%;

            div.content {
                .wizard-title {
                    width: 380px;
                }
            }
        }

        .login-card {
            .login-main {
                width: 380px;
            }
        }

        .msg-box {
            top: 25px !important;
        }
    }

    /*Form Wizard Four ends*/
    // tab material
    .border-tab.nav-tabs {
        margin-bottom: 20px;
    }

    .tab-content {
        ~ .nav-tabs {
            &.border-tab {
                margin-top: 20px;
            }
        }
    }

    // support ticket
    .dataTables_wrapper {
        .dataTables_length {
            margin-bottom: 20px;
        }

        table.dataTable {
            margin-bottom: 20px !important;
        }
    }

    // search page
    .search-page {
        .info-block {
            + .info-block {
                padding-top: 20px;
                margin-top: 20px;
            }
        }

        #video-links {
            .embed-responsive + .embed-responsive {
                margin-top: 20px;
            }
        }
    }

    .lg-mt {
        margin-top: 20px;
    }

    .gallery-with-description {
        margin-bottom: -20px;

        a {
            > div {
                margin-bottom: 20px;
            }
        }
    }

    // clipboard
    .clipboaard-container {
        p {
            margin-bottom: 8px;
        }
    }

    // summer-note
    .m-b-30 {
        margin-bottom: 20px !important;
    }

    // email app
    .email-wrap {
        .email-left-aside {
            .email-app-sidebar {
                ul {
                    li {
                        hr {
                            margin: 13px 0;
                        }
                    }
                }
            }
        }
    }

    // navs page
    .nav-md-mt {
        margin-top: 20px;
    }

    .navs-icon {
        padding: 20px;

        .main-section {
            padding-top: 20px;
        }

        .separator {
            margin: 20px 0;
        }
    }

    .nav-list {
        padding: 20px;
    }

    .navs-dropdown {
        button {
            margin-top: 20px;
        }

        .onhover-show-div {
            top: 68px;
        }
    }

    // animated modal page
    .animated-modal {
        .form-group {
            width: 100%;
            display: block;
        }

        .mr-4 {
            margin-right: 0 !important;
        }
    }

    // steps page
    .steps-md-mt {
        margin-top: 20px;
    }

    // button group page
    .btn-group-wrapper {
        .m-b-30 {
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    // datatable advance page
    #advance-5_wrapper {
        .dataTables_paginate {
            margin-bottom: 20px;
        }
    }

    // summer-note page
    .click2edit {
        & ~ .note-editor {
            &.note-frame {
                margin-bottom: 20px;
            }
        }
    }

    // call-chat page
    .chat-box {
        .chat-left-aside {
            .people-list {
                height: 483px;
            }
        }

        .chat-right-aside {
            .chat {
                .chat-header {
                    .chat-menu-icons {
                        li {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .card {
        .card-body {
            form {
                &.animated-modal {
                    display: flex;

                    .animated-modal-md-mb {
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
    }

    .wizard-4 {
        .msg-box {
            top: 40px;
            right: 0%;
            bottom: unset;
            left: unset;
        }
    }

    .theme-form {
        &.form-inline {
            display: flex;
        }
    }

    .page-wrapper {
        &.modern-type {
            .page-body-wrapper {
                div {
                    &.sidebar-wrapper {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .page-wrapper {
        &.compact-wrapper {
            &.material-type {
                &::before {
                    height: 230px;
                }

                .page-header {
                    width: calc(100% - 60px) !important;
                    margin: 50px auto 0 !important;
                }

                .page-body-wrapper {
                    margin: 0 30px;

                    div.sidebar-wrapper {
                        left: 0;
                    }

                    .page-body {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 762px) {
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    margin-left: auto;
                }

                .search-full {
                    display: none;
                    position: absolute;
                    left: 0;
                    z-index: 1;
                    height: auto;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 0 23%;
                    flex: 1 0 23%;
                    transition: all 0.3s ease;
                    left: 0;
                    width: 100%;
                    top: 0;
                    padding: 15px;
                    &.open {
                        display: block;
                    }
                }
            }
        }
    }

    .header-search {
        display: inherit !important;
    }

    .close-search {
        display: inherit !important;
    }
}

@media only screen and (max-width: 1402px) {
    .user-name-hide {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .bg-overlay {
        display: block;
    }

    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        td:first-child {
                            display: block;
                            width: 100%;

                            a {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        .room-detail {
                            padding: 0;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }

    .page-wrapper {
        &.modern-type {
            .page-body-wrapper {
                .page-body {
                    margin-left: 0;
                }
            }
        }
    }

    .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
        margin: 0px !important;
        top: 0;
        bottom: 0;
        border-radius: 0;
    }
}

@media only screen and (max-width: 868px) {
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    > ul {
                        > li {
                            .profile-media {
                                .media-body {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .wizard-4 {
        ul.anchor {
            width: 100%;
            height: auto;
            padding: 30px;
        }

        .step-container {
            width: 100%;
        }
    }

    .contact-editform {
        padding-left: 20px !important;
    }

    .event-calendar {
        display: block !important;

        #right {
            width: 100%;
        }

        #calendar {
            clear: both;
        }
    }

    .onhover-dropdown {
        &:hover {
            .onhover-show-div {
                opacity: 0;
                transform: none;
                visibility: hidden;
            }
        }
        .onhover-show-div {
            &.active {
                opacity: 1;
                transform: translateY(0px);
                visibility: visible;
                border-radius: 5px;
                overflow: hidden;
            }
        }
    }

    .page-wrapper {
        &.compact-wrapper,
        &.compact-sidebar {
            .left-header {
                position: unset;

                .mega-menu {
                    position: absolute !important;
                    right: 30px;
                    top: 13px;

                    .nav-link {
                        min-width: auto;
                        background-color: transparent;
                        border-left: 1px solid #ddd;
                        padding-right: 0;
                        border-radius: 0;
                    }

                    svg {
                        margin-right: 0;
                        height: 20px;
                        stroke: $dark-body-background;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        &.material-type {
            &::before {
                display: none;
            }

            .page-header {
                margin-top: 0;
                border-radius: 0;
            }

            .page-body-wrapper {
                .page-body {
                    margin-top: 0 !important;
                }
            }
        }

        &.only-body {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        > ul {
                            > li {
                                margin-right: -2px;
                            }
                        }

                        .nav-menus {
                            margin-right: -10px;
                        }
                    }
                }
            }
        }
        .page-header {
            .header-wrapper {
                .nav-right {
                    .onhover-show-div {
                        right: 8px;
                    }
                }
            }
        }
    }

    .new-products {
        .owl-carousel {
            .product-box {
                &.row {
                    > div {
                        width: auto;
                    }
                }
            }
        }
    }

    .profile-media {
        .media-body {
            display: none;
        }
    }

    .form-inline {
        .form-group:not(.form-control-search) {
            width: 100%;
            position: relative;

            + .form-group {
                margin-top: 10px;
            }

            input {
                width: 100%;
            }
        }
    }

    .credit-card {
        .text-center {
            img {
                margin-bottom: 20px;
            }
        }
    }

    .starter-kit-fix {
        .page-body {
            margin-bottom: 78px;
        }
    }

    .page-header {
        .header-wrapper {
            .left-header {
                ul {
                    li {
                        .search-form {
                            &.form-inline {
                                display: flex;
                            }

                            input {
                                width: 77% !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .blog-box {
        .blog-details-main {
            .blog-social {
                li {
                    padding: 0 12px;
                }
            }
        }

        .blog-details {
            .blog-social {
                li {
                    &:first-child {
                        padding-right: 25px;
                    }

                    + li {
                        padding-left: 25px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        ~ div {
            text-align: center;

            p {
                float: none;
                margin-top: 5px;
            }
        }
    }

    // button page
    .btn-showcase {
        text-align: center;
    }

    // social app page css
    .user-profile {
        .hovercard {
            .user-image {
                .share-icons {
                    right: 25px;
                    top: -19px;

                    li {
                        .social-icon {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    .tabs-scoial {
        &.border-tab {
            &.nav-tabs {
                display: flex;
            }
        }
    }

    // timeline small page css
    .timeline-small {
        .media {
            .media-body {
                p {
                    width: 100%;
                }
            }
        }
    }

    // icon-compact layout page
    .my-gallery {
        figure {
            &:nth-child(9),
            &:nth-child(10) {
                margin-bottom: 20px;
            }
        }
    }

    // project list
    .project-list {
        .btn {
            float: left;
            margin-top: 5px;
        }
    }

    // chat
    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-message {
                    .text-box {
                        .btn {
                            font-size: 0;

                            &:before {
                                position: absolute;
                                content: "\e661";
                                font-family: $font-themify;
                                font-size: 18px;
                                top: 11px;
                                left: 20px;
                            }
                        }
                    }
                }
            }
        }

        .border-tab {
            &.nav-tabs {
                display: flex;
            }
        }

        .chat-menu {
            .nav-tabs {
                .nav-item {
                    a {
                        height: 50px;
                    }
                }
            }
        }

        .chat-history {
            .call-content {
                > div {
                    padding-top: 40px;
                    height: auto;
                }
            }

            .receiver-img {
                margin-bottom: unset;
            }
        }

        .chat-left-aside {
            .people-list {
                height: auto;
            }
        }
    }

    .call-chat-sidebar {
        max-width: 100%;
        width: 100%;
        flex-basis: auto;

        .people-list {
            height: auto;
        }
    }

    .chat-left-aside {
        .people-list {
            ul {
                max-height: 100px;
            }
        }
    }

    .clockpicker-align-top {
        left: 55px !important;
        top: 470px !important;

        .arrow {
            display: none;
        }
    }

    .form-builder-column {
        .form-body {
            min-height: 250px;
        }
    }

    .form-builder-2-header {
        flex-wrap: wrap;

        > div {
            width: 100%;
            margin-top: 10px;

            nav {
                float: none;
            }
        }
    }

    // form-builder
    .lg-mt-col {
        margin-top: 20px;
    }

    .form-builder {
        .drag-box {
            fieldset {
                padding: 20px;
                margin-top: 20px;
            }
        }

        #components {
            margin-top: 20px;
        }

        .component {
            label {
                padding-top: 0px;
            }
        }
    }

    //mega menu
    .mega-menu {
        .onhover-show-div {
            height: 415px;
            left: 0;
            top: 61px;
            padding: 20px;
        }

        .lg-mt {
            margin-top: 15px;
        }
    }

    .page-builder {
        .ge-canvas {
            &.ge-editing {
                .ge-tools-drawer {
                    margin: 10px 0 0 10px;

                    > a {
                        padding: 0 5px;
                    }
                }
            }
        }
    }

    .reader {
        margin-top: 15px;
        height: auto !important;
    }

    .range-slider {
        &.theme-form {
            .form-group {
                margin-bottom: 0;
            }
        }
    }

    .note-editor {
        .btn-group {
            .btn {
                font-size: 11px;
            }
        }
    }

    .user-status {
        tbody {
            tr {
                td {
                    min-width: 284px;

                    & + td {
                        min-width: unset;

                        & + td {
                            min-width: 100px;
                        }
                    }
                }
            }
        }
    }

    .text-md-right {
        text-align: right;
    }

    .star-ratings {
        .stars {
            padding: 0;
        }
    }

    .form-inline {
        &.d-inline-block {
            display: block !important;
            width: 100%;

            .form-control {
                margin-bottom: 10px;
            }
        }
    }

    .reset-password-box {
        width: 500px;
        margin: 0 auto;

        .card {
            padding: 20px;
        }
    }

    .custom-card {
        .card-profile {
            img {
                height: 136px;
                bottom: 0;
            }
        }
    }

    .error-wrapper {
        .maintenance-icons {
            li {
                i {
                    color: $light-color;
                    font-size: 30px;
                }

                &:nth-child(2) {
                    i {
                        font-size: 60px;
                        margin-top: -10px;
                        margin-left: 70px;
                    }
                }

                &:nth-child(3) {
                    i {
                        font-size: 90px;
                        margin-right: 80px;
                    }
                }
            }
        }
    }

    .authentication-main {
        .auth-innerright {
            .social-media {
                li {
                    font-size: 14px;
                }
            }

            min-height: unset;
            padding: 0;
        }
    }

    .loader-box {
        justify-content: center;
    }

    .dataTables_wrapper {
        .dataTables_length {
            margin-bottom: 20px;
        }

        .dataTables_filter {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    div {
        &.dataTables_wrapper {
            div {
                &.dataTables_info {
                    padding-top: 0;
                }

                &.dataTables_length,
                &.dataTables_filter {
                    label {
                        float: none;
                    }
                }

                &.dataTables_paginate {
                    float: none;
                    margin-top: 20px !important;
                    margin-left: 0 !important;
                }
            }
        }
    }

    .calendar-wrap {
        .fc-basic-view {
            .fc-day-number {
                padding: 0 0px !important;
            }
        }

        .fc-events-container {
            margin-top: 0px;
        }

        .fc-events-container {
            h6,
            .checkbox {
                text-align: right;
            }
        }

        .checkbox {
            label {
                margin-top: 0;
            }
        }

        .fc-toolbar {
            .fc-left {
                .fc-next-button {
                    .fc-icon-right-single-arrow {
                        &:after {
                            font-size: 12px !important;
                        }
                    }
                }

                .fc-prev-button {
                    .fc-icon-left-single-arrow {
                        &:after {
                            font-size: 12px !important;
                        }
                    }
                }
            }

            .fc-left,
            .fc-right {
                .fc-today-button {
                    padding-top: 0px !important;
                }
            }
        }

        .fc {
            button {
                font-size: 14px !important;
                padding: 0 5px !important;
            }

            .fc-left {
                margin-bottom: 10px;
            }

            .fc-toolbar {
                > div {
                    display: block !important;
                    float: none !important;
                }

                > * {
                    :first-child {
                        font-size: 18px;
                    }

                    > * {
                        float: none !important;
                    }
                }
            }

            margin-top: 0px;
        }
    }

    .btn-group-wrapper {
        text-align: center;

        .m-b-30 {
            margin-bottom: 15px !important;
        }
    }

    .btn-group-showcase {
        text-align: center;

        .btn-group {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .btn-group {
        .btn {
            font-size: 14px;
            padding: 0.375rem 0.5rem;
        }
    }

    .animate-widget {
        .text-center {
            display: none;
        }
    }

    .page-wrapper {
        .page-header {
            .header-wrapper {
                .search-full {
                    .form-group {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    //general widget page
    .calender-widget {
        .cal-desc {
            p {
                max-height: 100%;
            }
        }
    }

    .user-status {
        &.product-chart {
            max-height: 100%;
        }

        table {
            thead {
                tr {
                    th {
                        &:nth-child(3) {
                            min-width: 124px;
                        }
                    }
                }
            }
        }
    }

    //mega menu
    .mega-menu {
        .onhover-show-div {
            height: 350px;
        }
    }

    .page-builder {
        .ge-canvas {
            .column {
                padding: 0 !important;
            }
        }

        .ge-content-type-ckeditor {
            h3 {
                font-size: 20px;
            }

            p {
                font-size: 12px;
            }
        }

        .ge-row-icon {
            display: none !important;
        }
    }

    /* font-awesom icon page */
    .icon-hover-bottom {
        .form-group {
            input {
                min-width: 270px;
            }
        }
    }

    //user profile
    .user-profile {
        .hovercard {
            .cardheader {
                height: 350px;
            }

            .info {
                padding: 35px;

                .ttl-info {
                    margin-bottom: 20px;
                }

                .ttl-sm-mb-0 {
                    margin-bottom: 0;
                }
            }
        }
    }

    //calendar
    .calendar-wrap {
        .fc {
            button {
                padding: 5px;
            }
        }

        .fc-day-grid-event {
            margin: 0;
            padding: 5px;
            width: inherit;
        }

        .fc-toolbar {
            h2 {
                font-size: 20px;
                line-height: 35px;
                font-weight: bold;
            }
        }

        .basic-calendar {
            .external-events {
                margin-top: 0px;
            }
        }
    }

    //blog
    .blog-single {
        .comment-box {
            .media {
                h6 {
                    margin-bottom: 10px;
                }

                img {
                    margin-right: 30px;
                }
            }

            .comment-social {
                margin-bottom: 5px;

                li:first-child {
                    padding-left: 0;
                    padding-right: 15px;
                }
            }
        }
    }

    //material tab
    .tabbed-card {
        ul {
            &.border-tab {
                margin-bottom: 15px;

                &.nav-tabs {
                    top: unset;

                    .nav-item {
                        .nav-link {
                            padding: 5px 15px 10px;
                        }
                    }
                }
            }

            padding: 10px 0 5px 0;
            position: relative;
            width: 100%;
            left: 0;
        }
    }

    //search
    .search-page {
        #image-links {
            .info-block {
                &.m-t-30 {
                    margin-top: 0 !important;
                }
            }
        }
    }

    //authentication
    .authentication-main {
        .authentication-box {
            width: 100%;
        }

        .auth-innerright {
            display: inherit;
        }
    }

    //default dashboard
    .map-chart {
        .chart-container {
            height: 300px;
        }
    }

    .candidcahrt {
        height: 250px;
    }

    //chart widget
    .bar-chart-widget {
        .earning-details {
            i {
                right: 0;
            }
        }
    }

    .serial-chart .chart-container {
        height: 300px;
    }

    .bottom-content {
        padding: 10px;

        p {
            font-size: 12px;
        }
    }

    // from wizard two
    .stepwizard {
        .stepwizard-row {
            &:before {
                top: 17px;
            }
        }
    }

    // icons
    .icon-hover-bottom {
        .icon-popup {
            display: block;

            > .icon-last {
                display: block;
                margin-top: 15px;

                .form-inline {
                    .form-group {
                        display: flex;
                    }
                }
            }

            div {
                .flag-icon {
                    width: 30px;
                    height: auto;
                }
            }
        }

        .form-group {
            input {
                display: block;
                width: auto !important;
            }
        }
    }

    //footer
    .footer-links {
        text-align: center;
    }

    .footer-copyright {
        text-align: center !important;
    }

    .jvector-map-height {
        height: 150px;
    }

    .sm-left-text {
        text-align: left !important;
    }

    // email app
    .email-wrap {
        .email-right-aside {
            .email-content {
                .email-top {
                    .d-flex {
                        padding-bottom: 0;
                    }

                    .float-right {
                        float: left !important;
                    }
                }

                .email-wrapper {
                    .attachment {
                        text-align: center;

                        ul {
                            li {
                                &:last-child {
                                    padding-right: 0;
                                }

                                img {
                                    width: 140px;
                                    height: 140px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // alert page
    .alert-dismissible {
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 294px;
        }
    }

    // button group page
    .button-group-mb-sm {
        margin-bottom: 5px;
    }

    // table components page
    .card-block {
        .table-responsive {
            .table {
                tbody {
                    tr {
                        td {
                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100px;
                            }
                        }
                    }
                }
            }

            .progress-content {
                tbody {
                    tr {
                        td {
                            span {
                                text-overflow: ellipsis;
                                white-space: normal;
                                max-width: 50px;
                            }
                        }
                    }
                }
            }

            .checkbox-td-width,
            .radio-first-col-width {
                tbody {
                    tr {
                        td {
                            min-width: 200px !important;
                        }
                    }
                }
            }
        }
    }

    // jsgrid-table page
    #batchDelete {
        .jsgrid-grid-header {
            .jsgrid-table {
                .jsgrid-header-row {
                    .jsgrid-header-cell {
                        &:first-child {
                            width: 119px !important;
                        }
                    }
                }
            }
        }

        .jsgrid-grid-body {
            .jsgrid-table {
                .jsgrid-row {
                    .jsgrid-cell {
                        &:first-child {
                            width: 119px !important;
                        }
                    }
                }
            }
        }
    }

    .jsgrid {
        .jsgrid-pager-container {
            text-align: center;
        }
    }

    // chat page
    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-msg-box {
                    height: auto;
                }
            }
        }

        .chat-menu {
            .nav-tabs {
                .nav-item {
                    width: 32.33%;
                }
            }

            .people-list {
                ul.list {
                    max-height: 433px;
                    margin-bottom: 15px;
                }
            }

            #info-profile,
            #info-contact {
                margin-bottom: 15px;
            }
        }
    }

    // user-profile page
    .hovercard {
        .info {
            .follow {
                .text-md-right {
                    text-align: center;
                }
            }
        }
    }

    .profile-img-style {
        .like-comment-sm-mb {
            margin-bottom: 20px;
        }
    }

    // forget-password page
    .reset-password-box {
        .theme-form {
            .form-group {
                .btn {
                    margin-top: 15px !important;
                }
            }
        }
    }

    // gallery page
    .lg-outer {
        .lg-actions .lg-next,
        .lg-actions .lg-prev {
            top: 48%;
        }
    }

    // product page
    .feature-products {
        .filter-toggle {
            padding: 8px 15px;
            background-color: $white;
            margin-left: 10px;
            border-radius: 5px;
        }

        .select2-drpdwn-product {
            float: left;
            margin-right: 10px;
            margin-top: 8px;
        }

        span {
            &.f-w-600 {
                padding-top: 15px;
                display: inline-block;
            }
        }

        .text-right {
            text-align: left !important;
        }
    }

    .d-none-productlist {
        display: block;
        margin-right: 10px;
    }

    .grid-options {
        ul {
            li {
                display: none;
            }
        }
    }

    .product-sidebar {
        transform: translateX(-300px) scaleX(0);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        height: 0;
        position: absolute;
        z-index: 1;
        width: 300px;

        &.open {
            transform: translateX(0px) scaleX(1);
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            visibility: visible;
            opacity: 1;
            height: auto;
            top: -45px;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 7px solid $transparent-color;
                border-right: 7px solid $transparent-color;
                border-bottom: 7px solid $white;
                right: 120px;
                top: -7px;
            }
        }

        .filter-section {
            .card-header {
                display: none;
            }
        }
    }

    .product-wrapper {
        &.sidebaron {
            .product-grid {
                .product-wrapper-grid {
                    margin-left: 0;
                }
            }

            .product-sidebar {
                .filter-section {
                    .card {
                        .left-filter {
                            top: unset;
                        }
                    }
                }
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-img {
                    width: 42%;
                }
            }
        }
    }

    .product-filter {
        .product-box {
            flex-wrap: nowrap;

            .product-img {
                width: 100px;
            }

            .product-details {
                padding: 0 !important;
            }
        }
    }

    // package detail
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .list-view {
                        .list-box {
                            padding: 0px;
                            flex-direction: column;

                            .list-img {
                                width: 100%;

                                a {
                                    display: block;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .list-view {
                        .list-box {
                            .list-content {
                                margin-left: 0;
                                margin-top: calc(8px + (60 - 8) * ((100vw - 320px) / (1920 - 320)));
                                width: 100%;
                                padding: 10px;
                            }

                            p {
                                margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 762px) {
    .form-inline .form-group:not(.form-control-search) {
        margin-top: 0;
    }
}

@media only screen and (max-width: 630px) {
    .left-header .level-menu .nav-link {
        svg {
            margin-right: 0;
        }

        span {
            display: none;
        }
    }

    .category-box {
        .content-category {
            p {
                text-align: justify;
                hyphens: auto;
                word-break: normal;
                margin-bottom: 7px;
            }

            h6 {
                flex-direction: column;
                align-items: baseline;

                span {
                    margin-top: 5px;
                    font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
                }
            }

            .top {
                flex-direction: column;
                align-items: baseline;

                h3 {
                    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }
    }
}

@media only screen and (max-width: 575.98px) {
    .page-wrapper.only-body {
        .page-header {
            .header-wrapper {
                .nav-right {
                    right: 30px;

                    .nav-menus {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .date-picker {
        .theme-form {
            > .row {
                > div {
                    &:last-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .search-page {
        .info-block {
            display: block;
        }
    }

    .form-bookmark {
        .row {
            > div {
                .row {
                    > .col-sm-6 {
                        + .col-sm-6 {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .accordion-plan {
                        .card {
                            .card-header {
                                button:before {
                                    right: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btn {
        padding: 9px 7px;

        &--package-page {
            padding: 6px;
        }
    }

    .alert-theme {
        min-width: 380px;
    }

    .knowledgebase-bg {
        height: 250px;
    }

    .login-card {
        .login-main {
            .theme-form {
                .or {
                    &:before {
                        width: 55%;
                    }
                }
            }
        }
    }

    .knowledgebase-search {
        width: calc(100% - 30px);
        left: 15px;
        height: calc(100% - 30px);
        background-color: rgba($white, 0.7);

        .form-inline {
            width: 90%;
            padding: 3px 45px;

            svg {
                top: 10px;
            }
        }

        > div {
            width: 100%;
            text-align: center;
            padding: 15px;
            border-right: 10px;

            h3 {
                font-size: 20px;
                margin-bottom: 0;
            }

            .form-group {
                margin-bottom: 0;
            }
        }
    }

    // invoice template page
    .invoice {
        .text-md-end {
            margin-top: 10px;
        }

        .text-right {
            text-align: right;
        }

        .text-xs-center {
            text-align: center;
        }
    }

    // Login page
    .login-card {
        padding-top: 40px;
        padding-bottom: 40px;

        .logo {
            margin-bottom: 10px;
        }

        .login-main {
            width: auto;
            padding: 20px;

            .theme-form {
                .form-group {
                    margin-bottom: 5px;
                }

                p {
                    margin-bottom: 5px;
                }
            }
        }

        .btn-showcase {
            .btn {
                width: 100%;

                + .btn {
                    margin-left: 0;
                    margin-top: 11px;
                }
            }
        }
    }

    .bookmark {
        ul {
            li {
                a {
                    svg {
                        height: 16px;
                        width: 16px;
                    }
                }

                & + li {
                    margin-left: 5px;
                }
            }
        }
    }

    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    > ul {
                        > li {
                            padding: 4px;
                        }
                    }
                }
            }
        }
    }

    .left-header {
        .level-menu {
            .nav-link {
                background-color: transparent;
                padding: 0.5rem 0;
                margin: 0;
            }
        }
    }

    .page-header {
        .header-wrapper {
            .nav-right {
                .cart-box,
                .notification-box {
                    .badge {
                        font-size: 10px;
                    }
                }
            }

            .left-header {
                ul {
                    li {
                        .search-form {
                            .form-control-plaintext {
                                top: 40px;
                                position: absolute;
                                transition: all linear 0.3s;
                                left: -14px;
                                background-color: $white;
                                border: 1px solid var(--theme-color);
                                transform: translateY(-35px) scaleY(0);
                                opacity: 0;
                                visibility: hidden;
                                width: 180px !important;
                                padding: 10px 10px 10px 15px;

                                &.open {
                                    transform: translateY(0px) scaleY(1);
                                    opacity: 1;
                                    visibility: visible;
                                    transition: all linear 0.3s;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .page-wrapper {
        .page-body-wrapper {
            .page-title > .row {
                .col-6 {
                    &:first-child {
                        display: block;

                        h3 {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .page-header {
            .header-wrapper .nav-right ul {
                li {
                    svg {
                        width: 16px;
                    }
                }
            }
        }

        .page-body-wrapper {
            .page-title {
                .breadcrumb {
                    margin-top: 0;
                }
            }
        }

        &.compact-wrapper,
        &.compact-sidebar {
            .left-header .mega-menu {
                top: 17px;

                .nav-link {
                    padding-left: 10px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

                right: 15px;
                padding-top: 0;
            }

            .page-header {
                .header-wrapper {
                    .toggle-sidebar {
                        margin-left: 0;
                        padding-left: 0;
                        border-left: none;
                        border-right: 1px solid #ddd;
                        padding-right: 8px;
                        margin-right: 8px;

                        svg {
                            width: 18px;
                        }
                    }
                }
            }
        }

        &.compact-sidebar {
            .sidebar-wrapper {
                .back-btn {
                    display: none;
                }

                .sidebar-main {
                    .sidebar-links {
                        .simplebar-wrapper {
                            .simplebar-mask {
                                .simplebar-content-wrapper {
                                    .simplebar-content {
                                        > li {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-header {
            .header-wrapper {
                padding: 12px 15px !important;

                .nav-right {
                    > ul {
                        top: 58px;

                        .search-form {
                            i {
                                display: none;
                            }
                        }
                    }

                    &.right-header {
                        ul {
                            li {
                                &.px-0 {
                                    padding: 6px !important;
                                }
                            }
                        }
                    }

                    > ul {
                        li {
                            margin-right: 0;

                            .flag-icon {
                                font-size: 14px !important;
                            }

                            .profile-media {
                                img {
                                    width: 25px;
                                }
                            }

                            &.onhover-dropdown {
                                ul {
                                    li {
                                        margin-right: 0 !important;
                                    }
                                }
                            }
                        }
                    }

                    .notification-dropdown {
                        &.onhover-show-div {
                            &:before,
                            &:after {
                                left: 24px !important;
                                right: unset !important;
                            }
                        }
                    }

                    .chat-dropdown {
                        left: -52px;
                        width: 300px;

                        &:after,
                        &:before {
                            left: 58px !important;
                        }
                    }
                }
            }
        }

        .page-body-wrapper {
            .page-title {
                .btn-group {
                    .btn {
                        padding: 8px 30px;
                    }
                }
            }
        }

        &.compact-wrapper {
            .page-body-wrapper {
                .page-body {
                    margin-top: 73px !important;
                    padding-top: 20px !important;
                    padding-bottom: 50px !important;
                }

                &.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            height: calc(100vh - 79px);
                        }
                    }
                }
            }
        }
    }

    .alert-dismissible {
        padding-right: 30px;

        p {
            max-width: 209px;
        }
    }

    .m-r-30 {
        margin-right: 15px;
    }

    // cart page
    .wishlist {
        .text-end {
            text-align: left !important;
        }
    }

    // general widget page css
    .mobile-clock-widget {
        #date {
            margin-top: 15px;
        }
    }

    .social-widget-card {
        .b-b-light {
            padding: 15px;
            margin-bottom: 15px;
        }
    }

    .weather-widget-two {
        .bottom-whetherinfo {
            .whether-content {
                top: 34px;
            }
        }
    }

    .cal-date-widget {
        .cal-datepicker {
            margin-top: 15px;
        }
    }

    .static-top-widget {
        .media-body {
            .icon-bg {
                width: 70px;
                height: 70px;
            }
        }
    }

    // Add post page css
    .add-post {
        form {
            .m-checkbox-inline {
                label {
                    margin-right: 15px;
                }
            }
        }

        .dropzone {
            margin-bottom: 15px;
        }
    }

    //form-wizard-four page css
    .wizard-4 {
        .msg-box {
            top: 10px !important;
        }

        .login-card {
            .login-main {
                width: 280px;
            }
        }

        .step-container {
            div.content {
                .wizard-title {
                    width: 280px;
                }
            }
        }

        .action-bar {
            right: 10px;
        }
    }

    // avatar page css
    .customers {
        &.avatar-group {
            margin-right: 15px;
        }
    }

    .vertical-mobile-sidebar {
        top: 19px;
    }

    //notify index page
    .alert-theme {
        i {
            margin-right: 10px !important;
        }

        button {
            top: 0px !important;
        }
    }

    .bar-chart-widget {
        .earning-details {
            i {
                right: -30px;
            }
        }
    }

    .widget-joins {
        &:before {
            display: none;
        }

        .ps-0 {
            padding-left: 15px !important;
        }

        .pe-0 {
            padding-right: 15px !important;
        }

        .border-after-xs {
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                width: 94%;
                background-color: $light-semi-gray;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }

    .clockpicker-align-top {
        left: 45px !important;
        top: 406px !important;
    }

    .gallery {
        margin-bottom: -15px;
    }

    .my-gallery {
        figure {
            margin-bottom: 15px;

            &:nth-child(9),
            &:nth-child(10) {
                margin-bottom: 15px;
            }
        }

        &.gallery-with-description {
            figure {
                &:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    #aniimated-thumbnials {
        figure {
            &:nth-child(12),
            &:nth-child(11),
            &:nth-child(10),
            &:nth-child(9) {
                margin-bottom: 15px;
            }
        }
    }

    .photoswipe-pb-responsive {
        padding-bottom: 30px !important;
    }

    // social app page css
    .avatar-showcase {
        .pepole-knows {
            text-align: center;

            ul {
                li {
                    margin-right: 14px;
                }
            }
        }
    }

    .social-app-profile {
        .hovercard {
            .user-image {
                .avatar {
                    margin-top: -56px;

                    img {
                        width: 100px;
                        height: 100px;
                    }
                }

                .icon-wrapper {
                    left: 52%;
                    top: 10px;
                    height: 30px;
                    width: 30px;
                    font-size: 15px;

                    &:hover {
                        font-size: 12px;
                    }
                }

                .share-icons {
                    right: 15px;

                    li {
                        margin-right: 0px;
                    }
                }
            }

            .info {
                .tabs-scoial {
                    .user-designation {
                        border-top: none;
                        border-bottom: none;
                        margin-bottom: 0;
                        padding: 0px 0px;
                    }
                }
            }
        }

        .tabs-scoial {
            margin-bottom: 0 !important;
        }
    }

    .socialprofile {
        .social-btngroup {
            margin: 15px 0;
        }

        .social-group {
            margin-top: 15px;
        }
    }

    .social-status {
        form {
            .form-group {
                .form-control-social {
                    margin-bottom: 15px;
                }
            }
        }

        .media {
            margin-bottom: 15px;
        }
    }

    .new-users-social {
        margin-bottom: 15px;
    }

    .tabs-scoial {
        position: relative;
        padding-top: 80px;

        li {
            &:nth-child(3) {
                position: absolute;
                top: 25px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        &.border-tab {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 10px;
                        height: 60px;
                    }
                }
            }
        }
    }

    .timeline-content {
        p,
        .comments-box {
            margin-top: 15px;
        }

        .comments-box {
            .media {
                .m-r-20 {
                    margin-right: 10px;
                }

                .media-body {
                    .input-group {
                        .input-group-append {
                            .btn {
                                padding: 6px;
                            }
                        }
                    }
                }
            }
        }

        .comment-number {
            i {
                margin-right: 15px;
            }
        }
    }

    #cd-timeline {
        margin-right: 0;
    }

    .social-chat {
        margin-top: 15px;

        .media-body {
            padding: 15px;
        }

        .your-msg,
        .other-msg {
            margin-bottom: 15px;
        }
    }

    .social-network {
        span {
            margin-bottom: 15px;
        }
    }

    .social-list {
        .media {
            margin-bottom: 15px;
        }
    }

    .details-about + .details-about {
        margin-top: 15px;
    }

    .your-details-xs {
        margin-top: 15px;
    }

    .social-header {
        h5 {
            span.pull-right {
                float: right !important;
                margin-top: 0 !important;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .activity-log {
        .my-activity {
            + .my-activity {
                margin-top: 15px;
            }
        }
    }

    .photos {
        ul {
            li {
                width: 26%;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    // chat page
    .chat-box {
        .chat-history {
            .call-content {
                > div {
                    padding-top: 30px;
                }
            }
        }
    }

    // timeline small page css
    .timeline-small {
        .media {
            margin-bottom: 15px;

            .media-body {
                h6 {
                    margin-bottom: 15px;
                }
            }

            .timeline-round {
                &.timeline-line-1 {
                    &:after {
                        bottom: -39px;
                        height: 30px;
                    }
                }

                &.small-line {
                    &:after {
                        bottom: -17px;
                        height: 8px;
                    }
                }

                &.medium-line {
                    &:after {
                        bottom: -30px;
                        height: 22px;
                    }
                }
            }
        }
    }

    // helper classes page start
    .helper-classes {
        padding: 15px;
    }

    // helper classes page ends
    .form-builder-2-header {
        ul {
            li {
                text-align: left;
            }
        }

        .form-inline {
            display: block;
            width: 100%;

            .form-group {
                width: 100%;
                margin-right: 0;
            }

            button {
                width: 100%;
                text-align: left;
            }
        }
    }

    .page-builder {
        .btn-grid {
            margin-bottom: 7px;
            padding-right: 5px;
        }

        .ge-canvas {
            &.ge-editing {
                .row {
                    padding: 15px;
                }

                .ge-tools-drawer {
                    margin: 10px;
                }
            }

            &.ge-layout-desktop [class*="col-"] {
                width: inherit !important;
            }
        }
    }

    .wizard-4 {
        .step-container {
            div {
                &.content {
                    margin-top: 10px;
                }
            }
        }
    }

    .nav-tabs {
        .nav-item {
            &.show {
                .nav-link {
                    border-top: 0;
                    border-bottom: 0;
                }
            }
        }

        .nav-link {
            border-top: 0;
            border-bottom: 0;
            border-radius: 0;

            &:hover,
            &.active,
            &:focus {
                border-top: 0;
                border-bottom: 0;
            }
        }
    }

    .border-tab {
        &.nav-tabs {
            .nav-item {
                width: 100% !important;
            }
        }

        .weather-widget-two {
            .bottom-whetherinfo {
                .whether-content {
                    top: 35px;
                }
            }
        }

        .social-widget-card {
            .b-b-light {
                padding: 15px;
                margin-bottom: 15px;
            }
        }

        .ecommerce-widget {
            .progress-showcase {
                margin-top: 15px;
            }
        }
    }

    .nav {
        display: inherit;
        text-align: center;
        border-bottom: none;
    }

    // editor_container
    .editor_container {
        .CodeMirror {
            min-height: 190px;
            height: 190px;
        }
    }

    // order history
    .order-history {
        table.dataTable.table {
            thead {
                th {
                    padding-right: 60px;
                }
            }
        }
    }

    // tab material start
    .border-tab.nav-left {
        .nav-link {
            text-align: center;
            border-left: none;
        }
    }

    .border-tab.nav-right {
        .nav-link {
            text-align: center;
            border-right: none;
        }
    }

    .border-tab.nav-tabs {
        margin-bottom: 10px;
    }

    .tab-content {
        ~ .nav-tabs {
            &.border-tab {
                margin-top: 10px;
            }
        }
    }

    // tab material end
    .nav-pills {
        text-align: center;
    }

    .crm-overall {
        margin: 0 -15px -15px;
    }

    .product-color {
        li {
            width: 15px;
            height: 15px;
        }
    }

    .product-social {
        li {
            a {
                width: 30px;
                height: 30px;
                font-size: 13px;
            }

            &:nth-child(n + 2) {
                margin-left: 5px;
            }
        }
    }

    .product-page-main {
        padding: 15px;

        .m-t-15 {
            .btn {
                margin-top: 10px;
            }
        }

        .product-page-details {
            h3 {
                font-size: 24px;
            }
        }
    }

    .todo {
        .action-box {
            &.large {
                height: 25px;
                width: 25px;

                .icon {
                    font-size: 14px;
                    vertical-align: -3px;
                }
            }
        }

        .todo-list-wrapper {
            #todo-list {
                li {
                    .task-container {
                        .task-label {
                            font-size: 14px;
                        }
                    }
                }
            }

            .mark-all-tasks {
                right: 15px;
            }
        }
    }

    .datetime-picker {
        label {
            text-align: left !important;
        }
    }

    .redial-chart-block {
        text-align: center;
    }

    #nav-tabContent {
        margin-top: 15px;
    }

    .reset-password-box {
        width: 430px;
    }

    .auth-bg {
        padding: 25px 15px;
    }

    .auth-bg-effect {
        display: none;
    }

    .date-picker {
        .text-right {
            text-align: left !important;
        }
    }

    .dataTables_wrapper {
        table {
            &.dataTable {
                margin-bottom: 15px !important;
            }
        }
    }

    ul {
        &.pagination {
            li {
                &:before {
                    line-height: 3;
                }
            }
        }
    }

    div {
        &.table-responsive {
            > div {
                &.dataTables_wrapper {
                    > div {
                        &.row {
                            > div {
                                &[class^="col-"] {
                                    &:last-child {
                                        padding-left: 0 !important;
                                    }

                                    &:first-child {
                                        padding-right: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.dataTables_wrapper {
            div {
                &.dataTables_length {
                    margin-bottom: 15px;

                    select {
                        min-height: 30px;
                    }
                }
            }

            table {
                &.dataTable {
                    th,
                    td {
                        padding: 0.5rem;
                    }
                }
            }

            div {
                &.dataTables_paginate {
                    margin-top: 15px !important;

                    .paginate_button {
                        padding: 1px 7px;

                        &.previous {
                            font-size: 0;
                            padding: 0;

                            a {
                                border: none !important;
                            }

                            &:before {
                                content: "\e64a";
                                font-family: $font-themify;
                                font-size: 11px;
                                padding: 0 5px;
                            }
                        }

                        &.next {
                            font-size: 0;
                            padding: 0;

                            a {
                                border: none !important;
                            }

                            &:before {
                                content: "\e649";
                                font-family: $font-themify;
                                font-size: 11px;
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }

            .dataTables_filter {
                margin-bottom: 15px;

                input {
                    &[type="search"] {
                        height: 30px;
                    }
                }
            }
        }
    }

    .page-wrapper {
        .page-body-wrapper {
            .user-profile {
                .profile-img-style {
                    padding: 15px;

                    .img-container {
                        margin-top: 15px;
                    }
                }

                hr {
                    margin: 15px 0;
                }

                .like-comment {
                    margin-top: 15px;
                }
            }
        }
    }

    .jvector-map-height {
        height: 250px;
    }

    .user-profile {
        .order-sm-0 {
            order: -1;
        }

        .hovercard {
            .info {
                .ttl-info {
                    text-align: center !important;
                }

                .ttl-xs-mt {
                    margin-top: 20px;
                }
            }
        }
    }

    .icon-hover-bottom {
        .form-group {
            display: block !important;
            margin-bottom: 0;

            input {
                width: 100% !important;
            }

            .btn {
                margin-top: 15px;
            }
        }

        .icon-popup {
            padding: 15px;
        }

        svg {
            &.climacon {
                height: 50px;
                width: 50px;
            }
        }
    }

    .blog-box {
        &.blog-shadow {
            &:before {
                box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
            }
        }

        .blog-details {
            p,
            .single-blog-content-top {
                margin-top: 15px;
            }

            h4 {
                margin-top: 15px;
            }

            .blog-social {
                margin-top: 15px;

                li {
                    & + li {
                        padding-left: 20px;

                        > span {
                            display: none;
                        }
                    }

                    &:first-child {
                        margin-right: 10px;
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    .comment-box {
        padding-top: 40px;
        padding-bottom: 40px;

        ul {
            ul {
                margin-left: 30px;
            }
        }

        li {
            .media {
                display: block;

                img {
                    height: 50px;
                    width: 50px;
                    padding: 3px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .ace-editor {
        height: 320px;
    }

    .gallery {
        > a {
            margin-bottom: 15px;
            text-align: center;
        }
    }

    .card {
        .card-header {
            h5 {
                font-size: 17px;
            }
        }
    }

    .all-package-heding5 {
        font-size: 13px !important;
    }

    .theme-form {
        .form-group {
            margin-bottom: 15px;
        }
    }

    .page-wrapper {
        .page-body-wrapper {
            .default-according {
                .card {
                    .card-header,
                    .card-body,
                    .card-footer {
                        padding: 0.75rem 1.25rem;
                    }
                }
            }

            .card {
                margin-bottom: 20px;

                .card-header,
                .card-body,
                .card-footer {
                    .tab-content {
                        .m-t-30 {
                            margin-top: 20px !important;
                        }

                        .m-b-30 {
                            margin-bottom: 20px !important;
                        }
                    }
                }
            }

            .page-body {
                padding: 0 !important;
            }

            .page-title {
                padding-top: 15px;
                padding-bottom: 15px;

                .row {
                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }
        .page-header {
            .header-wrapper {
                .search-full {
                    input {
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    /* alert responsive css start here */
    $alert-name: primary, secondary, success, danger, warning, info, light, dark;
    $alert-color: var(--theme-color), $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
        $light-color, $dark-color;

    @each $var in $alert-name {
        $i: index($alert-name, $var);

        .alert-#{$var}.inverse {
            background-color: transparent;
        }
    }

    .alert {
        .close {
            height: 100%;
        }
    }

    //business card
    .calender-widget {
        .cal-desc {
            padding: 10px 15px 15px !important;

            p {
                max-height: 100%;
            }
        }
    }

    //user profile
    .user-profile {
        hr {
            margin: 15px 0;
        }

        .hovercard {
            .cardheader {
                height: 300px;
            }

            .info {
                padding: 20px;

                .user-designation {
                    border-top: 1px solid $light-color;
                    border-bottom: 1px solid $light-color;
                    margin-bottom: 15px;
                    padding: 15px 0px;
                }
            }

            .social-media {
                a {
                    margin-right: 0px;
                    font-size: 16px;
                }
            }

            .follow {
                .follow-num {
                    font-size: 20px;
                }
            }
        }

        .profile-img-style {
            .pictures {
                img {
                    margin-bottom: 10px;
                }
            }
        }
    }

    //user card
    .custom-card {
        .card-footer {
            > div {
                h6 {
                    font-size: 14px;
                    font-weight: 600;
                }

                h3 {
                    font-size: 24px;
                }
            }
        }
    }

    //calendar
    .calendar-wrap {
        .fc-toolbar {
            .fc-center {
                margin-top: 10px;
            }
        }

        a.fc-more {
            font-size: 0.7em;
        }
    }

    // megaoption page responsive scss
    .mega-inline {
        display: block;
    }

    .megaoptions-border-space-sm {
        padding-bottom: 30px !important;
    }

    //blog
    .blog-box.blog-shadow {
        .blog-details {
            padding: 15px;

            p {
                margin-bottom: 0;
            }

            h4 {
                margin-bottom: 10px;
            }
        }
    }

    .blog-box {
        &.blog-list {
            .blog-details {
                padding-top: 0;
            }
        }

        .blog-details {
            padding: 20px;
        }

        .blog-date {
            span {
                font-size: 25px;
            }
        }
    }

    .blog-single {
        .blog-box {
            .blog-details {
                padding: 0;
            }
        }
    }

    //error and maintenace
    .error-wrapper {
        padding: 20px 0;

        .btn {
            margin-top: 15px;
        }

        .img-100 {
            width: 50px !important;
        }

        .error-heading {
            margin-top: 20px;

            .cloud-second {
                margin-top: -60px;
            }

            .headline {
                font-size: 150px;
            }
        }

        .maintenance-heading {
            .cloud-second {
                display: none;
            }

            .headline {
                font-size: 25px;
                margin-top: -10%;
                letter-spacing: 2px;
            }
        }
    }

    //coming soon
    .comingsoon {
        .comingsoon-inner {
            .coming-soon-bottom-link {
                margin-top: 20px;
                line-height: 1;
            }

            h5 {
                margin-bottom: 20px;
                margin-top: 20px;
            }

            .countdown {
                padding: 20px 0px;

                ul li {
                    margin: 0 7px;
                }

                .title {
                    font-size: 12px;
                }

                .time {
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                    margin: 0 auto;
                }
            }
        }
    }

    // Form Wizard One start
    .form-wizard {
        .btn-mb {
            margin-bottom: 15px;
        }
    }

    // Form Wizard Two Start
    .stepwizard {
        margin-bottom: 15px;
    }

    // Form Wizard Three Start
    .f1 {
        .f1-steps {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    // form wizard--4 scss
    .wizard-4 {
        ul.anchor {
            width: 100%;
            padding-bottom: 15px;
            padding-right: 0;
        }

        .step-container {
            width: 100%;
        }
    }

    //chart
    .status-widget {
        .text-sm-right {
            text-align: right;
        }
    }

    //mega menu
    .mega-menu {
        padding-left: 15px;

        .onhover-show-div {
            height: 405px;
            left: 0;
            top: 61px;
            padding: 15px;
        }

        .lg-mt {
            margin-top: 10px;
        }

        .xs-mt {
            margin-top: 15px;
        }
    }

    // navs page
    .nav-md-mt {
        margin-top: 15px;
    }

    .navs-icon {
        padding: 15px;

        .main-section {
            padding-top: 15px;
        }

        .separator {
            margin: 15px 0;
        }
    }

    .nav-list {
        padding: 15px;
    }

    .navs-dropdown {
        button {
            margin-top: 15px;
        }

        .onhover-show-div {
            top: 64px;
        }
    }

    .lg-mt {
        margin-top: 15px;
    }

    // scroll reval
    #aniimated-thumbnials {
        a {
            &:last-child {
                img {
                    margin-bottom: 15px;
                }
            }
        }
    }

    // tilt
    .tilt-showcase {
        .mt-4 {
            margin-top: 15px !important;
        }

        ol {
            .m-b-20 {
                margin-bottom: 15px !important;
            }
        }
    }
    .xs-mt {
        margin-top: 15px;
    }
    span:last-child.tag-pills-sm-mb {
        margin-top: 3px;
    }

    .animated-modal {
        .form-group {
            .form-control {
                width: 50%;
                margin: 0 auto;
            }
        }

        .animated-modal-md-mb {
            margin-bottom: 15px !important;
        }
    }

    // steps page
    .steps-md-mt {
        margin-top: 15px;
    }

    .steps-sizing-sm-mb {
        margin-bottom: 15px;
    }

    // touchspin page
    .bootstrap-touchspin {
        .touchspin-vertical-tab {
            .input-group {
                width: 96% !important;
            }
        }
    }

    // datatable API page
    #API-chield-row_wrapper {
        #API-chield-row {
            tbody {
                tr {
                    td {
                        &:first-child {
                            min-width: 20px;
                        }
                    }
                }
            }
        }
    }

    // chart-flot page
    .chart-block {
        .flot-chart-container {
            p#choices {
                width: 100%;
            }

            #toggling-series-flot {
                width: 100% !important;
            }
        }
    }

    // summer-note page
    .click2edit {
        & ~ .note-editor {
            &.note-frame {
                margin-bottom: 15px;
            }
        }
    }

    // support ticket page
    .ecommerce-widget {
        .progress-showcase {
            margin-top: 15px;
        }
    }

    // forget-password page
    .reset-password-box {
        .theme-form {
            .form-group {
                .btn {
                    margin-top: 13px !important;
                }
            }
        }
    }

    // gallery-with-description page
    .gallery-with-description {
        a {
            > div {
                margin-bottom: 15px;
            }
        }
    }

    // gallery hover page
    .gallery-img-mb-sm {
        margin-bottom: 15px;
    }

    // peity chart page
    .xm-mb-peity {
        margin-bottom: 8px;
    }

    // login page
    .authentication-main {
        padding: 30px 15px;
    }

    .translate_wrapper.active {
        .more_lang {
            &:before,
            &:after {
                display: none;
            }

            &.active {
                position: fixed;
                width: calc(100vw - 30px);
                left: 15px;
                top: 60px;
            }
        }
    }

    .page-header {
        .header-wrapper {
            .nav-right {
                .profile-dropdown {
                    top: 43px;
                }
            }
        }
    }

    .wizard-4 {
        .action-bar {
            .btn {
                margin: 0 2px;
            }
        }
    }

    .tabbed-card {
        ul {
            right: 0px;
            left: unset;
        }
    }

    // dropdown-basic
    .dropdown-basic {
        .btn-group {
            margin-right: unset;
        }
    }

    // dropdown-basic
    .order-left-image {
        display: block;
        text-align: center;

        .tracking-product-image {
            width: 60%;
            margin: 20px auto;
        }
    }
}

@media only screen and (max-width: 480px) {
    .page-wrapper.only-body .page-header .header-wrapper .nav-right {
        display: none;
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                display: block;

                .product-img {
                    width: 100%;
                }
            }
        }
    }

    .pagination-lg {
        .page-link {
            font-size: 1rem;
        }
    }

    h6 {
        font-size: 14px;
    }

    .prooduct-details-box {
        .media-body {
            margin-left: 0rem !important;
        }
    }

    .left-header {
        .level-menu {
            .header-level-menu {
                width: 140px;
                padding: 0.7rem 0;

                > li > .header-level-sub-menu {
                    padding: 0.7rem 0;
                    width: 120px;
                    left: 140px;
                }

                svg {
                    display: none;
                }
            }
        }
    }

    .box-layout {
        &.page-wrapper {
            .set-col-6,
            .set-col-3,
            .set-col-7,
            .set-col-5 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .chart_data_right {
        .knob-block {
            > div:not(.chart-clock-main) canvas {
                width: 70px !important;
                height: 70px !important;
            }
        }
    }

    .knob-block {
        .chart-clock-main {
            margin: auto;
            width: auto;

            .clock-medium {
                position: absolute;
                left: 33px;
                top: 33px;

                canvas {
                    width: 180px !important;
                    height: 180px !important;
                }
            }

            .clock-small {
                left: 34px;
                top: 74px;
            }

            .clock-large {
                left: 0;
                top: 0;
                margin: 0 auto;

                canvas {
                    width: 245px !important;
                    height: 245px !important;
                }
            }
        }

        > div:not(.chart-clock-main) {
            canvas {
                width: 245px !important;
                height: 245px !important;
            }
        }
    }

    .wizard-4 {
        .action-bar {
            .btn {
                width: 100%;

                & + .btn {
                    margin-top: 10px;
                }
            }
        }
    }

    ul {
        &.notification-dropdown {
            &.onhover-show-div {
                width: 260px;
                right: -80px;

                &:before,
                &:after {
                    right: 89px !important;
                }
            }
        }
    }

    .inline-block-sm {
        display: inline-block;
    }

    .pagination-lg {
        .page-link {
            padding: 0.3rem 0.65rem;
        }
    }

    .nav-pills {
        li {
            width: 100%;
        }
    }

    .reset-password-box {
        width: 290px;
    }

    .icon-hover-bottom {
        .icon-first {
            margin-right: 10px;
        }

        .icon-popup {
            .icon-class {
                display: none;
            }
        }
    }

    .error-wrapper {
        .maintenance-heading {
            margin-top: 0;
        }
    }

    .custom-card {
        padding: 0;

        .card-footer {
            > div {
                h3 {
                    font-size: 20px;
                }
            }
        }

        .card-social {
            li {
                padding: 5px 0;
            }
        }

        .profile-details {
            h6 {
                margin-bottom: 15px;
            }

            h4 {
                font-size: 20px;
            }
        }

        .card-profile {
            img {
                height: 93px;
            }
        }
    }

    .card {
        .card-header {
            .card-header-right {
                i {
                    margin: 0 3px;
                    font-size: 14px;
                }
            }

            > span {
                & + span {
                    display: none;
                }
            }
        }
    }

    // timeline small page css
    .timeline-small {
        .media {
            .timeline-round {
                &.timeline-line-1 {
                    &:after {
                        bottom: -50px;
                    }
                }

                &.medium-line {
                    &:after {
                        bottom: -40px;
                    }
                }
            }
        }
    }

    // dropdown page
    .dropdown-basic {
        text-align: center;
    }

    .dropup-basic {
        text-align: center;
    }

    //blog
    .blog-box.blog-shadow {
        .blog-details {
            padding: 10px;

            h4 {
                font-size: 14px;
            }
        }
    }

    .cal-date-widget {
        padding: 30px;
    }

    .calender-widget {
        .cal-img {
            height: 225px;
        }
    }

    .company-card {
        .details {
            .media {
                flex-wrap: wrap;
            }
        }
    }

    // from wizard two
    .stepwizard {
        .stepwizard-row {
            &:before {
                top: 17px;
            }

            .stepwizard-step {
                .btn {
                    padding: 6px 22px;
                }
            }
        }
    }

    // email app
    .email-wrap {
        .email-right-aside {
            .email-content {
                .email-wrapper {
                    .attachment {
                        ul {
                            li {
                                img {
                                    width: 83px;
                                    height: 83px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //mega menu
    .mega-menu {
        padding-left: 20px;
        padding-top: 3px;

        .onhover-show-div {
            height: 405px;
            left: 0;
            top: 61px;
            padding: 15px;
        }
    }

    //  tag-pills
    .badge {
        padding: 0.42em 0.7em;
    }

    // alert page
    .alert-dismissible {
        .close {
            top: -1px;

            span {
                font-size: 19px;
            }
        }
    }

    // bootstrap notify page
    .notify-alert {
        width: 90%;
    }

    // chat page
    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-header {
                    display: block;

                    .chat-menu-icons {
                        margin-top: 15px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    .package-card {
        display: block !important;

        div {
            h5 {
                margin-bottom: 10px !important;
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .product-sidebar {
        &.open {
            top: -75px;
        }
    }

    .alert-theme {
        font-size: 12px;
        min-width: 300px;
        right: 0 !important;
        width: 100%;

        button {
            right: 30px !important;
        }

        i {
            display: none;
        }

        span {
            + span {
                + span {
                    padding: 15px;
                }
            }
        }
    }

    .and-many-more {
        font-size: 37px;
    }

    // tab css
    .tabbed-card {
        ul {
            &.border-tab {
                &.nav-tabs {
                    .nav-item {
                        .nav-link {
                            padding: 5px 15px 8px;
                        }
                    }
                }
            }
        }
    }

    .border-tab {
        &.nav-tabs {
            display: block;
        }
    }

    // compact sidebar css
    .page-wrapper {
        &.compact-sidebar {
            .page-body-wrapper {
                div.sidebar-wrapper {
                    > div {
                        width: 112px;
                    }

                    .sidebar-main {
                        .sidebar-links {
                            li {
                                .sidebar-submenu,
                                .mega-menu-container {
                                    left: 112px;
                                    width: 190px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.compact-small {
            .page-body-wrapper {
                div.sidebar-wrapper {
                    > div {
                        width: 80px;
                    }

                    .sidebar-main {
                        .sidebar-links {
                            li {
                                .sidebar-submenu,
                                .mega-menu-container {
                                    left: 80px;
                                    width: 210px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-header {
            .header-wrapper {
                .nav-right {
                    .onhover-show-div{
                        right: 0;
                    }
                }
            }
        }
    }

    // login form
    .login-card {
        .login-main {
            .theme-form {
                .link {
                    position: unset;
                }
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .product-box {
        .modal {
            .modal-header {
                .product-box {
                    .product-details {
                        .product-qnty {
                            fieldset {
                                .input-group {
                                    width: 45%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .page-wrapper {
        &.compact-wrapper {
            .page-body-wrapper {
                .page-body {
                    padding-bottom: 70px !important;
                }
            }
        }
    }

    .header-search {
        display: none !important;
    }

    .prooduct-details-box {
        .close {
            right: 0;
            top: 5px;
        }

        .btn {
            padding: 4px 8px;
        }

        .media {
            padding: 15px;
            display: block;

            .media-body {
                margin-top: 15px;
            }
        }
    }

    .datepicker--nav-action {
        width: 20px;
        margin-left: -10px;
        background-color: transparent;

        path {
            stroke: var(--theme-color);
        }
    }

    .clockpicker-align-top {
        top: 426px !important;
    }

    .note {
        min-width: 250px;
    }

    .datepicker {
        width: 239px;
    }

    .add-post {
        form {
            .m-checkbox-inline {
                label {
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .map-js-height {
        height: 250px;
    }

    .bar-chart-widget {
        .earning-details {
            i {
                right: -73px;
            }
        }
    }

    .widget-joins {
        .border-after-xs {
            &:after {
                width: 92%;
            }
        }
    }

    // chart widget page css
    .status-widget {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    // social app page css
    .tabs-scoial {
        &.border-tab {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 10px 5px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .user-profile {
        .hovercard {
            .user-image {
                .share-icons {
                    right: 6px;
                    top: -15px;

                    li {
                        .social-icon {
                            width: 25px;
                            height: 25px;

                            i {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .social-chat {
        .other-msg {
            margin-left: 15px;
        }
    }

    .social-header {
        h5 {
            span.pull-right {
                display: none;
            }
        }
    }

    // timeline small page css
    .timeline-small {
        .media {
            .timeline-round {
                &.timeline-line-1 {
                    &:after {
                        bottom: -74px;
                        height: 56px;
                    }
                }

                &.small-line {
                    &:after {
                        bottom: -32px;
                        height: 14px;
                    }
                }

                &.medium-line {
                    &:after {
                        bottom: -59px;
                        height: 40px;
                    }
                }
            }

            .media-body {
                p {
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 4;
                }
            }
        }
    }

    .custom-card {
        .dashboard-card {
            padding-top: 0;
        }

        .card-profile {
            img {
                bottom: 25px;
            }
        }

        .card-footer {
            > div {
                h6 {
                    font-size: 12px;
                }
            }
        }
    }

    .flot-chart-container {
        height: 230px;
    }

    .map-block {
        height: 250px;
    }

    .img-cropper {
        .docs-tooltip {
            font-size: 11px;
        }

        .docs-toggles {
            .btn-group {
                .btn {
                    padding: 0.375rem 0.2rem;
                }
            }
        }
    }

    .blog-box {
        .blog-details {
            p {
                line-height: 1.5;
            }

            .blog-social {
                li:last-child {
                    padding-left: 0;
                }
            }
        }

        .blog-details-main {
            .blog-social {
                li {
                    padding: 0 10px;
                }
            }
        }
    }

    .button-builder-wrap {
        .btn-lg {
            font-size: 18px;
        }
    }

    .tabs-responsive-side {
        max-width: 100%;
    }

    //default dashboard
    .browser-widget {
        .media {
            i {
                font-size: 52px;
            }

            .media-body {
                h4 {
                    font-size: 20px;
                }
            }
        }
    }

    .bar-chart-widget {
        .bottom-content {
            .num {
                font-size: 17px;
            }
        }
    }

    // serach page
    .search-page {
        .theme-form {
            .input-group {
                .form-control-plaintext {
                    padding-left: 10px;
                }

                .btn {
                    padding: 6px 4px;
                }
            }
        }

        .info-block {
            padding: 20px;
        }
    }

    // from wizard two
    .stepwizard {
        .stepwizard-row {
            .stepwizard-step {
                .btn {
                    padding: 6px 20px;
                }
            }
        }
    }

    .user-status {
        table {
            tbody {
                img {
                    height: 40px;
                }
            }
        }
    }

    //from wizard four
    .wizard-4 {
        ul {
            &.anchor {
                padding-top: 15px;
            }
        }

        .msg-box {
            top: 40px !important;
        }
    }

    .xs-width-100 {
        min-width: 100%;
    }

    // email app
    .email-wrap {
        .action-wrapper {
            .actions {
                li {
                    margin-right: 5px;
                }
            }
        }

        .email-body {
            .attachment {
                .list-inline {
                    .list-inline-item {
                        padding-right: 5px;
                    }
                }
            }
        }

        .email-right-aside {
            .email-content {
                .email-wrapper {
                    .attachment {
                        ul {
                            li {
                                img {
                                    width: 65px;
                                    height: 65px;
                                }
                            }
                        }
                    }
                }
            }

            .email-top {
                .dropdown-menu {
                    left: -58px;
                }
            }
        }
    }

    // form-builder
    .form-builder {
        #components {
            .component {
                .form-group {
                    #button1id {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    // according
    .default-according {
        .card {
            .card-header {
                i {
                    font-size: 14px;
                    top: 24px;
                }
            }

            .btn-link {
                display: block;
                width: 100%;
                padding-left: 17px;
            }
        }
    }

    //rating
    .star-ratings {
        .stars {
            .title {
                line-height: 1;
            }
        }
    }

    // tour page
    .user-profile {
        .hovercard {
            .social-media {
                a {
                    padding: 0 12px;
                }
            }
        }
    }

    // touchspin page
    .bootstrap-touchspin {
        .touchspin-vertical-tab {
            .input-group {
                width: 93% !important;
            }
        }
    }

    // form-wizard-four page
    .step-container {
        height: 280px !important;
    }

    // jsgrid-table page
    .sort-panel {
        .d-inline {
            .btn {
                margin-left: 87px;
            }
        }
    }

    // jsgrid-table css
    .jsgrid {
        .jsgrid-pager-container {
            .jsgrid-pager {
                .jsgrid-pager-page {
                    padding: 0.5em 0.62em;
                }
            }
        }
    }

    // chat page
    .chat-box {
        .chat-right-aside {
            flex: 0 0 96%;
            max-width: 96%;

            .chat {
                .chat-message {
                    width: calc(100% - 20px);
                }
            }
        }
    }

    // error 400 page
    .error-wrapper {
        .error-heading {
            .headline {
                font-size: 100px;
            }
        }
    }

    // gallery page
    .lg-toolbar {
        .lg-icon {
            width: 40px;
        }
    }

    // calender page
    .calendar-wrap {
        a.fc-more {
            font-size: 0.59em;
        }

        .fc {
            th {
                font-size: 10px;
            }
        }
    }

    //modal footer
    .modal-footer {
        display: block;
        text-align: center;

        button:first-child {
            margin-bottom: 10px;
        }
    }

    // checkout
    .order-box {
        .qty {
            li {
                font-size: 14px;

                span {
                    font-size: 14px;
                }
            }
        }

        ul {
            li {
                span {
                    text-align: right;
                }

                label {
                    text-align: right;
                    font-size: 14px;
                }
            }
        }

        .sub-total {
            li {
                .count {
                    font-size: 14px;
                }
            }
        }
    }

    .checkout {
        .text-end {
            text-align: left !important;
            margin-top: 15px;
        }
    }

    // dropdown-basic
    .order-left-image {
        .tracking-product-image {
            width: 100%;
        }
    }

    .list-table {
        tbody {
            tr {
                td {
                    &:nth-child(4) {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 578px) {
    .datepicker-dashboard {
        .datepicker {
            height: 340px;
        }

        .datepicker--days-names {
            font-size: 16px;
            margin: 12px 0;
        }

        .datepicker--cells-days {
            height: 170px;
        }
    }

    .btn-group .btn {
        font-size: 12px;
        padding: 0.375rem 0.5rem;
    }
}

@media only screen and (min-width: 576px) {
    .product-wrapper-grid {
        &.list-view {
            .col-sm-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .sidebar-wrapper {
        .back-btn {
            display: none;
        }
    }
}

@media only screen and (max-width: 575px) {
    .rtl-ltr-btn {
        display: inline-block;
    }
}

@media only screen and (max-width: 359px) {
    .apexcharts-legend-marker {
        margin-left: 0;
    }
}

@media only screen and (max-width: 396px) {
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .product-button {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .checkout {
        .checkout-details {
            padding: 25px;
        }
    }

    .order-box {
        .title-box {
            span {
                width: 30%;
            }
        }

        .sub-total {
            li {
                .count {
                    width: 38%;
                }
            }

            .shipping-class {
                .shopping-checkout-option {
                    width: 46%;
                }
            }
        }

        .qty {
            li {
                span {
                    width: 38%;
                }
            }
        }

        .total {
            li {
                .count {
                    width: 38%;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .order-box {
        .sub-total {
            .shipping-class {
                .shopping-checkout-option {
                    width: 38%;
                }
            }
        }
    }
    .page-wrapper {
        &.compact-wrapper {
            .page-header {
                .header-wrapper {
                    .toggle-sidebar {
                        display: block !important;
                        font-size: 20px;
                        display: flex !important;
                        gap: 5px;
                        border-right: none;
                        width: 100%;
                        line-height: 1;
                        a {
                            width: calc(100px + (140 - 100) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .checkout {
        .checkout-details {
            padding: 15px;
        }
    }

    .order-box {
        .sub-total {
            .shipping-class {
                .shopping-checkout-option {
                    width: 50%;
                }
            }

            li {
                .count {
                    width: 50%;
                }
            }
        }
    }
}

/**=====================
       Responsive CSS Ends
  ==========================**/
