/**=====================
    Skeleton Loader CSS Start
==========================**/
.skeleton-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    padding: calc(8px + 6 * (100vw - 320px) / 1600);
    z-index: 1;
    background-color: #f9f9f6;
}

.skeleton *:empty {
    background: #f6f7f8;
    background-position: -500px 0;
    animation: skeletonShine 1s linear 0s infinite normal forwards;
    border-radius: 5px;
    background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    width: 100%;
}

.skeleton__section + .skeleton__section {
    margin-top: 16px;
}

.skeleton-section {
    width: 100%;
}

.skeleton__section--actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 16px;
}

.skeleton__section--card {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.skeleton__header {
    margin-bottom: 6px;
    height: 32px;
    width: 200px;
    max-width: 35%;
}

.skeleton__header--long {
    width: 300px;
    max-width: 85%;
}

.skeleton__p {
    height: 16px;
    width: 100%;
    margin-bottom: 8px;
}

.skeleton__p:last-child {
    width: 45%;
}

.skeleton__p--short {
    max-width: 120px;
}

.skeleton__img {
    height: 110px;
    width: 64px;
    border-radius: 8px;
}

.skeleton__tree .skeleton__p {
    width: 100%;
    max-width: none;
}

@keyframes skeletonShine {
    to {
        background-position: 500px 0;
    }
}
