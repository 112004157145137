/**=====================
     Loader CSS Start
==========================**/
.modal-open,
.loader-none {
    .loader-wrapper {
        display: none;
    }
}

.loader-wrapper,
.custom-loader-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    height: calc(100vh - 138px);
    background-color: #f9f9f6;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
    top: 90px;
    padding-left: 280px;

    [dir="rtl"] & {
        padding-left: 0;
        padding-right: 280px;
        left: unset;
        right: 0;
    }

    @media (max-width: 991px) {
        padding-left: 0;
        [dir="rtl"] & {
            padding-right: 0;
        }
    }

    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid $white;
        border-bottom-color: var(--theme-color);
        border-radius: 50%;
        display: inline-block;
        animation: rotation 1s linear infinite;
    }

    h3 {
        margin-top: 6px;
        color: #777;
        font-weight: 400;
    }
}

.page-wrapper {
    &.compact-wrapper {
        .page-body-wrapper {
            div.sidebar-wrapper {
                .skeleton-loader {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100vh;
                    background: linear-gradient(-45deg, #0da487, #009289, #ffa53b);
                    background-size: 400% 400%;
                    animation: gradient 15s ease infinite;
                    z-index: 1;
                    pointer-events: none;
                    user-select: none;
                    .sidebar-main {
                        .sidebar-links {
                            overflow: hidden;
                            li {
                                &.sidebar-list {
                                    .sidebar-submenu {
                                        display: none;
                                    }
                                    a {
                                        align-items: center;
                                        margin-top: 4px !important;
                                        justify-content: unset;

                                        .according-menu {
                                            display: none;
                                        }

                                        i {
                                            width: 20px;
                                            height: 20px;
                                            background: linear-gradient(
                                                90deg,
                                                rgba(212, 212, 212, 0.3) 8%,
                                                rgba(212, 212, 212, 0.3) 33%
                                            );
                                            border-radius: 6px;
                                            font-size: 0;
                                        }

                                        .sidebar-main-link {
                                            background: linear-gradient(
                                                90deg,
                                                rgba(212, 212, 212, 0.3) 8%,
                                                rgb(255 255 255 / 32%) 18%,
                                                rgba(212, 212, 212, 0.3) 33%
                                            );
                                            background-size: 800px 104px;
                                            animation: skeleton-loader 2s infinite linear;
                                            border-radius: 5px;
                                            color: transparent;
                                            height: 13px;
                                            padding-right: 30px;
                                            width: 90px;
                                        }
                                    }
                                    &:nth-child(2),
                                    &:nth-child(5),
                                    &:nth-child(14) {
                                        a {
                                            .sidebar-main-link {
                                                width: 90px;
                                            }
                                        }
                                    }
                                    &:nth-child(3),
                                    &:nth-child(7),
                                    &:nth-child(13) {
                                        a {
                                            .sidebar-main-link {
                                                width: 130px;
                                            }
                                        }
                                    }
                                    &:nth-child(4),
                                    &:nth-child(8),
                                    &:nth-child(11),
                                    &:nth-child(12) {
                                        a {
                                            .sidebar-main-link {
                                                width: 70px;
                                            }
                                        }
                                    }
                                    &:nth-child(5),
                                    &:nth-child(9),
                                    &:nth-child(10) {
                                        a {
                                            .sidebar-main-link {
                                                width: 110px;
                                            }
                                        }
                                    }
                                    &:nth-child(14),
                                    &:nth-child(17) {
                                        a {
                                            .sidebar-main-link {
                                                width: 60px;
                                            }
                                        }
                                    }
                                }
                                .sidebar-link {
                                    &.active {
                                        &:before {
                                            opacity: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-box-loader {
    position: relative;
    min-height: 350px;
    .box-loader {
        .loader-wrapper,
        .custom-loader-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 0;
            gap: 0;
        }
    }
}

.blur-bg {
    background-color: rgba(255, 255, 255, 0.9) !important;
}

@keyframes skeleton-loader {
    0% {
        transform: translateZ(0);
        background-position: -468px 0;
    }

    100% {
        transform: translateZ(0);
        background-position: 468px 0;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
