/**=====================
    Notification CSS start
==========================**/
.notification-setting {
    li {
        padding: 14px;
        border-radius: 5px;
        position: relative;
        background-color: #f9f9f6;
        text-transform: capitalize;
        h4 {
            margin-bottom: 6px;
            color: $grey-3;
            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
        }
        h5 {
            color: $grey-9;
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 15px;
        }
        .delete-icon {
            position: absolute;
            right: 20px;
            top: 10px;
            font-size: 18px;
            color: #222;
            i {
                padding: 2px;
                background-color: $white;
                border-radius: 3px;
                box-shadow: 0 0 8px #e3e3e3;
            }
        }
        &.unread {
            background-color: #f0f3f6;
            border-left: 2px solid var(--theme-color);
        }
        + li {
            margin-top: 18px;
        }
    }
}
