.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
}
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}
.custom-day.focused {
    background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
    background-color: #0da487;
    color: white;
}
.custom-day.faded {
    background-color: #009289;
}

.error-border {
    border: 1px solid #f00;
    padding: 3px;
    border-radius: 11px;
}