/**=====================
     Reset css start
==========================**/
.font-roboto {
    font-family: $public-sans;
}

/*====== Padding css starts ======*/
$i: 0;

@while $i<=50 {
    .p-#{$i} {
        padding: #{$i}px;
    }

    $i: $i + 5;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;

@while $i<=50 {
    .p-l-#{$i} {
        padding-left: #{$i}px;
    }

    $i: $i + 5;
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;

@while $i<=50 {
    .p-t-#{$i} {
        padding-top: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/
$i: 0;

@while $i<=50 {
    .p-b-#{$i} {
        padding-bottom: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;

@while $i<=50 {
    .p-r-#{$i} {
        padding-right: #{$i}px;
    }

    $i: $i + 5;
}

/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/
$i: 0;

@while $i<=50 {
    .m-#{$i} {
        margin: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;

@while $i<=50 {
    .m-t-#{$i} {
        margin-top: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;

@while $i<=50 {
    .m-b-#{$i} {
        margin-bottom: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;

@while $i<=50 {
    .m-l-#{$i} {
        margin-left: #{$i}px !important;
    }

    $i: $i + 5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;

@while $i<=50 {
    .m-r-#{$i} {
        margin-right: #{$i}px;
    }

    $i: $i + 5;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i: 0;

@while $i<=10 {
    .b-r-#{$i} {
        border-radius: #{$i}px !important;
    }

    $i: $i + 1;
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/
$i: 12;

@while $i<=100 {
    .f-#{$i} {
        font-size: #{$i}px !important;
    }

    $i: $i + 2;
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/
$i: 100, 300, 500, 400, 600, 700, 900;

@each $val in $i {
    .f-w-#{$val} {
        font-weight: $val;
    }
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/
$i: normal, italic, oblique, initial, inherit;

@each $val in $i {
    .f-s-#{$val} {
        font-style: $val;
    }
}

/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/

$i: overline, line-through, underline, dashed, blink, dotted, initial, none, solid, wavy, inherit, double;

@each $val in $i {
    .text-#{$val} {
        text-decoration: $val;
    }
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/
$i: baseline, sub, super, top, text-top, middle, bottom, text-bottom, initial, inherit;

@each $val in $i {
    .#{$val} {
        vertical-align: $val;
    }
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

$i: static, absolute, fixed, relative, initial, inherit;

@each $val in $i {
    .p-#{$val} {
        position: $val;
    }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i: left, right, none;

@each $val in $i {
    .f-#{$val} {
        float: $val;
    }
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/
$i: hidden, visible, auto;

@each $val in $i {
    .o-#{$val} {
        overflow: $val;
    }
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/
$i: 10;

@while $i<=100 {
    .img-#{$i} {
        width: #{$i}px !important;
    }

    $i: $i + 10;
}

/*====== Image-sizes css ends ======*/

/*======= Label-color css starts  ======= */

.label {
    border-radius: 2px;
    color: $white;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize;
}
/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */

.badge-primary {
    background-color: var(--theme-color) !important;
}

.badge-secondary {
    background-color: var(--theme-secondary) !important;
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */
.bg-primary {
    background-color: var(--theme-color) !important;
}

.bg-secondary {
    background-color: var(--theme-secondary) !important;
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */
.txt-primary {
    color: var(--theme-color) !important;
}

.txt-secondary {
    color: var(--theme-secondary) !important;
}

/*======= Font-color css end  ======= */

/*======= Button-color css starts  ======= */
.btn-light {
    background-color: $inner-bg !important;
    border-color: $light-semi-gray !important;
}

/*======= Button-color css ends  ======= */

/*====== Border width css starts ======*/
$i: 1;

@while $i<=10 {
    .border-#{$i} {
        border-width: #{$i}px !important;
    }

    $i: $i + 1;
}

/*====== Border width css ends ======*/

.opacity-0 {
    opacity: 0;
}

.map-block {
    height: 350px;
    width: 100%;
}

.map-js-height {
    height: 500px;

    .btn-group {
        input {
            background-color: $primary-color;

            & + input {
                background-color: $danger-color;
            }
        }
    }
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $light-gray;
        box-shadow: inset 0 0 6px $light-gray;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(68, 102, 242, 0.15);
    }
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.comment {
    color: #9f9ba5;
    font-style: italic;
}

.line {
    color: $dark-color;

    pre {
        font-size: 100%;
    }
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle {
    list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/
.theme-close {
    opacity: 1;
    height: 40px;
    width: 40px;
    position: absolute;
    font-weight: 400;
    z-index: 1;
    right: 0;
    background-color: $white !important;
    border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/
.options {
    > div {
        color: $gray-60;
        display: inline-block;
        padding: 2px 10px;
        border: 1px solid;
        margin: 0 8px 8px 0;
        transition: all 0.3s ease;

        &:hover {
            background-color: $primary-color;
            color: $white;
            transition: all 0.3s ease;
        }
    }
}

/**====== Animation css Ends ======**/

.modal-footer {
    flex-wrap: wrap;
}

.img-cropper {
    #putData {
        margin-bottom: 0;
    }

    .img-container {
        min-height: auto;
        margin-bottom: 0;
    }

    .docs-data {
        > .input-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .docs-preview {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.dropzone {
    .dz-preview {
        .dz-error-message {
            background: $white !important;
            color: $primary-color !important;
            border: 1px solid $primary-color;

            &:after {
                border-bottom: 6px solid $primary-color !important;
            }
        }
    }
}

.typeahead {
    .theme-form {
        .form-group {
            margin-bottom: 0;
        }
    }
}

.editor-statusbar {
    border-bottom: 1px solid rgba($black, 0.125);
}

.page-builder {
    .ge-canvas {
        &.ge-editing {
            .row {
                padding: 30px;
                margin-bottom: 0;
                background-color: rgba($primary-color, 0.02);
            }
        }
    }

    .btn-screen {
        padding: 0 18px 0 0;
    }
}

.bg-overlay {
    &.active {
        height: 100vh;
        width: 100vw;
        background-color: rgba($black, 0.2);
        position: fixed;
        z-index: 6;
        top: 0;
    }
}

button {
    &:focus {
        outline: none !important;
    }
}

.dotted {
    border-style: dotted;
}

.dashed {
    border-style: dashed;
}

.double {
    border-style: double;
}

.groove {
    border-style: groove;
}

.ridge {
    border-style: ridge;
}

.inset {
    border-style: inset;
}

.outset {
    border-style: outset;
}

@keyframes fadeIncustom {
    0% {
        opacity: 0;
        top: 50px;
    }

    75% {
        opacity: 0.6;
        top: 0;
    }

    100% {
        opacity: 1;
    }
}

.daterangepicker {
    select {
        &.monthselect,
        &.yearselect {
            border-color: $light-semi-gray;
            background-color: $white;
            font-size: 14px;
            color: $dark-gray;
            padding: 5px 10px;
            border-radius: 5px;
            width: 46%;
        }
    }
}

@media (min-width: 991px) {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
        box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
        background-color: #f5f5f5;
        border-radius: 5px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #fdf9f9;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba($primary-color, 0.5);
        border-radius: 5px;
    }
}

.feather-icons {
    line-height: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.border-up-class {
    border-top: 1px solid #eee;
}

.custom-margin {
    margin-top: -10px;
    margin-bottom: 10px;
}

.mt-custom {
    margin-top: 12px;
}

.fs-17 {
    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.rating-sec {
    color: #f7b94a;
    font-size: 20px;
    gap: 6px;
}

.ck {
    &.ck-editor {
        z-index: 0;
    }
    &.ck-editor__editable {
        &.ck-focused {
            &:not(.ck-editor__nested-editable) {
                box-shadow: none !important;
            }
        }
    }
}

.offcanvas {
    visibility: visible;
}

.w-webkit {
    width: -webkit-fill-available;
}

.ck-content {
    ul {
        padding-left: 2rem;
    }
}

// toaster
.ngx-toastr {
    border: none;
    padding: 20px 30px 20px 60px !important;
    background-color: white;
    border: 1px solid gainsboro;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.11) !important;
    margin-top: 20px !important;
    width: 390px !important;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        background-color: #0d462c;
        height: 32px;
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
    .toast-message {
        font-weight: 500;
        font-size: 16px;
    }
}


.toast-success {
    background-image: none;
    border: 1px solid #0d462c !important;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        background-color: #0d462c;
        height: 32px;
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
    &::before {
        content: "\eb7b";
        font-family: remixicon!important;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 22px;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: #0d462c;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.toast-error {
    border: 1px solid #721c2485 !important;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        background-color: #721c24;
        height: 32px;
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}

hr {
    border-color: #eee;
    opacity: 1;
}