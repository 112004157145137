/**=====================
	theme layout CSS Start
==========================**/
body.dark-only .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.5) !important;
   
}
body.dark-only .custom-select .select2-container .select2-dropdown .select2-search__field {
    color: rgba(255, 255, 255, 0.5); /* Giữ nguyên màu chữ */
}

body.dark-only .custom-select .select2-container .select2-search__field::placeholder {
    color: #888; /* Màu placeholder sáng hơn một chút */
}
body {
	.badge-light-danger {
		background-color: rgba(116, 125, 198, 0.12);
	}

	.badge-light-secondary {
		background-color: rgba(239, 63, 62, 0.12);
	}

	&.dark-only {
		color: $dark-all-font-color;
		background-color: $dark-body-background;

		.input-group-text {
			background-color: $dark-body-background;
			border-color: $dark-card-border;
			color: $dark-all-font-color;
		}

		.help-text {
			color: rgba(255, 255, 255, 0.5);
		}

		.btn-theme {
			&:hover {
				background-color: #0b141d;
			}
		}

		.dropdown-toggle {
			&::after {
				border-top-color: #9b9b9b;
			}
		}

		.dashboard-tiles .card-tiles {

			h3,
			.h3 {
				color: rgba(255, 255, 255, 0.9);
			}

			h6,
			.h6 {
				color: rgba(255, 255, 255, 0.6);
			}
		}

		.apexcharts-tooltip {
			box-shadow: 2px 2px 6px -4px rgba(153, 153, 153, 0.29);

			&.apexcharts-theme-light {
				border: 1px solid #0b141d;
				background: #1c2128;

				.apexcharts-tooltip-title {
					background: #1c2128;
					border-bottom: 1px solid transparent;
					color: #fff;
				}
			}

			.apexcharts-tooltip-series-group {
				color: #fff;
			}
		}

		.apexcharts-menu {
			background-color: #0b141d;
			border-color: #1d3436;

			.apexcharts-menu-item {
				color: inherit;
				background: transparent;
			}
		}

		.apexcharts-xaxistooltip,
		.apexcharts-yaxistooltip {
			color: #fff;
			background: #0b141d;
			border-color: #1c2128;
		}

		.apexcharts-xaxistooltip-bottom {
			&:after {
				border-bottom-color: #1c2128;
			}
		}

		.top-selling-table {
			border-color: #1d3436;
			h5, h6 {
				color: $dark-all-font-color;
			}
		}

		.btn-secondary:hover {
			background-color: #0b141d;
			border: 1px solid #1d3436;
			color: #fff;
		}

		.btn-check:checked+.btn,
		:not(.btn-check)+.btn:active,
		.btn:first-child:active,
		.btn.active,
		.btn.show {
			color: $white;
			background-color: var(--theme-color);
			border-color: var(--theme-color);
		}

		.custom-dp-dropdown {
			.ngb-dp-header {
				background-color: #1c2128;
			}

			.ngb-dp-weekdays {
				border-color: #1d3436;
			}

			.ngb-dp-weekdays,
			.ngb-dp-month-name {
				background-color: #1c2128;
			}

			.ngb-dp-navigation-select>.form-select {
				border-color: #1d3436 !important;
				background-color: #0b141d !important;
			}

			.custom-day {
				color: #fff;

				&.focused {
					background-color: #6c757d;
				}
			}
		}

		@media (min-width: 991px) {
			::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
				box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
				background-color: #1d1e26;
				border-radius: 5px;
			}

			::-webkit-scrollbar {
				width: 6px;
				height: 6px;
				background-color: #1d1e26;
			}

			::-webkit-scrollbar-thumb {
				background-color: rgba($primary-color, 0.5);
				border-radius: 5px;
			}
		}

		.color-box {
			border-color: $dark-border;
		}

		.radio-type-sec {
			.selection-box {
				border-color: $dark-border;
			}
		}

		.radio-type-sec {
			&.selection-layout {
				label {
					img {
						border-color: $dark-border;
					}
				}
			}
		}

		.notification-setting {
			li {
				background-color: $dark-body-background;
				&.unread {
					background-color: #374151;
					border-left: 2px solid var(--theme-color);
				}
				h4,
				.h4 {
					color: $dark-small-font-color;
				}
			}
		}

		.roles-form {
			>div {
				ul {
					background-color: $dark-card-background;
					border-color: $dark-border;

					li {
						&:first-child {
							color: #a3a3a3;
						}
					}
				}
			}
			.form-check {
				.checkbox_animated {
					&::after {
						background-color: $dark-body-background;
						border-color: $dark-card-border;
					}
				}
			}
		}

		.page-wrapper {
			.page-header {
				.header-wrapper {
					.nav-right {
						&.right-header {
							ul {
								li {
									.profile-media {
										.media-body {
											p {
												color: #98a6ad;
											}
										}
									}
								}
							}
						}

						.profile-dropdown {
							li {
								a {
									span {
										color: #98a6ad;
									}
								}
							}
						}
					}

					.search-full {
						.onhover-dropdown {
							background-color: #1c2128;
							box-shadow: none;

							&::-webkit-scrollbar-track {
								background: #1c2128 !important;
								box-shadow: none;
							}

							&::-webkit-scrollbar-thumb {
								border: 3px solid #1c2128;
							}

							.main-content {
								background-color: #0b141d;
								color: #cdcdcd;
							}
						}

						.close-icon {
							color: $dark-small-font-color;
						}

						input {
							&::placeholder {
								color: #0b141d;
							}
						}

						&.skeleton-header {
							pointer-events: none;

							.header-btns {
								.btn {
									background: linear-gradient(90deg, #1e1e1e 8%, #1c1c1c 18%, #0b141d 33%);
									background-size: 800px 104px;
								}
							}

							.nav-right {
								&.right-header {
									ul {
										li {
											i {
												background-color: #18181e;
												color: #18181e !important;

												&.middle {
													background-color: transparent;
												}
											}

											.profile-media {
												.profile-img {
													background: #0b141d;
												}

												.media-body {

													p,
													span {
														background: linear-gradient(90deg,
																#1e1e1e 8%,
																#1c1c1c 18%,
																#0b141d 33%);
														background-size: 800px 104px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.variant-box {
			&.border-top {
				border-color: $dark-border !important;
			}
		}

		.selection-section {
			ul {
				li {
					border-color: $dark-border;

					button {
						color: $dark-all-font-color;
					}

					button[disabled] {
						opacity: 0.2;
					}
				}
			}
		}

		.shipping-accordion {
			&.variant-accordion {
				.accordion-item {
					.accordion-button {
						color: $dark-small-font-color;
					}
				}
			}

			.accordion-item {
				background-color: #1c2128;
				border-color: $dark-border;
				color: $dark-small-font-color;

				.accordion-button {
					background-color: #0c151e;
					color: $dark-small-font-color;
					&:after {
						filter: grayscale(1) brightness(3);
					}
				}
			}
		}

		.datatable-wrapper {
			.table-loader {
				background-color: rgba(38, 41, 50, 0.87);
			}
		}

		.checkout-section-2 {
			.pos-detail-card {
				.product-details {
					.cart-listing {
						li {
							border-color: $dark-border;

							.cart-content {
								h4 {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}

			.left-sidebar-checkout {
				.checkout-detail-box {
					>ul {
						>li {
							.checkout-box {
								background-color: $dark-card-background;
								box-shadow: none;

								.checkout-detail {
									.delivery-address-box {
										background-color: $dark-body-background;
									}

									.delivery-option,
									.payment-option {
										background-color: $dark-body-background;
									}
								}
							}

							.checkout-icon {
								background-color: $dark-card-background;
								box-shadow: none;
							}
						}
					}
				}
			}
		}

		.layout-selection-sec {
			.theme-card {
				border-color: $dark-border;

				&.active {
					.content-sec {
						a {
							color: $white;
							background-color: var(--theme-color);
						}
					}
				}

				.content-sec {
					box-shadow: none;
					border-radius: 0 0 15px 15px;
					background-color: $dark-card-background;

					a {
						background-color: $dark-card-background;

						&:hover {
							color: $white;
							background-color: var(--theme-color);
						}
					}
				}

				.library-box {
					background: $dark-body-background;
					box-shadow: inset 0px 0 8px rgba(98, 98, 98, 0.66);

					.details-box {
						background-color: rgba($dark-card-background, 0.9);
						color: $dark-small-font-color;
					}
				}
			}
		}

		.blur-bg {
			background-color: rgba(28, 33, 40, 0.95) !important;
		}

		.custom-box-loader {
			.box-loader {

				.loader-wrapper,
				.custom-loader-wrapper {
					background-color: $dark-body-background;
				}
			}
		}

		.skeleton {
			*:empty {
				background: linear-gradient(90deg, #1e1e1e 8%, #1c1c1c 18%, $dark-card-background 33%);
				background-size: 1000px 100%;
			}
		}

		.inside-horizontal-tabs {
			@media (max-width: 991px) {
				background-color: $dark-card-background;
			}

			.nav-tabs {
				.nav-item {
					.nav-link {
						color: $dark-small-font-color;
					}
				}
			}
		}

		.tracking-wrapper {
			background-color: $dark-card-background;
		}

		.tracking-total {
			li {
				&:last-child {
					border-color: $dark-border;
				}
			}
		}

		.tracking-panel {
			.panel-content {
				.icon {
					img {
						filter: invert(0.6);
					}
				}
			}

			ul {
				li {
					background-color: $dark-card-background;

					&:after {
						border-left: 17px solid $dark-card-background;

						[dir="rtl"] & {
							border-left: unset;
							border-right: 17px solid $dark-card-background;
						}
					}

					&:before {
						border-left: 17px solid $dark-body-background;

						[dir="rtl"] & {
							border-left: 17px solid transparent;
							border-right: 17px solid $dark-body-background;
						}
					}

					&.active {
						background-color: #113838;

						&:after {
							border-left: 17px solid #113838;

							[dir="rtl"] & {
								border-right: 17px solid #113838;
								border-left: unset;
							}
						}
					}

					&.cancelled-box {
						background-color: #113838;

						&:after {
							border-left: 17px solid #113838;

							[dir="rtl"] & {
								border-right: 17px solid #113838;
								border-left: unset;
							}
						}
					}
				}
			}
		}

		.media-modal {
			.modal-footer {
				border-color: $dark-border !important;
			}

			.content-section.drop-files-sec {
				background-color: $dark-card-background !important;
				color: $dark-all-font-color;
			}
		}

		.media-library-sec {
			label {
				background-color: $dark-body-background;
				border-color: $dark-border;
			}
		}

		.pending-balance-sec {
			&:after {
				background-image: none;
			}
		}

		.withdraw-btn {
			.plus-icon {
				background-color: $dark-body-background;
			}
		}

		.category-breadcrumb-select {
			background-color: $dark-body-background !important;
			border-color: $dark-border;
		}

		.image-select-list {
			li {
				background-color: $dark-body-background;
				border-color: $dark-border;

				.remove-icon {
					background-color: #323232;
				}
			}
		}

		.theme-tree-box {
			.tree-main-ul {
				>li {
					&:after {
						filter: invert(0.4);
					}
				}

				li {
					color: $dark-small-font-color;

					&:after {
						filter: invert(0.4);
					}

					ul {
						&:after {
							filter: invert(0.4);
						}
					}
				}
			}
		}

		.border-up-class {
			border-color: $dark-border;
		}

		.select-category-box {
			background-color: $dark-body-background !important;
			border-color: $dark-border;
			box-shadow: 0px 6px 10px 4px #1c1c1c;

			.category-content {
				background-color: $dark-card-background;
				border-color: $dark-border;
			}

			.category-listing {
				background-color: $dark-body-background;
				border-color: $dark-border;
			}
		}

		.loader-wrapper,
		.custom-loader-wrapper {
			.loader {
				border: 5px solid $dark-card-background;
				border-bottom-color: var(--theme-color);
			}
		}

		.border-table {
			border: 1px solid $dark-card-border;
		}

		.page-wrapper {
			.page-header {
				.header-wrapper {
					.nav-right {
						>ul {
							>li {
								span {
									color: #efefef;
								}
							}
						}
					}
				}
			}

			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						.skeleton-loader {
							background: linear-gradient(180.02deg, rgba(36, 36, 36, 1) 0.02%, #111a22 90.86%);
							animation: none;

							.sidebar-main {
								.sidebar-links {
									li.sidebar-list {
										a {
											i {
												background: #292930;
											}

											.loader-cls {
												background: linear-gradient(90deg,
														#242424 8%,
														#303030 18%,
														#292930 33%);
												background-size: 800px 104px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.table-product {
			.table {
				tbody {
					tr {
						td {
							.user-name {
								span {
									&:first-child {
										color: #fff;
									}

									&:last-child {
										color: rgba($white, 0.6);
									}
								}
							}
						}
					}
				}
			}
		}

		.title-header {
			color: $gray-light;

			a.btn {
				&:hover {
					background-color: transparent;
				}
			}
		}

		.card {
			.card-header-2 {
				h5 {
					color: $gray-light;
				}
			}
		}

		.customizer-links {
			.rtl-btn {
				background-color: $dark-card-background;
			}
		}

		.table {
			td {
				a {
					span {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.rating {
			li {
				i {
					color: $light-all-font-color !important;

					&.theme-color {
						color: #ffa202 !important;
					}
				}
			}
		}

		.user-round {
			background-color: $dark-body-background;
		}

		.apexcharts-tooltip.light {
			.apexcharts-tooltip-title {
				background: $dark-card-background;
				color: $white;
			}
		}

		.tracker-table {
			tbody {
				tr {
					&:nth-child(even) {
						background-color: $dark-body-background;
					}

					td {
						border-bottom: 1px solid rgb(51, 54, 62) !important;

						&:nth-child(odd) {
							h6 {
								color: $gray-light;
							}

							p {
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}

		.status-circle {
			border-color: $dark-body-background;
		}

		.save-details-box {
			.save-details {
				border-color: $dark-body-background;
				background-color: $dark-body-background;

				.button {
					a {
						background-color: rgba($white, 0.06);
						color: $white;
					}
				}
			}
		}

		.order-left-image {
			.order-image-contain {
				p {
					span {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.progtrckr {
			li {
				&.progtrckr-todo {
					&:before {
						background-color: $dark-card-background;
					}
				}

				h5 {
					color: $dark-all-font-color;
				}
			}
		}

		.title-header-block {
			.card-order-section {
				ul {
					li {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.order-details-table {
			.table-details {
				.table {
					tfoot {
						tr {
							&.table-order {
								h5 {
									color: $dark-all-font-color;
								}
							}

							td {
								h4 {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.order-details-table {
			.order-success {
				h4 {
					color: $dark-all-font-color;
				}

				.delivery-sec {
					h3 {
						color: $dark-all-font-color;
					}
				}
			}

			.table-details {
				.table {
					tbody {
						tr {
							td {
								h5 {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.StepProgress {
			&::before {
				border-left-color: $dark-body-background;
			}
		}

		.StepProgress-item {
			&:nth-child(even) {
				&::before {
					background-color: $dark-card-background;
				}
			}

			&:nth-child(odd) {
				&::before {
					background-color: $dark-card-background;
				}
			}
		}

		.recent-activity {
			.recent-item {
				border-bottom-color: rgba(255, 255, 255, 0.06);
			}
		}

		.brower-states {
			.brower-item {
				border-bottom-color: rgba(255, 255, 255, 0.06);

				.browser-image {
					h5 {
						color: $gray;
					}
				}
			}
		}

		.to-do-list {
			.to-do-item {
				border-bottom-color: rgba(255, 255, 255, 0.06);

				.to-do-list-name {
					strong {
						color: $gray;
					}
				}
			}
		}

		.card {
			background-color: $dark-card-background;
		}

		.page-wrapper {
			.page-header {
				.header-wrapper {
					.search-full input {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.dropzone {
			border-color: $dark-body-background !important;
			background-color: $dark-body-background;
		}

		.country-list {
			li {
				border-color: $dark-border;

				a {
					color: $dark-small-font-color;
				}
			}
		}

		.custom-dt-picker {
			.btn {
				background-color: $dark-body-background !important;
				border-color: $dark-border !important;
			}
		}

		.bootstrap-tagsinput {
			border-color: $dark-body-background !important;
			background-color: $dark-body-background !important;
		}

		.ck {
			&.ck-icon {
				color: $dark-all-font-color;
			}
		}

		.ck.ck-button:not(.ck-disabled),
		a.ck.ck-button:not(.ck-disabled) {
			&:hover {
				background: $dark-card-background;
			}
		}

		.ck.ck-button.ck-on,
		a.ck.ck-button.ck-on {
			background: $dark-body-background;
		}

		.cke_top {
			background-color: $dark-body-background !important;
		}

		.cke_1 {
			border-color: $dark-body-background !important;
		}

		.cke_editable {
			border-color: $dark-body-background !important;
		}

		.ck {
			&.ck-toolbar {
				background-color: $dark-body-background;
				border-color: $dark-border;

				.ck.ck-toolbar__separator {
					background-color: $dark-border;
				}
			}
		}

		.vertical-tabs {
			.tab-content {
				border-color: $dark-border;
			}

			.nav-pills {
				.nav-item {
					background-color: $dark-body-background;
				}
			}
		}

		.theme-form input[type="text"],
		.theme-form input[type="email"],
		.theme-form input[type="search"],
		.theme-form input[type="password"],
		.theme-form input[type="number"],
		.theme-form input[type="tel"],
		.theme-form input[type="date"],
		.theme-form input[type="datetime-local"],
		.theme-form input[type="time"],
		.theme-form input[type="datetime-local"],
		.theme-form input[type="month"],
		.theme-form input[type="week"],
		.theme-form input[type="url"],
		.theme-form input[type="file"],
		.theme-form select,
		textarea {
			background-color: $dark-body-background !important;
		}

		.theme-form {
			.select2-selection__rendered {
				background-color: $dark-body-background !important;
				color: rgba(255, 255, 255, 0.5) !important;
			}

			.ck-content {
				border-color: $dark-border !important;
				background-color: $dark-body-background !important;
			}

			.ck-toolbar {
				border-color: $dark-border !important;
				background-color: $dark-body-background !important;
			}
		}

		.custom-select {
			.select2-container {
				.select2-results__option {
					color: $dark-all-font-color !important;
					background-color: transparent !important;
					border-color: $dark-card-border;
				}

				.select2-results__option--highlighted[aria-selected] {
					background-color: transparent !important;
					border-color: #1d3436;
				}

				.select2-selection {
					border-color: $dark-border !important;
					background-color: $dark-body-background !important;
				}

				.select2-container-dropdown {
					.select2-dropdown {
						background-color: $dark-body-background !important;
						border-color: #1d1e26;
						box-shadow: 0px 6px 10px 4px #1c1c1c;

						.select2-search__field {
							border-color: #363636 !important;
						}
					}
				}
			}

			&.intl-tel-input {
				background: transparent !important;

				.select2-container {
					.select2-selection {
						background: #0d0e11 !important;

						.select2-selection__rendered {
							background: #0d0e11 !important;
						}
					}
				}
			}
		}

		.theme-form input[type="text"],
		.theme-form input[type="email"],
		.theme-form input[type="search"],
		.theme-form input[type="password"],
		.theme-form input[type="number"],
		.theme-form input[type="tel"],
		.theme-form input[type="date"],
		.theme-form input[type="datetime-local"],
		.theme-form input[type="time"],
		.theme-form input[type="datetime-local"],
		.theme-form input[type="month"],
		.theme-form input[type="week"],
		.theme-form input[type="url"],
		.theme-form input[type="file"],
		.theme-form textarea,
		.theme-form select {
			border-color: $dark-border !important;
			color: rgba(255, 255, 255, 0.6);
		}

		.datepicker-dashboard {
			.datepicker--cells-days {
				color: $dark-all-font-color;
			}
		}

		.category-box {
			.content-category {
				background-color: $dark-body-background;

				.top {
					h3 {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.theme-table {
			thead {
				tr {
					th {
						background-color: #292930 !important;
					}
				}
			}

			tbody {
				tr {
					&:nth-child(even) {
						background-color: #18181e;
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part.about {
						.about-sec {
							h4 {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		table {
			border-color: $dark-card-border;
		}

		.datepicker--nav {
			border-color: $dark-card-border;
		}

		.btn-outline-light:hover {
			background-color: $dark-body-background !important;
			border-color: $dark-card-border !important;
			color: $dark-all-font-color !important;
		}

		.btn-outline-light:focus {
			background-color: $dark-body-background !important;
			border-color: $dark-card-border !important;
			color: $dark-all-font-color !important;
		}

		.btn-outline-light:active,
		.btn-outline-light {
			&.active {
				background-color: $dark-body-background !important;
				border-color: $dark-card-border !important;
				color: $dark-all-font-color !important;
			}
		}

		.choose {
			input {
				&[type="file"]::file-selector-button:hover {
					background-color: $dark-card-border;
				}
			}
		}

		input[type="file"]::file-upload-button {
			background-color: $dark-card-border;
			color: $dark-all-font-color;
		}

		input[type="file"]::file-upload-button:hover {
			background-color: $dark-card-border;
			color: $dark-all-font-color;
		}

		input[type="file"]::-webkit-file-upload-button {
			background-color: $dark-card-border;
			color: $dark-all-font-color;
		}

		input[type="file"]::-webkit-file-upload-button:hover {
			background-color: $dark-card-border;
			color: $dark-all-font-color;
		}

		.ecommerce-widget {
			border: 1px solid $dark-card-border !important;
		}

		.btn-light {
			color: $dark-all-font-color !important;
		}

		.form-select {
			color: $dark-all-font-color;
		}

		.add-project {
			.text-inherit {
				color: $dark-all-font-color;
			}
		}

		.knowledgebase-search {
			color: $theme-body-font-color;

			.form-control-plaintext {
				color: $theme-body-font-color;
			}
		}

		.theme-modal {
			.modal-dialog {
				.modal-content {
					.modal-header {
						border-color: $dark-border !important;
					}
				}
			}
		}

		.dashboard-category {
			.category-name {

				h6,
				.h6 {
					color: $dark-small-font-color;
				}
			}

			.category-image {
				&:before {
					background-color: $dark-body-background;
				}
			}
		}

		.pos-detail-card {
			.summary-total {
				background-color: $dark-body-background;

				&:after,
				&:before {
					border-color: $dark-border;
					background-color: $dark-card-background;
				}

				li {
					&.list-total {
						border-color: $dark-border;
					}

					h4 {
						color: $dark-small-font-color !important;
					}
				}
			}
		}

		.product-details {
			.cart-listing {
				li {
					border-color: $dark-border;

					.cart-content {

						h4,
						.h4 {
							color: $dark-small-font-color;
						}

						h5,
						.h5 {
							color: $dark-small-font-color;
						}
					}

					.qty-box {
						.input-group {
							background-color: $dark-body-background;
							border-color: $dark-border;

							input {
								background-color: $dark-body-background;
							}
						}
					}
				}
			}
		}

		.product-section {
			.product-box {
				.counter-box {
					.addtocart_btn {

						.add-button,
						.add_cart {
							background-color: $dark-card-background;
							color: #878787 !important;
							border-color: $dark-border;
						}
					}
				}

				.product-detail {

					h6,
					.h6 {
						color: $dark-small-font-color;
					}
				}
			}
		}

		.product-box {
			background-color: $dark-body-background;
		}

		.modal-header {

			h3,
			.h3 {
				color: $dark-all-font-color;
			}
		}

		.form-control {
			border-color: $dark-border;
			background-color: $dark-body-background;
			color: $dark-all-font-color;
		}

		.prooduct-details-box {
			.media {
				border: 1px solid $dark-card-border;
			}
		}

		.product-price {
			del {
				color: $dark-small-font-color;
			}
		}

		.ProfileCard {
			border: 1px solid $dark-card-border !important;
		}

		.form-control-plaintext {
			color: $dark-all-font-color;
		}

		.form-select {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}

		.box-layout {
			background-color: $dark-body-background;

			.page-wrapper,
			&.page-wrapper {
				.page-header {
					.header-wrapper {
						border: 1px solid $dark-border-color;
					}
				}
			}
		}

		.form-check-input {
			background-color: #444444;
		}

		.switch-state {
			&:before {
				background-color: $dark-card-background;
			}
		}

		.category-box {
			.img-category {
				.like-cls {
					i {
						background-color: $dark-card-background;
					}
				}
			}
		}

		// daterangepicker css start
		.daterangepicker {
			&:before {
				border-bottom-color: $dark-card-background;
			}

			.ranges {
				li {
					background-color: $dark-body-background;
					border-color: $dark-card-background;
					color: $dark-gray;
				}
			}

			.daterangepicker_input {
				.form-control {
					background-color: $dark-body-background;
					border-color: $dark-card-background !important;
				}
			}

			.calendar-time {
				select {
					border-color: $dark-card-background;
					background-color: $dark-body-background;
				}
			}

			&.dropdown-menu {
				background-color: $dark-card-background;
				-webkit-box-shadow: 0 0 14px $dark-body-background;
				box-shadow: 0 0 14px $dark-body-background;
			}

			.calendar-table {
				background-color: $dark-card-background;
				border-color: $dark-card-background;
			}

			&.ltr {
				td {

					&.in-range,
					&.off {
						background-color: $dark-body-background;
						color: $white;
					}
				}
			}
		}

		// daterangepicker css end
		// tooltip css start
		.tooltip {
			&.bs-tooltip-top {
				.tooltip-arrow {
					&:before {
						border-top-color: $dark-card-inbox;
					}
				}
			}

			&.bs-tooltip-bottom {
				.tooltip-arrow {
					&:before {
						border-bottom-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			&.bs-tooltip-start {
				.tooltip-arrow {
					&:before {
						border-left-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			&.bs-tooltip-end {
				.tooltip-arrow {
					&:before {
						border-right-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			.tooltip-inner {
				background-color: $dark-card-inbox;
				color: $white;
			}

			.tooltip-arrow {
				&:before {
					border-top-color: $dark-card-inbox;
				}
			}
		}

		// tooltip css end
		.page-wrapper {
			&.only-body {
				.page-body-wrapper {
					.page-title {
						>.row {
							.col-6 {
								&:first-child {
									h6 {
										color: rgba($dark-all-font-color, 0.5);
									}
								}
							}
						}
					}
				}

				.page-header {
					.header-wrapper {
						.search-full {
							.form-group {
								.Typeahead {
									.u-posRelative {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
				}
			}

			.page-header {
				.header-wrapper {
					.nav-right {
						.profile-dropdown {
							li {
								a {
									i {
										color: $white;
									}
								}
							}
						}

						.flip-card .flip-card-inner {
							.back {
								background-color: $dark-card-background !important;

								.flip-back-content {
									input {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
										color: $dark-all-font-color;
									}
								}

								li {
									&:last-child {
										border-top: 1px solid $dark-card-border;
									}
								}
							}

							.front {
								background-color: $dark-body-background !important;

								svg {
									stroke: $white;
								}
							}
						}

						.onhover-show-div:not(.profile-dropdown) {
							li {
								&:first-child {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
			}
		}

		.customizer-contain {
			color: $dark-body-background;

			.customizer-body {
				.main-layout {
					.box-layout {
						background-color: $white;
					}
				}
			}
		}

		.login-card {
			.btn-showcase .btn {
				background-color: $dark-card-background !important;
				color: #fff;
				border-color: $dark-card-background !important;
			}

			.login-main {
				box-shadow: 0 0 37px rgba($white, 0.05);

				.theme-form {
					input {
						background-color: $dark-card-background !important;
					}

					.or {
						&:before {
							background-color: $dark-card-background;
						}
					}

					.checkbox label::before {
						background-color: $dark-card-background !important;
						border-color: $dark-card-background !important;
					}
				}
			}
		}

		.H_ui {
			~div {
				background-color: $dark-body-background !important;
			}
		}

		.swal-icon--success:after,
		.swal-icon--success:before {
			background: $dark-card-background;
		}

		.swal-icon--success__hide-corners {
			background-color: $dark-card-background;
		}

		.note-editor.note-frame {
			.note-status-output {
				border-top: 1px solid $dark-card-background;
			}

			.note-statusbar {
				border-top: 1px solid $dark-card-background;

				.note-resizebar {
					background-color: $dark-card-background;
				}
			}
		}

		.right-sidebar-modal {
			.qty-box {
				.input-group {
					background-color: $dark-body-background;
					border-color: $dark-border;
				}
			}
		}

		.log-in-section {
			.log-in-box {
				background-color: $dark-card-background;
			}
		}

		.light-font {
			color: rgba(255, 255, 255, 70%);
		}

		.page-link {
			background-color: transparent;
			border: 1px solid $dark-card-border;
		}

		.b-r-light {
			border-right: 1px solid $dark-card-border !important;
		}

		.history-details {
			.media {
				border-top: 1px solid $dark-card-border;
			}
		}

		.modal-header {
			border-bottom: 1px solid $dark-card-border;
		}

		#right-history {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-body-background;

			h6 {
				span {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.ProfileCard {
			&:hover {
				color: $dark-all-font-color;
				background-color: $dark-card-background;
			}
		}

		.translate_wrapper.active .more_lang {
			&:before {
				border-bottom: 7px solid $dark-card-background;
			}

			.lang {
				border-bottom: 1px solid $dark-body-background;
			}
		}

		.toggle-sidebar {
			svg {
				stroke: var(--theme-color);
			}
		}

		.footer {
			background-color: $dark-card-background;
		}

		.page-wrapper {
			.page-body-wrapper {
				background-color: $dark-body-background;
			}

			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}

			&.compact-wrapper {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							background: linear-gradient(180.02deg,
									rgba(245, 245, 245, 0.05) 0.02%,
									rgba(245, 245, 245, 0) 90.86%);
							animation: none;

							@media (max-width: 991px) {
								background: linear-gradient(180.02deg, rgba(36, 36, 36, 1) 0.02%, #111a22 90.86%);
							}

							&.close_icon {
								&:hover {
									background-color: #121a23;
								}
							}

							.sidebar-main {
								.sidebar-links {
									.sidebar-link.active {
										svg {
											stroke: $dark-all-font-color;
										}
									}

									li {
										a {
											svg {
												stroke: $dark-all-font-color;
											}
										}
									}

									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($white, 0.06);

																svg {
																	color: $dark-all-font-color;
																	stroke: $dark-all-font-color;
																}
															}
														}

														.mega-menu-container {
															.mega-box {
																.link-section {
																	.submenu-title {
																		h5 {
																			color: $dark-all-font-color;
																		}
																	}

																	.submenu-content {
																		&.opensubmegamenu {
																			li {
																				a {
																					color: $dark-all-font-color;
																					font-weight: 400;
																				}
																			}
																		}
																	}
																}
															}

															&::after {
																background-color: $light-all-font-color;
															}
														}

														.sidebar-submenu {
															li {
																&:hover {
																	>a {
																		color: var(--theme-color);
																	}
																}

																a {
																	color: $dark-all-font-color;
																}
															}
														}

														a {
															i {
																color: #efefef;
															}
														}
													}
												}
											}
										}
									}

									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}

													&.active {
														color: var(--theme-color);
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color !important;
						}
					}
				}
			}

			&.horizontal-wrapper {
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;
						border-top: 1px solid $dark-card-border;

						.sidebar-main {
							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;
													}

													.sidebar-submenu {
														background: $dark-card-background;

														li {
															a {
																color: $dark-all-font-color;

																&.active,
																&:hover {
																	color: var(--theme-color);
																}
															}

															.nav-sub-childmenu {
																background: $dark-card-background;
															}
														}
													}

													.mega-menu-container {
														background: $dark-card-background;

														.mega-box {
															.link-section {
																.submenu-content {
																	li {
																		a {
																			color: $dark-all-font-color;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}

							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}

							.sidebar-main {
								.sidebar-links {
									li {
										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}

										a {
											span {
												color: $dark-all-font-color;
											}

											svg {
												stroke: $dark-all-font-color;
											}
										}

										.sidebar-submenu,
										.mega-menu-container {
											background-color: $dark-card-background;

											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}

									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);

																span {
																	color: var(--theme-color);
																}

																svg {
																	color: var(--theme-color);
																	stroke: var(--theme-color);
																}
															}
														}

														.mega-menu-container {
															.mega-box {
																.link-section {
																	.submenu-title {
																		h5 {
																			color: $dark-all-font-color;
																		}
																	}

																	.submenu-content {
																		&.opensubmegamenu {
																			li {
																				a {
																					color: $dark-all-font-color;
																					font-weight: 400;
																				}
																			}
																		}
																	}
																}
															}

															&::after {
																background-color: $light-all-font-color;
															}
														}

														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}

									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}

													&.active {
														color: var(--theme-color);
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}

			&.material-type {
				.page-header {
					.header-wrapper {
						border-radius: 15px 15px 0 0;
					}
				}
			}

			// material icon css
			&.material-icon {
				.page-header {
					.header-wrapper {
						background-color: $transparent-color;

						.nav-right {
							.language-nav {
								.more_lang {
									color: $white;
								}
							}

							.onhover-show-div {
								color: $white;
							}

							.flip-card {
								.flip-card-inner {
									.back {
										.flip-back-content {
											input {
												border: 1px solid $dark-border-color;
												background: $dark-body-background;
												color: $white;
											}
										}
									}
								}
							}
						}
					}

					.header-logo-wrapper {
						background-color: $transparent-color;
					}
				}

				.page-body-wrapper {
					.page-body {
						background-color: $transparent-color;
					}
				}
			}

			&.advance-layout {
				background-color: $dark-body-background;

				.page-header {
					.header-wrapper {
						background: $transparent-color;

						.header-logo-wrapper {
							background: $transparent-color;
						}
					}
				}

				.page-body-wrapper {
					background: $transparent-color;

					.page-body {
						background: $transparent-color;
					}
				}
			}

			.page-header {
				.header-wrapper {
					.search-full {
						background-color: transparent;

						@media (max-width: 767px) {
							background-color: $dark-card-background;
						}

						input {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		::-webkit-calendar-picker-indicator {
			filter: invert(1);
		}

		.left-header {
			.mega-menu-container {
				background-color: $dark-card-background;
				border-top: 1px solid $dark-card-background;

				.mega-box {
					+.mega-box {
						border-left: 1px solid $dark-card-border;
					}
				}
			}

			.mega-menu div>div a {
				color: rgba($white, 0.7);
			}

			.level-menu {
				.header-level-menu {
					background-color: $dark-card-background;

					>li {
						.header-level-sub-menu {
							background-color: $dark-card-background;
						}

						a {
							color: rgba($white, 0.7);
						}

						svg {
							stroke: rgba($white, 0.7);
						}
					}
				}
			}
		}

		.kanban-board-header {
			background-color: $dark-body-background !important;
			border-bottom: 1px solid $dark-card-background !important;
		}

		.kanban-board .kanban-drag {
			background: $dark-body-background !important;
		}

		.kanban-container {
			.kanban-item {
				.kanban-box {
					border: 1px solid rgba($primary-color, 0.15);
					background-color: $dark-body-background;
				}
			}
		}

		.project-box {
			border: 1px solid rgba($primary-color, 0.15);
			background-color: $dark-body-background;
		}

		.file-content {
			.form-inline {
				border: 1px solid $dark-body-background;
			}

			.files {
				.file-box {
					border: 1px solid rgba($primary-color, 0.15);
					background-color: $dark-body-background;

					.file-top {
						background-color: $dark-card-background;
						border: 1px solid rgba($primary-color, 0.15);
					}
				}
			}

			.folder .folder-box {
				border: 1px solid rgba($primary-color, 0.15);
				background-color: $dark-body-background;
			}
		}

		.file-sidebar {
			.pricing-plan {
				border: 1px solid rgba($primary-color, 0.15) !important;
			}

			.btn-light {
				color: $dark-all-font-color;
				background-color: $dark-body-background !important;
				border: 1px solid rgba($primary-color, 0.15) !important;
			}
		}

		#lnb {
			border-right: 1px solid $dark-card-border;
		}

		.lnb-new-schedule,
		.lnb-calendars>div {
			border-bottom: 1px solid $dark-card-border;
		}

		#menu .sidebar-list .btn-default {
			color: rgba($dark-all-font-color, 0.2);
		}

		.tui-full-calendar-timegrid-hour {
			background-color: $dark-card-background;
			color: $white !important;
		}

		.tui-full-calendar-timegrid-gridline {
			border-bottom: 1px solid $dark-card-border !important;
		}

		.tui-full-calendar-time-date,
		.tui-full-calendar-weekday-grid-line,
		.tui-full-calendar-left,
		.tui-full-calendar-timegrid-timezone {
			border-right-color: $dark-card-border !important;
		}

		.tui-full-calendar-popup {
			color: $theme-body-font-color;
		}

		#menu {
			.btn-default {
				color: $dark-all-font-color;

				&:hover {
					color: $theme-body-font-color;
				}
			}

			.dropdown-menu {
				color: $dark-all-font-color;
			}
		}

		.tui-full-calendar-dayname-container,
		.tui-full-calendar-splitter {
			border-top-color: $dark-card-border !important;
			border-bottom-color: $dark-card-border !important;
		}

		span.tui-full-calendar-dayname-date-area {
			color: $white !important;
		}

		.tui-full-calendar-layout {
			background-color: $dark-card-background !important;
		}

		.chat-default {
			.card-body {
				&.chat-box {
					.right-side-chat {
						.media-body {
							.message-main {
								span {
									background-color: var(--theme-color);
									color: $dark-all-font-color;

									&.loader-span {
										.dot {
											background: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// index page css end
		.blockquote {
			border-left: 4px solid $dark-card-border;
		}

		.figure {
			&.text-end {
				blockquote {
					border-right-color: $dark-card-border;
				}
			}
		}

		.contacts-tabs {
			.nav-pills {
				border-right: 1px solid $dark-card-border;

				.nav-link {
					+.nav-link {
						border-top: 1px solid $dark-card-border;
					}
				}
			}
		}

		.list-persons {
			.profile-mail {
				.media {
					.media-body {
						ul {
							li {
								+li {
									border-left: 2px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}

		.product-wrapper {
			&.sidebaron {
				.product-sidebar {
					.filter-section {
						.card {
							.left-filter {
								background-color: $dark-card-background;
							}
						}
					}
				}
			}
		}

		.apexcharts-gridline {
			stroke: $dark-border-color;
		}

		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}

		// peity chart css
		.small-donut,
		.donut-peity2,
		.pie-peity {

			~svg,
			svg {
				path {
					&:nth-child(even) {
						fill: $dark-body-background;
					}
				}
			}
		}

		// index page css start
		.earning-card {
			&.card {
				.card-body {
					.left_side_earning {
						p {
							color: $dark-all-font-color;
						}
					}

					.earning-content {
						border-right: 1px solid $dark-card-border;
					}

					.inner-top-left,
					.inner-top-right {
						ul {
							li {
								color: $dark-all-font-color;

								&.active {
									color: var(--theme-color);
								}
							}
						}
					}

					.media {
						.media-body {
							p {
								color: $dark-all-font-color;
							}
						}
					}

					.border-top {
						border-top: 1px solid $dark-card-border !important;
					}
				}
			}
		}

		.chart_data_left,
		.chart_data_right {
			.card-body {
				.chart-main {
					.media {
						border-right: 1px solid $dark-card-border;

						&.border-none {
							border-right: none;
						}
					}
				}

				.media {
					.right-chart-content {
						span {
							color: $dark-all-font-color !important;
						}
					}
				}
			}
		}

		.chart_data_right {
			.card-body {
				.knob-block {
					input {
						color: $dark-all-font-color !important;
					}
				}
			}
		}

		.third-news-update,
		.news,
		.appointment-sec {
			.news-update {
				span {
					color: $dark-all-font-color;
				}

				h6 {
					margin-bottom: 2px;
					line-height: 24px;
				}

				border-bottom: 1px solid $dark-card-border;
			}

			.appointment {
				.radar-chart {
					.apexcharts-datalabel {
						fill: $dark-all-font-color;
					}
				}
			}
		}

		.chat-default {
			.card-body {
				&.chat-box {
					.input-group {
						.form-control {
							background-color: $dark-card-background !important;
							border: none !important;
							border-left: 1px solid var(--theme-color) !important;

							&::placeholder {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.appointment-table {
			table {
				tr {
					td {
						&.img-content-box {
							.font-roboto {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.notification {
			.card {
				.media {
					.media-body {
						p {
							span {
								color: $dark-all-font-color;
							}
						}

						span {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.datepicker {
			background-color: $dark-card-inbox;

			.datepicker--cell.-current- {
				color: $primary-color;
			}
		}

		.cal-date-widget {
			.datepicker {
				background-color: $dark-card-inbox;

				.datepicker--cell-day {
					color: $white;
				}

				.datepicker--cell.-current- {
					color: $primary-color;
				}
			}
		}

		.default-datepicker {
			.datepicker-inline {
				.datepicker {
					background: $dark-card-background;

					.datepicker--nav-title {
						color: $dark-all-font-color;

						i {
							color: $dark-all-font-color;
						}
					}

					.datepicker--content {
						.datepicker--days {

							.datepicker--days-names,
							.datepicker--cells {

								.datepicker--day-name,
								.datepicker--cell-day {
									color: $dark-all-font-color;

									&.-selected- {
										color: $white;
									}
								}
							}
						}
					}
				}
			}
		}

		// index page css end
		// ecommerce dashboard css start
		.dash-chart {
			.media {
				.media-body {
					p {
						color: $dark-all-font-color;

						.font-roboto {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.our-product {
			table {
				tr {
					td {
						p {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.activity-timeline {
			.media {
				.activity-line {
					background-color: $light-all-font-color;
				}

				.media-body {
					p {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.total-users {
			.goal-status {
				ul {
					&:last-child {
						li {
							border-top-color: $dark-card-border;
						}
					}
				}
			}
		}

		.best-seller-table {
			table {
				tbody {
					tr {
						td {
							.label {
								color: $dark-all-font-color;
							}

							p {
								color: $light-all-font-color;
							}
						}

						&:hover {
							td {
								color: var(--theme-color) !important;

								span {
									color: $dark-all-font-color !important;
								}

								.label,
								p {
									color: var(--theme-color) !important;
								}
							}
						}
					}
				}
			}
		}

		[class*="activity-dot-"] {
			background-color: $dark-card-background;
		}

		// ecommerce dashboard css end
		.page-header {
			.header-wrapper {
				.nav-right {

					.chat-dropdown,
					.profile-dropdown {
						li {
							&:last-child {
								border-top: none !important;
							}
						}
					}
				}
			}
		}

		$alert-name: primary,
		secondary,
		success,
		danger,
		warning,
		info,
		light,
		dark;
		$alert-color: $primary-color,
		$secondary-color,
		$success-color,
		$danger-color,
		$warning-color,
		$info-color,
		$light-color,
		$dark-color;

		@each $var in $alert-name {
			$i: index($alert-name, $var);

			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;

				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}

				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}

			.alert-#{$var}.inverse {
				background-color: $transparent-color !important;

				.btn-close {
					filter: brightness(1) invert(1);
				}
			}

			.alert-#{$var}.outline,
			.alert-#{$var}.outline-2x {
				.btn-close {
					filter: brightness(1) invert(1);
				}
			}

			.dismiss-text {
				.alert-dismissible {
					.btn-close {
						filter: unset;

						.bg-light {
							color: $theme-body-font-color !important;
						}
					}
				}
			}
		}

		.options {
			>div {
				border: 1px solid $dark-card-border;
			}
		}

		.was-validated {
			.custom-control-input {
				&:invalid {
					~.custom-control-label {
						&::before {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		.pricing-simple {
			box-shadow: 1px 1px 2px 1px $dark-body-background !important;
		}

		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}

		.search-page {
			ul {
				&.search-info {
					li {
						+li {
							border-left: 1px solid $dark-body-background;
						}
					}
				}
			}
		}

		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid $dark-border-color;
				}
			}
		}

		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid $dark-body-background;
								}
							}
						}
					}
				}
			}
		}

		.calendar-wrap {
			.fc-unthemed {

				td,
				th {
					border-color: $dark-body-background;
				}
			}
		}

		.mega-inline,
		.mega-horizontal,
		.mega-vertical {
			.card {
				box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
			}

			&.plain-style,
			&.border-style,
			&.offer-style {
				.card {
					box-shadow: none !important;
				}
			}

			&.border-style {
				.card {
					border: 1px solid $dark-card-border !important;
				}
			}

			&.offer-style {
				.card {
					border: 1px dashed $dark-card-border !important;
				}
			}
		}

		.setting-dot {
			.setting-bg {
				background-color: var(--theme-secondary);
			}
		}

		.bookmark.pull-right {
			border: none;
		}

		// popover css start
		.popover {
			&.bs-popover-bottom {
				.popover-arrow {
					&:after {
						border-bottom-color: $dark-body-background;
						border-right-color: $transparent-color;
					}
				}
			}

			&.bs-popover-top {
				.popover-arrow {
					&:after {
						border-right-color: $transparent-color;
						border-top-color: $dark-body-background;
					}
				}
			}

			&.bs-popover-start {
				.popover-arrow {
					&:after {
						border-left-color: $dark-body-background;
					}
				}
			}

			background-color: $dark-body-background;

			.popover-header {
				background-color: $dark-card-background;
			}

			.popover-arrow {
				&:after {
					border-right-color: $dark-body-background;
				}
			}

			.popover-body {
				color: rgba(255, 255, 255, 0.6);
			}

			code {
				background-color: unset;
			}
		}

		// popover css end
		// apex chart css start
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
			}
		}

		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}

				.apexcharts-subtitle-text {
					fill: $white;
				}

				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}

				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}

				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}

				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}

					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}

		// apex chart css end
		.Typeahead-menu {
			background-color: $dark-card-background;
		}

		.ecommerce-widget {
			border: 1px solid $dark-border-color;
		}

		.bookmark {
			ul {
				li {
					.search-form {
						.form-control-search {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}

							&:before {
								background: $dark-border-color;
							}
						}
					}
				}
			}
		}

		// cart css start
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: $dark-card-border !important;
					}
				}
			}
		}

		// cart css end
		// checkout css start
		.checkout {
			.checkout-details {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				padding: 40px;

				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid $dark-card-background;
						}
					}
				}
			}
		}

		// checkout css end
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid $dark-border-color;
			}

			.qty {
				li {
					color: #bfc2c6;

					span {
						color: #bfc2c6;
					}
				}

				border-bottom: 1px solid $dark-border-color;
			}

			.sub-total {
				li {
					color: #bfc2c6;
				}
			}

			.total {
				li {
					color: #bfc2c6;
				}
			}
		}

		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid $dark-card-background;
				}
			}
		}

		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid $dark-border-color;
				}
			}
		}

		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}

		.card {
			.card-header-1 {
				h5 {
					color: $dark-all-font-color;
				}
			}
		}

		.order-details-table {
			.order-success {
				background-color: $dark-body-background;
			}
		}

		.nav-pills {
			.nav-item {
				.nav-link {
					color: $gray;
				}
			}
		}

		.page-wrapper {
			background-color: #1d1e26;

			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-wrapper {
						.sidebar-main {
							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.sidebar-link {
														&.active {
															.according-menu {
																i {
																	color: $gray;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.all-hotel-table,
		.all-restaurant-table,
		.user-table {
			tbody {
				tr {
					td {
						&:nth-child(2) {
							a {
								span {
									&:first-child {
										color: $gray !important;
									}
								}
							}
						}
					}
				}
			}
		}

		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: $dark-small-font-color;
							}

							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}

		.radio_animated,
		.checkbox_animated {
			&:after {
				background-color: $dark-card-background;
				border-color: $dark-border;
			}
		}

		.slider-product {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
		}

		.square-product-setting {
			.icon-grid {
				background-color: $dark-card-background;

				svg {
					color: $dark-all-font-color;
				}
			}
		}

		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}

				tbody {
					tr {
						td {
							border-bottom: 1px solid $dark-border-color;
						}

						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}

		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
			}
		}

		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: $dark-card-background;
						color: $white;

						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}

		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}

		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}

		.loader-wrapper {
			background-color: $dark-body-background;

			.loader {
				background-color: $dark-body-background !important;
			}
		}

		.page-wrapper {

			/* Main Header start */
			.page-header {
				.translate_wrapper {
					&.active {
						.more_lang {
							background-color: $dark-body-background;
						}

						.lang {
							background-color: $dark-card-background;
						}
					}
				}

				form {
					background-color: $dark-body-background;
				}

				.header-wrapper {
					background-color: $dark-card-background;

					li {
						i {
							color: #9b9b9b !important;
						}
					}

					.nav-right {
						>ul {
							>li {
								svg {
									stroke: $white;
								}
							}
						}

						&.right-header {
							>ul {
								>li {
									.profile-media {
										.media-body {
											p {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}

						.nav-menus {
							li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}

								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 14px $dark-body-background;

									li {
										border-color: $dark-card-background;
									}

									p {
										color: $dark-all-font-color;
									}

									&:before {
										border-bottom: 7px solid $dark-body-background;
									}

									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}

								.notification-dropdown.onhover-show-div {
									li {
										span {
											color: $dark-small-font-color;
										}
									}

									.bg-light {
										background-color: #282e38 !important;
										color: $dark-all-font-color !important;
									}
								}
							}
						}

						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;

									svg {

										path,
										line,
										circle {
											color: $dark-all-font-color !important;
										}
									}

									&:hover {
										color: var(--theme-color);

										svg {

											line,
											path,
											circle {
												color: var(--theme-color) !important;
											}
										}
									}
								}
							}
						}

						>ul {
							>li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}

								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;

									&:before {
										border-bottom: 7px solid $dark-body-background;
									}

									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}

								.notification-dropdown.onhover-show-div {
									li {
										border-color: $dark-card-background;

										span {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}

						.chat-dropdown {
							li {
								color: $white;
								border-color: $dark-card-background;
							}
						}
					}

					.left-header {
						ul {
							li {
								.search-form {
									input {
										&::placeholder {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}

				.header-logo-wrapper {
					background-color: $dark-card-background;

					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}

							.image-light {
								display: block;
							}
						}
					}
				}
			}

			/* Main Header ends */
			.page-body-wrapper {

				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
					}

					.header-small {
						color: $dark-small-font-color;
					}

					.statistics {
						p {
							color: $dark-small-font-color;
						}
					}

					.feather-main,
					.professor-table {

						.media-body,
						.professor-block {
							p {
								color: $dark-small-font-color;
							}
						}
					}

					.logs-element {
						span {
							+span {
								color: $dark-small-font-color;
							}
						}
					}

					.progress-block {
						.progress-title {
							span {
								+span {
									color: $dark-small-font-color;
								}
							}
						}
					}

					.ecommerce-icons {
						div {
							span {
								color: $dark-small-font-color;
							}
						}
					}

					.new-users,
					.recent-notification {
						.media {
							.media-body {
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}

					.progress-media {
						.media {
							.media-body {
								span {
									color: $dark-small-font-color;
								}
							}
						}

						.progress-change {
							span {
								color: $dark-small-font-color;
							}
						}
					}

					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid $dark-card-border;
									}

									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}

					.notifiaction-media {
						.media {
							.media-body {
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}

					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid $dark-border-color;

							&:last-child {
								border-bottom: none;
							}
						}
					}

					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}

					.number-widgets {
						.media {
							.media-body {
								h6 {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.activity {
						.media {
							.gradient-round {

								&.gradient-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: $dark-border-color;
									}
								}
							}

							.media-body {
								h6 {
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
					}

					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
				}

				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}

				.page-title {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}

					.breadcrumb {
						li {
							color: $dark-all-font-color;
						}

						.breadcrumb-item {
							a {
								svg {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.breadcrumb-item {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}

				.page-body {
					background-color: $dark-body-background;

					.default-according {
						.card:not(.email-body) {
							border: none;
						}
					}

					.card:not(.email-body) {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);

						.chart-block {
							#bar-chart2 {
								svg {
									>rect {
										fill: $dark-card-background;
									}

									>g {
										text {
											fill: $dark-all-font-color;
										}
									}
								}
							}

							.word-tree {
								svg {
									>g {
										>rect {
											fill: $dark-card-background;
										}

										>text {
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}

						.card-header {
							background-color: $transparent-color;
							border-bottom: 1px solid $dark-card-border;

							>span {
								color: $dark-all-font-color;
							}

							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: $dark-all-font-color;
							}

							.card-header-right {
								background-color: $dark-card-background;

								i {
									color: $dark-all-font-color;

									&.fa-cog {
										color: var(--theme-color);
									}
								}
							}
						}

						.alert-dark {
							color: $dark-small-font-color;

							a {
								color: $dark-small-font-color;
							}
						}

						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: $dark-all-font-color;
						}

						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}

						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
							}
						}

						.line {
							color: $dark-all-font-color;
						}

						thead,
						tbody,
						tfoot,
						tr,
						td,
						th {
							border-color: $dark-card-border;
						}

						.table {

							th,
							td {
								color: $dark-all-font-color;
							}

							td {
								&:last-child {
									&.lost {
										color: var(--theme-color);
									}

									&.success {
										color: #42ba96;
									}
								}
							}

							thead {
								.border-bottom-primary {
									th {
										border-bottom: 1px solid var(--theme-color);
									}
								}
							}

							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {

								th,
								td {
									color: $dark-card-background;
								}
							}

							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}

							.bg-light {
								color: $black;
							}

							.thead-light {
								th {
									color: $black;
								}
							}

							tbody {
								.border-bottom-primary {

									th,
									td {
										border-bottom: 1px solid var(--theme-color);
									}
								}
							}
						}

						.table[class*="bg-"] {

							th,
							td {
								color: $white;
							}
						}

						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										--bs-table-accent-bg: rgb(29, 30, 38);

										&:hover {

											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}

						.table-styling {

							thead,
							tbody {

								th,
								td {
									color: $white;
								}
							}
						}

						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}

						.switch {
							.switch-state {
								background-color: #313138;
							}

							input {
								&:checked {
									+.switch-state {
										background-color: var(--theme-color);
									}
								}
							}
						}

						.bg-white {
							background-color: $white !important;
						}

						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}

						.ct-grid {
							stroke: $dark-border-color;
						}

						.ct-label {
							color: $dark-small-font-color;
						}

						hr {
							border-top: 1px solid $dark-border-color;
						}

						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}

						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}

						.contact-form {
							.theme-form {
								border: 1px solid $dark-card-border;

								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-card-border;
									color: $dark-all-font-color;
								}
							}
						}

						.btn-outline-light,
						.btn-outline-dark,
						.btn-outline-light-2x {
							color: $white !important;
							border: 1px solid $dark-card-border;
						}

						.btn-outline-light {
							&:hover {
								color: $dark-all-font-color !important;
							}
						}

						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}

						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}

							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}

						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: $dark-body-background;
											}
										}
									}
								}

								rect {
									&:nth-child(6) {
										fill: $dark-body-background;
									}
								}
							}

							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: $dark-card-background;
								}
							}

							svg {
								>rect {
									fill: $dark-card-background;
								}

								>g {
									>g {
										>g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}

									>text {
										fill: $dark-all-font-color;
									}
								}
							}
						}

						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}

						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;

									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}

						.b-r-light {
							border-right: 1px solid $dark-card-border !important;
						}

						.chart-container {

							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}

								.ct-grid {
									stroke: $white;
								}
							}

							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}

						.status-details {
							h4 {
								color: $dark-all-font-color;

								span {
									color: $dark-all-font-color;
								}
							}

							span {
								color: $dark-small-font-color;
							}
						}

						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}

						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-card-border;
						}

						.dataTables_wrapper {
							button {
								color: $black;
							}

							&.no-footer {
								.dataTables_scrollBody {
									border-bottom: 1px solid $dark-body-background;
								}
							}

							.btn-danger,
							.btn-success,
							.btn-primary {
								color: $white;
							}

							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}

							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}

							.dataTables_paginate {
								border: 1px solid $dark-border-color;
							}

							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}

							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;

										&:hover {
											>.sorting_1 {
												background-color: $dark-datatable-sorting;
											}
										}
									}

									tr.odd {
										background-color: $dark-datatable-odd;

										>.sorting_1 {
											background-color: $dark-datatable-sorting;
										}
									}

									tr.even {
										>.sorting_1 {
											background-color: $dark-datatable-sorting-even;
										}
									}
								}
							}

							table.dataTable {
								border: 1px solid $dark-border-color;

								thead {

									th,
									td {
										border-bottom: 2px solid $dark-border-color;
									}
								}

								input,
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}

								tbody {
									tr {
										background-color: $dark-card-background;
									}

									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}

							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											--bs-table-accent-bg: $dark-body-background;
										}
									}
								}
							}

							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;

									&.current,
									&:active {
										border-color: var(--theme-color);
									}
								}

								.paginate_button.disabled {
									color: $dark-small-font-color !important;

									&:hover,
									:active {
										color: $dark-small-font-color !important;
									}
								}
							}

							table.dataTable.row-border,
							table.dataTable.display {
								tbody {

									th,
									td {
										border-top: 1px solid $dark-border-color;
									}
								}
							}

							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											>.sorting_1 {
												background-color: $dark-even-hover-sorting;
											}
										}
									}
								}
							}

							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: $dark-datatable-sorting;
										}
									}
								}
							}

							table.dataTable.cell-border {

								th,
								td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;

									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}

							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {

										>.sorting_1,
										>.sorting_2,
										>.sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}

						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}

						.page-item {
							&:hover {
								.page-link {
									background-color: $dark-body-background;
								}
							}
						}

						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}

							.total-num {
								color: $dark-all-font-color;

								span {
									color: $dark-all-font-color;
								}
							}

							span {
								color: $dark-small-font-color;
							}
						}

						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}

						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {

											span,
											p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}

							h6 {
								color: $dark-small-font-color;
							}
						}

						.border-tab.nav-tabs {
							border-bottom: 1px solid $dark-border-color;

							.nav-item {
								.nav-link {
									color: $dark-all-font-color;
								}
							}

							.nav-link {

								&.active,
								&:focus,
								&:hover {
									color: var(--theme-color);
								}
							}
						}

						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: $dark-border-color;

									&.br-active,
									&.br-selected {
										background-color: var(--theme-color);
									}
								}
							}
						}

						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background;
									color: $dark-all-font-color;

									&.br-active,
									&.br-selected {
										border: 2px solid var(--theme-color);
										color: var(--theme-color);
									}
								}
							}
						}

						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {

									&.br-selected,
									&.br-active {
										&:after {
											color: var(--theme-color);
										}
									}
								}
							}
						}

						.scroll-demo {
							border: 1px solid $dark-card-border;
						}

						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								background-color: $dark-body-background;
							}

							.form-group {
								&:before {
									background: $dark-card-background;
								}
							}
						}

						.cd-timeline-content {
							background-color: $dark-border-color;

							&::before {
								border-left: 7px solid $dark-border-color;
							}
						}

						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: $dark-border-color;
										border-left: $transparent-color;
									}
								}
							}
						}

						.breadcrumb {
							&.bg-white {
								background-color: $dark-card-background !important;
							}
						}

						.user-status {
							table {

								td,
								th {
									border-top: none !important;
								}
							}
						}

						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}

					.form-builder-header-1,
					.form-builder-2-header {
						background-color: $dark-body-background;
					}

					.form-builder {
						.nav-primary {
							.show {
								>.nav-link {
									color: $white;
								}
							}

							.nav-link {
								color: $dark-all-font-color;

								&.active {
									color: $white;
								}
							}

							.nav-pills.nav-primary {
								.show {
									>.nav-link {
										color: $white;
									}
								}

								.nav-link {
									color: $dark-all-font-color;

									&.active {
										color: $white;
									}
								}
							}
						}

						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}

						.help-block {
							color: $dark-small-font-color;
						}
					}

					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}

					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}

					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}

					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}

						a {
							>div {
								border: 1px solid $dark-border-color;
							}
						}

						p {
							color: $dark-small-font-color;
						}
					}

					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}

					.jsgrid-header-row,
					.jsgrid-filter-row {

						>.jsgrid-header-cell,
						>.jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;

							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;

								&:focus {
									outline: none;
								}
							}
						}

						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}

					.jsgrid-row {
						>.jsgrid-cell {
							background-color: $dark-card-background;
						}
					}

					.jsgrid-alt-row {
						>.jsgrid-cell {
							background-color: $dark-body-background;
						}
					}

					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}

					.jsgrid-selected-row {
						>.jsgrid-cell {
							background-color: $dark-datatable-sorting;
						}
					}

					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}

							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}

					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}

					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;

								&:hover {
									color: var(--theme-color);
								}
							}
						}

						.separator {
							border-bottom: 1px solid $dark-card-border;
						}
					}

					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}

					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--theme-color) !important;
								}
							}
						}
					}

					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}

								&:hover {
									color: var(--theme-color);

									a {
										color: var(--theme-color);
									}
								}
							}
						}
					}

					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-body-background;
							box-shadow: 0 0 14px 0 $dark-body-background;

							.navs-icon {
								li {
									p {
										color: $white;
									}

									a {
										svg {

											path,
											line,
											polyline,
											polygon,
											rect {
												color: $dark-all-font-color !important;
											}
										}

										&:hover {
											svg {

												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--theme-color) !important;
												}
											}
										}
									}
								}
							}
						}
					}

					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-body-background;
								}
							}
						}
					}

					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}

							.card-body {
								color: $dark-small-font-color;
							}

							.card-body {
								border: 1px solid $dark-card-border;
								border-top: none;
							}
						}
					}

					.border {
						border: 1px solid $dark-card-border !important;
					}

					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}

						.blog-details,
						.blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;

									&:first-child {
										border-right: 1px solid $dark-border-color;
									}

									&:last-child {
										border-right: none;
									}
								}
							}

							p {
								color: $dark-all-font-color;
							}

							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;

								p {
									color: $dark-small-font-color;
								}
							}
						}
					}

					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}

							img {
								border: 1px solid $dark-border-color;
							}
						}

						p {
							color: $dark-small-font-color;
						}

						.comment-social {
							li {
								color: $dark-small-font-color;

								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}

						hr {
							border-top: 1px solid $dark-border-color;
						}
					}

					ul.the-icons {
						li {
							border: 1px dotted $dark-card-border;
							color: $sidebar-submenu-font-color;
							display: inline-block;
							padding: 10px;

							&:hover {
								background: $black;
								box-shadow: 0 0 3px $dark-card-background;
							}

							em {
								display: none;
							}
						}
					}

					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}

						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}

						pre.well {
							background-color: $dark-card-background !important;
						}
					}

					.crm-activity {
						>li {
							+li {
								border-top: 1px solid $dark-border-color;
							}

							h6 {
								color: $dark-all-font-color;
							}
						}

						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}

					#donut-color-chart-morris,
					#donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}

					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}

						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}

						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}

						.card-footer {
							>div {
								h6 {
									color: $dark-small-font-color;
								}

								+div {
									border-left: 1px solid $dark-card-inbox;
								}
							}
						}
					}

					.form-control {
						background-color: $dark-body-background;
						color: rgba(255, 255, 255, 0.5);
						border-color: $dark-border;
					}

					.checkbox,
					.radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}

					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;

								a {
									color: $dark-all-font-color;
									background-color: $transparent-color;
									border-color: $dark-card-background;

									&:hover {
										background-color: $dark-card-background;
									}
								}

								.dropdown-header {
									color: $dark-all-font-color;
								}

								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}

					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;

								a {
									color: $dark-all-font-color;

									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}

					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}

							span {
								color: $dark-small-font-color;
							}
						}
					}

					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;

							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}

					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-card-border;

							h4 {
								color: $dark-all-font-color;

								span {
									color: $dark-all-font-color;
								}
							}

							span {
								color: $dark-small-font-color;
							}
						}
					}

					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}

							.main-menu {
								>li {
									a {
										color: $dark-all-font-color;

										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}

							ul {
								li {
									a {
										>.title {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}

						.email-right-aside {
							.email-body {
								.row {
									.col-xl-4 {
										border-right: 1px solid rgba(0, 0, 0, 0.1);
									}
								}

								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}

						.media-body {
							h6 {
								color: $dark-all-font-color;

								small {
									color: $dark-small-font-color;

									span {
										color: $dark-small-font-color;
									}
								}
							}

							p {
								color: $dark-small-font-color;
							}
						}

						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}

						p {
							color: $dark-small-font-color;
						}

						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}

					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-card-background;
						box-shadow: 0px 6px 10px 4px $dark-card-background;

						a {
							color: rgba(255, 255, 255, 0.9);
							background: $transparent-color;
							border-top-color: $dark-card-background;

							&:hover {
								background-color: $dark-card-background;
							}
						}
					}

					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}

							&:hover {
								background-color: $dark-body-background;
							}
						}
					}

					.widget-joins {
						.media {
							.media-body {
								>span {
									color: $dark-small-font-color;
								}
							}

							.details {
								border-left: 1px solid $dark-card-border;
							}
						}

						&:before,
						&:after {
							background-color: $dark-card-border;
						}
					}

					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}

					.social-widget-card {

						h5,
						h4 {
							color: $dark-all-font-color;
						}

						span {
							color: $dark-small-font-color;
						}
					}

					.b-b-light {
						border-bottom: 1px solid $dark-card-border !important;
					}

					.b-r-dark {
						border-right: 1px solid $white !important;
					}

					.testimonial {
						i {
							color: $dark-border-color;
						}

						p {
							color: $dark-all-font-color;
						}

						h5 {
							color: $dark-all-font-color;
						}

						span {
							color: $dark-small-font-color;
						}
					}

					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-card-border;
						}
					}

					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}

					.page-builder {
						.ge-html-output {
							background-color: $dark-body-background;
							color: $dark-small-font-color;
							border: 1px solid $dark-card-border;
						}

						.btn-grid {
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}

						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}

						.btn-code,
						.btn-screen {
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}

						.ge-canvas.ge-editing {
							.row {
								background-color: $dark-body-background;
							}

							.column {
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}

							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}

									.btn-group {
										a {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}

						.ge-content-type-ckeditor {
							color: $dark-all-font-color;
						}
					}

					.search-page {
						.info-block {
							border: 1px solid $dark-border-color;
						}
					}

					.card-absolute {

						.bg-primary,
						.bg-secondary {
							h5 {
								color: $white;
							}
						}
					}

					.timeline-small {
						.media {
							.timeline-round {

								&.timeline-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: $dark-card-border;
									}
								}
							}
						}
					}

					/* body end*/
				}

				.footer {
					p {
						color: $dark-all-font-color;
					}

					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}

				.custom-select,
				.custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}

				.footer-fix {
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}

			.note {
				textarea {
					color: $dark-all-font-color;
				}
			}

			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;

				h2 {
					background-color: $dark-card-background;
				}
			}

			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}

				.chat-menu {
					border-left: 1px solid $dark-border-color;

					.nav-tabs {
						border-bottom: 1px solid $dark-border-color;

						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: $dark-card-background;
								box-shadow: 1px 1px 3px 1px $dark-body-background;
							}

							.avatar {
								img {
									border: 5px solid $dark-border-color;
								}
							}
						}

						.social-media {
							a {
								color: $dark-small-font-color;
							}
						}

						.follow {
							span {
								color: $dark-small-font-color;
							}

							.follow-num {
								color: $dark-all-font-color;
							}
						}
					}
				}

				.status {
					color: $dark-small-font-color;

					p {
						color: $dark-all-font-color !important;
					}
				}

				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-border-color;

							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}

							img {
								box-shadow: 1px 1px 4px 1px $dark-body-background;
							}
						}

						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-border-color;
							}

							.message {
								color: $dark-all-font-color;
							}

							.other-message {
								background-color: $dark-body-background;
							}
						}

						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;

							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}

				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid $dark-border-color;

								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
			}

			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}

			.scorlled {
				background-color: $dark-body-background;
			}

			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}

			.input-group-text {
				background-color: $dark-body-background;
				border: 1px solid $dark-border;
				color: rgba(255, 255, 255, 0.6);

				i {
					color: rgba(255, 255, 255, 0.6);
				}
			}

			.input-group-solid {

				.input-group-text,
				.form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}

			.semilight-bg-color,
			.header-bg-color {
				background-color: $white;
			}

			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;

				+.list-group-item {
					border-top-width: 0;
				}

				&.active {
					background-color: var(--theme-color) !important;
					border-color: var(--theme-color);
					color: $white;
				}

				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}

			.list-group-item-action {

				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}

			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}

			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}

			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}

			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}

			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}

			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}

			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}

			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}

			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}

			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);

				.authentication-box {
					.card {
						background-color: $dark-card-background;

						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}

								label {
									color: $dark-all-font-color;
								}
							}

							.checkbox {
								label {
									color: $dark-all-font-color;

									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}

					h3,
					h4,
					h6 {
						color: $white;
					}
				}
			}

			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);

				.authentication-box {

					h4,
					h3 {
						color: $white;
					}

					h6 {
						color: $dark-small-font-color;
					}

					.card {
						background-color: $dark-card-background;

						.theme-form {
							.form-group {

								input[type="text"],
								input[type="password"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}

							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}

			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}

						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}

			.modal-content {
				background-color: $dark-card-background;

				.modal-header {
					border-bottom: 1px solid $dark-border-color;

					.close {
						color: $dark-small-font-color;
					}
				}

				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}

			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);

				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}

					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;

						.title {
							color: $dark-all-font-color;
						}
					}

					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}

			.theme-form {
				.login-divider {
					border-top: 1px solid $dark-border-color;

					&:before {
						background: $dark-border-color;
						color: $dark-all-font-color;
					}
				}
			}

			.authentication-main {
				background-color: $dark-border-color;

				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}

						.reset-password-link {
							color: $dark-small-font-color;
						}

						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}

								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}

							.opt-box {
								background-color: $dark-border-color;
							}
						}
					}

					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}

						h6 {
							color: $dark-small-font-color;
						}

						h3 {
							color: $dark-all-font-color;
						}

						.card {
							background-color: $dark-card-background;

							.theme-form {
								.form-group {

									input[type="text"],
									input[type="password"] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}

								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}

			.vertical-menu-main {
				background-color: $dark-card-background;
			}

			.mega-menu {
				.title {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}

				.list-unstyled {
					div {
						a {
							&:hover {
								color: var(--theme-color);
							}
						}
					}
				}
			}

			.default-according {
				.card {
					background-color: $dark-card-background;

					.btn-link {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
						border-bottom: none;
						color: $white;
					}

					.text-muted {
						color: $dark-small-font-color !important;
					}
				}

				.bg-primary {
					.btn-link {
						background-color: var(--theme-color);
						border: 1px solid var(--theme-color);
					}
				}

				.bg-secondary {
					.btn-link {
						background-color: var(--theme-secondary);
						border: 1px solid var(--theme-secondary);
					}
				}
			}

			.collapse {
				.card-body {
					background-color: $dark-card-background;
				}
			}

			@media screen and (max-width: 1660px) {
				.chat-box {
					.chat-history {
						.call-icons {
							ul {
								li {
									border: 1px solid $dark-border-color;
								}
							}
						}

						.total-time {
							h2 {
								color: $dark-all-font-color;
							}
						}
					}
				}

				.caller-img {
					img {
						opacity: 0.7;
					}
				}

				.chat-box {
					.chat-history {
						.call-content {
							>div {
								background-color: rgba(0, 0, 0, 0.75);
								background-blend-mode: overlay;
							}
						}
					}
				}
			}

			@media only screen and (max-width: 1199.98px) {
				.left-header {
					.mega-menu-container {
						border-left: 1px solid $dark-card-border;

						.mega-box {
							+.mega-box {
								border-left: none;
							}
						}
					}
				}
			}

			@media only screen and (max-width: 1199px) {
				.mobile-title {
					&.d-none {
						border-bottom: 1px solid $dark-card-border;
					}
				}

				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}

				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
			}

			@media only screen and (max-width: 575.98px) {
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}

				.widget-joins {
					.border-after-xs {
						&:after {
							background-color: $dark-border-color;
						}
					}
				}

				.page-header {
					.header-wrapper {
						.left-header {
							ul {
								li {
									.search-form {
										.form-control-plaintext {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
					}
				}

				.page-wrapper {

					&.compact-wrapper,
					&.compact-sidebar {
						.page-header {
							.header-wrapper {
								.toggle-sidebar {
									border-right: 1px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}

		.lg-backdrop {
			background-color: $dark-body-background;
		}

		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}

		.drag {
			background-color: $dark-card-background;

			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;

				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}

				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}

		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}

			p {
				color: $dark-small-font-color;
			}

			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}

			.radio,
			.checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}

		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}

				input[type="text"],
				input[type="email"],
				input[type="search"],
				input[type="password"],
				input[type="number"],
				input[type="tel"],
				input[type="date"],
				input[type="datetime-local"],
				input[type="time"],
				input[type="datetime-local"],
				input[type="month"],
				input[type="week"],
				input[type="url"],
				input[type="file"],
				select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;

					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}

				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}

				textarea {
					border-color: $dark-border-color;
				}
			}

			.form-divider {
				border-top: 1px solid $dark-border-color;

				&::before {
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}

		.CodeMirror {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;

			.CodeMirror-code {
				pre {
					background: $dark-card-background;
				}
			}
		}

		.editor-toolbar {
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;

			a {
				color: $dark-all-font-color !important;

				&:hover,
				&.active {
					background: $dark-border-color;
				}
			}

			i.separator {
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}

			&.fullscreen {
				&::before {
					background: linear-gradient(to right, $dark-body-background 0, rgba(0, 0, 0, 0) 100%);
				}

				&::after {
					background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, $dark-body-background 100%);
				}
			}
		}

		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: $dark-border-color;
				}
			}
		}

		.editor-preview {
			background-color: $dark-card-background;
		}

		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}

		.u-step {
			background: $dark-border-color;

			&.active,
			&.current {
				background: var(--theme-color);
				color: $white;
			}
		}

		.u-step-title,
		.u-pearl-title {
			color: $dark-all-font-color;
		}

		.u-step-number {
			background-color: $dark-card-background;
		}

		.u-pearl {
			&:before {
				background-color: $dark-border-color;
			}
		}

		.u-pearl-number,
		.u-pearl-icon {
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}

		.u-pearl.disabled {

			.u-pearl-icon,
			.u-pearl-number {
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}

			&:after {
				background-color: #334053;
			}
		}

		.u-pearl.error {
			&:after {
				background-color: $dark-body-background;
			}
		}

		.note-editor.note-frame {
			border-color: $dark-border-color;

			.note-editing-area {
				.note-editable {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}

		.swal-modal {
			background-color: $dark-card-background;

			.swal-title {
				color: $dark-all-font-color;
			}

			.swal-text {
				color: $dark-small-font-color;
			}

			.swal-content__input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}

		.nav-tabs {
			border-bottom: 1px solid $dark-card-border;

			.nav-link {
				color: $dark-all-font-color;

				&.active {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border $dark-card-border $dark-card-background;
				}

				&:hover,
				&:focus {
					border-color: $dark-card-border $dark-card-border $dark-card-inbox;
				}
			}

			.nav-item.show {
				.nav-link {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
			}
		}

		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {

					&.active,
					&.focus,
					&.hover {
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}

		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: $dark-all-font-color;

				&.active {
					color: var(--theme-color);
				}
			}

			.show {
				>.nav-link {
					color: var(--theme-color);
				}
			}
		}

		.border-tab.nav-left.nav-secondary {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--theme-secondary);
					color: var(--theme-secondary) !important;
				}
			}

			.show>.nav-link {
				border-left-color: var(--theme-secondary);
				color: var(--theme-secondary) !important;
			}

			.nav-item.show {
				color: var(--theme-secondary) !important;
				border-left-color: var(--theme-secondary);
			}
		}

		.border-tab.nav-left.nav-success {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}

			.show>.nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}

			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}

		.border-tab.nav-right.nav-info {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}

			.show>.nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}

			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}

		.border-tab.nav-secondary {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: var(--theme-secondary) !important;
				}
			}

			.nav-item {
				&.show {
					color: var(--theme-secondary) !important;
				}
			}
		}

		.border-tab.nav-success {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}

			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}

		.dropdown-divider {
			border-top: 1px solid $dark-border-color;
		}

		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;

			.icon-title {
				color: $dark-all-font-color;
			}

			span {
				color: $dark-small-font-color;
			}

			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}

			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}

		code {
			background-color: $dark-body-background;
			border-radius: 2px;
		}

		#cd-timeline {
			&::before {
				background: $dark-border-color;
			}
		}

		.timeliny {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;

			&::before {
				background-color: $white;
			}

			.timeliny-dot {
				background-color: $dark-card-background;
				border: 1px solid $white;

				&::before {
					color: $dark-all-font-color;
				}
			}

			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: $dark-all-font-color;
							}

							&::after {
								background-color: $dark-border-color;
								color: $dark-all-font-color;
								border: none;
							}
						}
					}

					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;

								&::before {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.timeliny-dot {
						&:hover {
							&::after {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;

							.task-label {
								color: $dark-all-font-color;
							}

							&:hover {
								h4 {
									color: $white;
								}
							}

							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}

						&.completed {
							.task-container {
								.task-label {
									color: var(--theme-color);
								}

								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}

				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.user-profile {
			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}

				span {
					color: $dark-all-font-color;
				}
			}

			.hovercard {
				.info {
					.title {
						a {
							color: $dark-all-font-color;
						}
					}
				}

				.user-image {
					.avatar {
						img {
							border: 10px solid $dark-card-background;
						}
					}

					.icon-wrapper {
						background-color: $dark-card-background;
					}
				}

				.tabs-scoial {
					border-bottom: none !important;
				}
			}

			.follow {
				.follow-num {
					color: $dark-all-font-color;
				}
			}

			.profile-img-style {
				.user-name {
					color: $dark-all-font-color;
				}

				p {
					color: $dark-small-font-color;
				}
			}
		}

		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}

		.jstree-default {

			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}

			.jstree-anchor {
				color: $dark-small-font-color;
			}

			.jstree-clicked {
				color: $white;
				background-color: $transparent-color;
			}

			.jstree-hovered {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}

		span.twitter-typeahead {
			.tt-menu {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}

			.tt-suggestion {
				color: $dark-all-font-color;

				&:hover,
				&:focus {
					background-color: $dark-card-background;
				}
			}
		}

		.typography {
			small {
				color: $dark-all-font-color;
			}
		}

		.code-box-copy {
			pre {
				background-color: $dark-body-background;

				code {
					background-color: $dark-body-background;
				}
			}

			pre[class*="language-"] {
				border: 1px solid $dark-border-color;
			}

			.code-box-copy__btn {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color: $white;
			}

			code[class*="language-"],
			pre[class*="language-"] {
				text-shadow: 0px 1px $black;

				::selection {
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}

		table.fixedHeader-floating {
			background-color: $dark-body-background;
		}

		.note {
			textarea {
				color: $dark-all-font-color;
			}
		}

		.dt-button-info {
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;

			h2 {
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}

		pre {
			background-color: $dark-body-background;
		}

		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}

		#example-style-8_wrapper {

			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: $dark-border-color;
					}
				}
			}
		}

		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);

			.sub-content {
				color: $dark-all-font-color;
			}
		}

		.b-light {
			border: 1px solid $dark-border-color !important;
		}

		.modal-content {
			background-color: $dark-card-background;

			.modal-header {
				border-bottom: 1px solid $dark-card-border;

				.close {
					color: $dark-all-font-color;
					font-weight: 400;
				}
			}

			.ui-front {
				.form-control {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
		}

		.stepwizard {
			.stepwizard-row {
				&:before {
					background-color: $dark-card-border;
				}
			}
		}

		.modal {
			.theme-close {
				background-color: $dark-card-background !important;
				color: $light-all-font-color;
			}
		}

		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}

		.token {

			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}

		.loader-box {
			&.card-loader {
				background-color: $dark-card-background;
			}
		}

		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid $dark-border-color !important;
					border-bottom: none !important;
				}
			}
		}

		@each $var in $alert-name {
			$i: index($alert-name, $var);

			.alert-#{$var}.inverse {
				color: $dark-all-font-color;
			}
		}

		.alert-theme {
			span {
				+span {
					+span {
						border-left: 5px solid var(--theme-color);
						background-color: $dark-body-background;
						border-radius: 4px;
					}
				}
			}

			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}

		.user-card {
			.user-deatils {
				h6 {
					color: $dark-small-font-color;
				}
			}

			.card-footer {
				>div {
					&:first-child {
						border-right: 1px solid $dark-border-color;
					}
				}

				.user-footer {
					h6 {
						color: $dark-small-font-color;
					}

					svg {

						path,
						rect {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}

		.order-history {
			table {
				.qty-box {
					button {
						background-color: $dark-body-background !important;
						border: none !important;
					}

					.input-group {
						border-color: $dark-card-border;
					}
				}

				thead {
					tr {
						th {
							background-color: $dark-body-background;
						}
					}
				}

				tr {

					td,
					th {
						border-top: none !important;
					}
				}
			}

			.title-orders {
				background-color: $dark-body-background;
			}
		}

		.navigation-option {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}

					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.product-box {
			.product-details {
				h6 {
					color: $dark-all-font-color;
				}

				span {
					color: $dark-small-font-color;
				}
			}

			.product-img {
				.product-hover {
					ul {
						li {
							background-color: $dark-card-background;

							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}

		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: $dark-all-font-color;
						}

						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-color)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.radio-#{$btn-name} {
				input[type="radio"] {
					&+label {
						&::before {
							border-color: $btn-color !important;
						}

						&::after {
							background-color: $btn-color;
						}
					}

					&:checked {
						&+label {
							&::before {
								border-color: $btn-color !important;
							}

							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-color)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-color)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}

				input[type="checkbox"] {
					&:checked {
						&+label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}

							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}

		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
			}

			tr {

				th,
				td {
					&:hover {
						background-color: $dark-card-background;
					}

					span {
						&:hover {
							background-color: $dark-card-background;
						}
					}

					&.prev,
					&.next {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}

				&:first-child {
					th {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
			}
		}

		.btn-transparent {
			color: $dark-all-font-color;
		}

		#cal-basic {
			.fc-toolbar {

				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}

					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
				}

				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}

		.fc-button-group {

			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}

			.fc-month-button {
				color: $white !important;
			}
		}

		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {

						.fc-month-button,
						.btn-light {
							color: $black !important;
						}

						.fc-agendaWeek-button {
							color: $white !important;

							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}

		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {

						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}

						.fc-month-button {
							color: $white !important;

							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}

		.categories {
			ul {
				li {
					a {
						color: $dark-small-font-color;
					}

					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: $dark-all-font-color;
					}
				}
			}

			span {
				color: $dark-small-font-color;
			}
		}

		.social-status {
			.media {
				.media-body {
					span {
						+span {
							color: $dark-small-font-color;
						}
					}

					p,
					.light-span {
						color: $dark-small-font-color;
					}
				}
			}
		}

		.filter-cards-view,
		.timeline-content {
			p {
				color: $dark-small-font-color;
			}

			.comment-number {
				i {
					color: $dark-small-font-color;
				}
			}

			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}

					.input-group-append {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.social-chat {
			.media-body {
				border: 1px solid $dark-border-color;

				&:after {
					border-right: 7px solid $dark-card-background;
				}
			}

			span {
				span {
					color: $dark-small-font-color;
				}
			}
		}

		.details-about {
			.your-details {
				p {
					color: $dark-small-font-color;
				}
			}
		}

		.activity-log {
			.my-activity {
				p {
					color: $dark-small-font-color;
				}
			}
		}

		.bookmark {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}

		//index page
		@media screen and (max-width: 1366px) {
			.earning-card {
				&.card {
					.card-body {
						.earning-content {
							border-bottom: 1px solid $dark-card-border;
						}
					}
				}
			}
		}

		@media only screen and (max-width: 991.98px) {
			.page-wrapper {

				&.compact-wrapper,
				&.compact-sidebar {
					.left-header {
						.mega-menu {
							svg {
								stroke: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 767.98px) {
			.page-wrapper {

				&.compact-wrapper,
				&.compact-sidebar {
					.left-header {
						.mega-menu {
							svg {
								stroke: $white;
							}

							.nav-link {
								border-left: 1px solid $dark-card-border;
							}
						}
					}
				}

				&.material-icon {
					.page-header {
						background-color: $dark-card-background;
					}
				}
			}

			.chart_data_left {
				.card-body {
					.chart-main {
						>div {
							&:first-child {
								border-right: 1px solid $dark-card-border;
							}

							&:nth-child(3) {
								border-right: 1px solid $dark-card-border;
							}
						}
					}
				}
			}
		}

		.apexcharts-tooltip.light {
			border-color: $dark-card-border;
			background-color: $dark-body-background;

			.apexcharts-tooltip-title {
				border-color: $dark-card-border;
			}

			.apexcharts-tooltip-text-label,
			.apexcharts-tooltip-text-value {
				color: $white;
			}
		}

		/**=====================
          view-detail-page dark
         ==========================**/

		.single-section {
			.description-section {
				&.tab-section {
					.menu-top {
						.nav-tabs {
							background-color: $dark-body-background !important;
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.menu-top {
					li {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.btn-outline {
			color: $dark-all-font-color;
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						background-color: $dark-card-background;
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.about {
							.about-sec {
								p {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.about {
							.about-sec {
								ul {
									li {
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part.about {
						.about-sec {
							h6 {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.dark-body {
			background-color: $dark-body-background !important;
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.accordion-plan {
							.card {
								.card-header {
									button {
										color: $dark-all-font-color;

										&::before {
											content: "";
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.order-menu {
			.search-bar {
				.search {
					input {
						background-color: $dark-body-background;

						&:focus {
							outline: none;
							border: none;
						}
					}
				}
			}
		}

		.order-menu {
			.search-bar {
				border-color: #f2f9fc1f;
			}
		}

		.order-menu {
			.nav {
				.nav-link {
					color: $dark-all-font-color;
				}
			}
		}

		.order-menu-section {
			border-color: #f2f9fc1f;

			.order-section {
				h4 {
					color: $dark-all-font-color;
				}

				.order-title {
					h6 {
						color: $dark-all-font-color;
					}
				}

				.order-items {
					.items {
						h5 {
							color: $dark-all-font-color;
						}

						p {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.cab-single-detail {
			background-color: $dark-card-background;

			.description {
				color: $dark-all-font-color;
			}

			.overview {
				h6 {
					color: $dark-all-font-color;
				}

				ul {
					li {
						img {
							filter: invert(1);
						}
					}
				}
			}

			.details {
				h6 {
					color: $dark-all-font-color;
				}
			}
		}

		.order-menu-section {
			.order-section {
				.order-items {
					.items+.items {
						border-color: #f2f9fc1f;
					}
				}
			}
		}

		.order-menu {
			.nav {
				.nav {
					.nav-link {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}

		.order-menu {
			.nav {
				.nav-link.active {
					background-color: $dark-card-background;
				}
			}
		}

		.order-menu {
			.nav {
				background-color: $dark-body-background;
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.facility {
							h6 {
								img {
									filter: invert(1);
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.list-view {
							.list-box {
								.list-content {
									.facility-icon {
										.facility-box {
											img {
												filter: invert(1);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.accordion-plan {
							.card {
								.card-body--modifay {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.list-view {
							.list-box {
								background-color: $dark-body-background;
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.list-view {
							.list-box {
								.list-content {
									p {
										color: $dark-all-font-color;
									}

									h5 {
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.review {
							.review-box {
								h6 {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.review {
							.review-box+.review-box {
								border-top: 1px solid #f2f9fc1f;
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.review {
							.review-box {
								p {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.review-users:not(:last-of-type) {
			border-bottom: 1px solid #f2f9fc1f !important;
		}

		.hotel_title_section {
			.hotel-name {
				.left-part {
					p {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.facility {
							h6 {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						.accordion-plan {
							.card {
								.card-body {
									.highlight {
										li {
											img {
												filter: invert(1);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.facility {
							ul {
								li {
									i {
										color: $dark-all-font-color;
									}
								}
							}

							h6 {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.policy {
							p {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.single-section {
			.description-section {
				.description-details {
					.menu-part {
						&.about {
							p {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.review-box {
			border-color: $dark-card-border;
			.img-box {
				background: $dark-body-background;
			}
		}

		.blog-box {
			.blog-content {
				h6 {
					color: rgba($dark-all-font-color, 0.3);
				}
				a {
					color: $dark-all-font-color;
				}
			}
		}

		.pos-product-screen {
			.owl-theme {
				.owl-nav {
					[class*=owl-] {
						background-color: $dark-body-background;
						&:after {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
	}

	&.dark-sidebar {
		.toggle-sidebar {
			svg {
				stroke: var(--theme-color);
			}
		}

		.page-wrapper {
			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}

			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						background: linear-gradient(180.02deg,
								rgba(245, 245, 245, 0.05) 0.02%,
								rgba(245, 245, 245, 0) 90.86%);
						animation: none;

						.sidebar-main {
							.sidebar-links {
								li {
									a {
										span {
											color: $dark-all-font-color;
										}

										svg {
											stroke: $dark-all-font-color;
										}
									}
								}

								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.sidebar-link {
														&.active {
															background-color: rgba($primary-color, 0.2);

															svg {
																color: var(--theme-color);
																stroke: var(--theme-color);
															}
														}
													}

													.mega-menu-container {
														.mega-box {
															.link-section {
																.submenu-title {
																	h5 {
																		color: $dark-all-font-color;
																	}
																}

																.submenu-content {
																	&.opensubmegamenu {
																		li {
																			a {
																				color: $dark-all-font-color;
																				font-weight: 400;
																			}
																		}
																	}
																}
															}
														}

														&::after {
															background-color: $light-all-font-color;
														}
													}

													.sidebar-submenu {
														li {
															a {
																color: $dark-all-font-color;
															}
														}
													}
												}
											}
										}
									}
								}

								.sidebar-list {
									ul.sidebar-submenu {
										li {
											a {
												span {
													color: $dark-all-font-color;
												}

												&.active {
													color: var(--theme-color);
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}

			&.horizontal-wrapper {
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;

						.sidebar-main {
							background-color: $dark-card-background;

							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;
													}

													.sidebar-submenu {
														background: $dark-card-background;

														li {
															a {
																color: $dark-all-font-color;

																&.active,
																&:hover {
																	color: var(--theme-color);
																}
															}

															.nav-sub-childmenu {
																background: $dark-card-background;
															}
														}
													}

													.mega-menu-container {
														background: $dark-card-background;

														.mega-box {
															.link-section {
																.submenu-content {
																	li {
																		a {
																			color: $dark-all-font-color;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}

							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}

							.sidebar-main {
								.sidebar-links {
									li {
										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}

										a {
											span {
												color: $dark-all-font-color;
											}

											svg {
												stroke: $dark-all-font-color;
											}
										}

										.sidebar-submenu,
										.mega-menu-container {
											background-color: $dark-card-background;

											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}

									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);

																span {
																	color: var(--theme-color);
																}

																svg {
																	color: var(--theme-color);
																	stroke: var(--theme-color);
																}
															}
														}

														.mega-menu-container {
															.mega-box {
																.link-section {
																	.submenu-title {
																		h5 {
																			color: $dark-all-font-color;
																		}
																	}

																	.submenu-content {
																		&.opensubmegamenu {
																			li {
																				a {
																					color: $dark-all-font-color;
																					font-weight: 400;
																				}
																			}
																		}
																	}
																}
															}

															&::after {
																background-color: $light-all-font-color;
															}
														}

														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}

									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}

													&.active {
														color: var(--theme-color);
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
	}

	.for-dark {
		display: none;
	}

	.main-dark {
		display: none;
	}

	&[class*="dark-"] {
		.for-dark {
			display: block;
		}

		.for-light {
			display: none;
		}

		.main-dark {
			display: block;
		}

		.main-white {
			display: none;
		}
	}
}