/**=====================
    Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  content: "";
  top: 48%;
  width: 6px;
  border-top: 2px solid $white;
}

%horizontal-after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
}

%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}

%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }

  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $light-background;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}

%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $light-background;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba($black, $alpha: 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}

.page-wrapper {
  position: relative;
  background-color: #f6f5f0;

  .page-header {
    max-width: 100vw;
    position: $main-header-position;
    top: $main-header-top;
    z-index: 7;
    transition: $sidebar-transition;
    background-color: $white;

    .header-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: $header-wrapper-padding;
      position: relative;

      @media (max-width: 1199px) {
        padding: 22px 20px;
      }

      @media (max-width: 991px) {
        padding: 12px 20px;
      }

      &.skeleton-header {
        pointer-events: none;

        .user-round {
          h4 {
            display: none;
          }
        }

        .nav-right {
          >ul {
            >li {
              .badge {
                display: none !important;
              }
            }
          }
        }

        .search-full {
          height: 46px;
          background-color: #f9f9f6;
          border-radius: 10px;

          input {
            &::placeholder {
              color: #f9f9f6;
            }
          }

          .close-icon {
            display: none;
          }

          .form-group {
            &:before {
              display: none;
            }
          }
        }

        .nav-right {
          &.right-header {
            .notification-box {
              >div {
                animation: none;
              }
            }

            ul {
              li {
                .dropdown-toggle {
                  &::after {
                    display: none;
                  }
                }

                .mode {
                  i {
                    background-color: #f2f2f2;
                    color: transparent;
                    border-radius: 100%;
                  }
                }

                i {
                  background-color: #f2f2f2;
                  color: transparent;
                  border-radius: 100%;
                  width: 21px;
                  height: 21px;

                  &.middle {
                    background: transparent;
                  }
                }

                .profile-media {
                  .profile-img {
                    background: #f2f2f2;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;

                    img {
                      display: none;
                    }
                  }

                  .media-body {
                    p {
                      background: linear-gradient(90deg,
                          rgba(212, 212, 212, 0.3) 8%,
                          #e4e4e4 18%,
                          rgba(212, 212, 212, 0.3) 33%);
                      background-size: 800px 104px;
                      animation: skeleton-loader 2s infinite linear;
                      border-color: transparent !important;
                      color: transparent !important;
                    }

                    span {
                      background: linear-gradient(90deg,
                          rgba(212, 212, 212, 0.3) 8%,
                          #e4e4e4 18%,
                          rgba(212, 212, 212, 0.3) 33%);
                      background-size: 800px 104px;
                      animation: skeleton-loader 2s infinite linear;
                      border-color: transparent !important;
                      color: transparent !important;
                      width: 70%;
                      margin-bottom: 0px;
                      height: 19px;
                    }
                  }
                }
              }
            }
          }
        }

        .header-btns {
          .btn {
            background: linear-gradient(90deg, rgba(212, 212, 212, 0.3) 8%, #e4e4e4 18%, rgba(212, 212, 212, 0.3) 33%);
            background-size: 800px 104px;
            animation: skeleton-loader 2s infinite linear;
            border-color: transparent !important;
            color: transparent !important;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      .search-full {
        position: relative;
        z-index: 1;
        height: auto;
        width: 600px;
        transition: all 0.3s ease;

        @media (max-width: 1600px) {
          width: 530px;
        }

        @media (max-width: 1456px) {
          width: 470px;
        }

        @media (max-width: 1260px) {
          width: 400px;
        }

        @media (max-width: 1130px) {
          width: 350px;
        }

        @media (max-width: 1080px) {
          width: 300px;
        }

        @media (max-width: 1034px) {
          width: 257px;
        }

        @media (max-width: 991px) {
          width: 377px;
        }

        &.open {
          display: block;
          height: 100%;
          // width: 100%;
          animation: zoomIn 0.5s ease-in-out;
          transform: scale(1);
          transition: all 0.3s ease;
        }

        .close-icon {
          position: absolute;
          right: 18px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          color: $black;
          cursor: pointer;

          [dir="rtl"] & {
            right: unset;
            left: 18px;
          }
        }

        .onhover-dropdown {
          position: absolute;
          left: 0;
          top: 60px;
          background-color: white;
          width: 100%;
          padding: 15px;
          box-shadow: 0 0 8px #eee;
          border-radius: 10px;
          max-height: 260px;
          overflow: auto;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-20px);
          transition: all 0.5s ease;

          @media (max-width: 767px) {
            left: 15px;
            top: 70px;
            width: calc(100% - 30px);
          }

          &.open {
            opacity: 1;
            visibility: visible;
            transform: none;
          }

          * {
            scrollbar-width: auto;
            scrollbar-color: #ffffff;
          }

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background: #ffffff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 10px;
            border: 3px solid #ffffff;
            transition: all 0.5s ease;
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background-color: hsla(168, 73%, 37%, 0.479);
            }
          }

          >ul {
            >li {
              +li {
                margin-top: 14px;
              }

              ul {
                padding-left: 14px;
                padding-top: 15px;

                li {
                  a {
                    color: #626262;
                  }

                  +li {
                    margin-top: 6px;
                  }
                }
              }
            }
          }

          .main-content {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: #555;
            padding: 9px 10px;
            background-color: #f9f9f6;
            border-radius: 26px;
          }
        }

        input {
          font-size: 16px;
          line-height: 32px;
          padding-left: 30px;
          width: 100% !important;
          background-color: $inner-bg;
          border-radius: 10px;

          @media (max-width: 1199px) {
            padding-top: 4px;
            padding-bottom: 4px;
          }

          &:focus {
            outline: none !important;
          }
        }

        .form-group {
          .close-search {
            position: absolute;
            font-size: 15px;
            right: 20px;
            width: 18px;
            height: 25px;
            top: 30px;
            display: none;
            color: $dark-gray;
            cursor: pointer;
          }

          &:before {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            font-size: 18px;
            color: white;
            content: "\F0D1";
            font-family: remixicon !important;
            font-style: normal;
            background-color: var(--theme-color);
            padding: 10px 18px 9px;
            border-radius: 0 8px 8px 0;

            [dir="rtl"] & {
              border-radius: 8px 0 0 8px;
            }

            @media (max-width: 1199px) {
              padding: 8px 18px 7px;
            }

            [dir="rtl"] & {
              left: 0;
              right: unset;
            }
          }
        }
      }

      .nav-right {
        justify-content: flex-end;
        display: flex;
        margin-left: auto;

        [dir="rtl"] & {
          margin-left: unset;
          margin-right: auto;
        }

        ul {
          li {
            svg {
              vertical-align: $header-wrapper-nav-icon-align;
              width: $header-wrapper-nav-icon-size;

              path {
                color: $header-wrapper-nav-icon-color;
              }
            }
          }
        }

        &.right-header {
          ul {
            li {
              i {
                font-size: 21px;
                color: #4a5568;
              }

              .mode {
                cursor: pointer;
                width: 20px;
                text-align: center;

                i {
                  font-size: 21px;
                  font-weight: 200;
                  transition: all 0.3s ease;

                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px;
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }

              .header-search {
                cursor: pointer;
                display: none;
              }

              &.translate-btn {
                .btn {
                  padding: 0 !important;
                  background-color: transparent;

                  &.dropdown-toggle {
                    &::after {
                      display: none;
                    }
                  }
                }

                .profile-dropdown {
                  width: 200px;

                  li {
                    +li {
                      padding-top: 10px !important;
                    }

                    &.active {
                      a {
                        span {
                          color: var(--theme-color);
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }

              .profile-media {
                align-items: center;

                .dropdown-toggle {
                  gap: calc(0px + (8 - 0) * ((100vw - 320px) / (1920 - 320)));
                }

                .media-body {
                  margin-left: 15px;
                  align-items: center;

                  [dir="rtl"] & {
                    margin-left: 0;
                    margin-right: 15px;
                  }

                  span {
                    font-weight: 600;
                    font-size: 15px;
                    color: inherit;
                    text-transform: capitalize;
                  }

                  p {
                    font-size: 13px;
                    line-height: 1;
                    color: $theme-body-font-color;
                    text-transform: capitalize;

                    .fa-angle-down {
                      margin-left: 5px;

                      [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 5px;
                      }
                    }
                  }
                }

                .user-profile {
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                }
              }
            }
          }
        }

        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          >li {
            padding: 0 8px;
            margin-right: 10px;
            font-size: 18px;
            display: inline-block;
            position: relative;

            @media (max-width: 1400px) {
              margin-right: 0;
            }

            .badge {
              position: absolute;
              right: -5px;
              top: -6px;
              padding: 2px 4px;
              font-size: 11px;
              font-weight: 700;
              font-family: $public-sans;
              background-color: #ff7272;
            }

            span {
              &.lnr {
                font-weight: 600;
              }

              ul {
                left: inherit;
                right: -10px;
                width: 130px;

                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }

                li {
                  display: block;

                  a {
                    font-size: 14px;
                    color: $dark-color;

                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
          }

          .flag-icon {
            font-size: 16px;
          }
        }

        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;
        }

        .flip-card {
          width: 220px;
          height: 245px;
          background-color: transparent;

          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 220px;
              height: 240px;

              button {
                background-color: $transparent-color;
                color: $primary-color;
                border: none;

                &:focus {
                  outline: $transparent-color;
                }
              }
            }

            .front {
              background-color: $white;
              transition: 0.3s;

              svg {
                margin-bottom: 15px;
                stroke: $dark-color;
                transition: all 0.3s ease;

                &:hover {
                  stroke: var(--theme-color);
                  transition: all 0.3s ease;
                }
              }
            }

            .back {
              background-color: $white;
              transform: rotateY(180deg);

              li {
                &:first-child {
                  margin-top: 20px;
                  height: 160px !important;
                }

                &:last-child {
                  padding: 20px 0;
                  border-top: 1px solid $gray-light;
                }
              }

              button {
                width: 100%;
              }

              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid $gray-light;
                }
              }
            }

            &.flipped {
              transform: rotateY(180deg);

              .front {
                opacity: 0;
              }
            }
          }
        }

        .onhover-show-div:not(.profile-dropdown) {
          li {
            &:first-child {
              padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
              background-color: $primary-color;
              color: $white;
              font-weight: 600 !important;

              span,
              i {
                position: absolute;
                width: 50px;
                height: 50px;
                top: -5px;
                right: -10px;
                opacity: 0.15;
                font-size: 50px;

                [dir="rtl"] & {
                  right: unset;
                  left: -10px;
                }
              }
            }
          }
        }

        .onhover-show-div {
          width: 330px;
          top: 50px;
          font-size: 16px;
          right: 0;
          left: unset;

          li {
            padding: 0 16px 0 16px;

            h6 {
              font-weight: 700;
            }

            p {
              opacity: 1;
            }

            &+li {
              padding-top: 15px;
              padding-bottom: 0px;
            }

            &:last-child {
              padding-bottom: 15px;
              padding-top: 15px;
            }

            .media {
              position: relative;

              img {
                width: 40px;
                position: relative;
              }

              .status-circle {
                left: -4px;
              }

              .media-body {
                >span {
                  letter-spacing: 0.8px;
                  padding-right: 10px;
                  font-weight: 500;
                  color: inherit;
                  display: inline-block;

                  [dir="rtl"] & {
                    padding-right: unset;
                    padding-left: 10px;
                  }
                }

                p {
                  margin-bottom: 15px;
                }
              }
            }
          }

          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }

        .notification-box {
          position: relative;

          >div {
            animation: ring 2s 0.5s ease-in-out infinite;
            transform-origin: 50% 4px;

            @keyframes ring {
              20% {
                transform: rotate(15deg);
              }

              40% {
                transform: rotate(-10deg);
              }

              60% {
                transform: rotate(5deg);
              }

              80% {
                transform: rotate(-5deg);
              }

              100% {
                transform: rotate(0deg);
              }
            }
          }
        }

        .cart-box {
          position: relative;

          svg {
            animation: swing 1.5s ease infinite;
          }
        }

        .cart-dropdown {
          li {
            .media {
              align-items: center;
            }

            &+li {
              padding-bottom: 10px;
            }
          }

          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }

          .close-circle {
            position: absolute;
            right: 0;
            top: 0;

            svg {
              width: 14px;
            }
          }

          .qty-box {
            .input-group {
              width: 80px;

              .btn {
                padding: 0px 2px;
              }
            }
          }
        }

        .notification-dropdown {
          top: 52px;
          width: calc(220px + (300 - 220) * ((100vw - 320px) / (1920 - 320)));
          right: -20px !important;
          left: unset;

          li {
            &+li {
              padding-top: 15px;

              p {
                margin-bottom: 0;
                text-transform: capitalize;

                i {
                  font-size: 11px;
                }
              }
            }

            &:last-child {
              text-align: center;
              border-top: 1px solid $light-gray;
              margin-top: 15px;
            }

            p {
              span {
                color: $light-font;
              }
            }
          }

          &:before,
          &:after {
            right: 28px !important;
            left: unset !important;
          }
        }

        .profile-dropdown {
          max-width: 240px;
          top: 57px;
          right: -12px;
          padding: 15px 10px;
          font-size: 14px;
          width: max-content;

          li {
            padding: 3px 10px !important;

            &:hover {
              a {
                span {
                  color: var(--theme-color);
                  transition: color 0.3s ease;
                }

                i {
                  color: var(--theme-color);
                }
              }
            }

            &:last-child {
              padding-top: 0 !important;
            }

            a {
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                color: $dark-color;
              }

              i {
                margin-right: 0;
                color: $dark-color;
                transition: all 0.3s ease-in-out;

                [dir="rtl"] & {
                  margin-right: unset;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-body-wrapper {
    background-color: $body-color;

    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;

      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
    }

    .page-title {
      padding-top: $page-title-padding;
      padding-bottom: $page-title-padding;

      >.row {
        .col-6 {
          &:first-child {
            display: flex;

            h3 {
              padding-right: 20px;
            }
          }
        }
      }

      .row {
        align-items: center;

        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          text-transform: $page-title-text-tranform;

          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }

      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;

        li {
          font-size: 13px;
          letter-spacing: 1px;
          font-family: $public-sans;
        }

        .breadcrumb-item {
          &+.breadcrumb-item {
            &:before {
              content: $breadcrumb-content;
            }
          }

          a {
            color: var(--theme-color);

            svg {
              width: 16px;
              height: 16px;
              color: $dark-color;
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }

  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }

    .footer {
      margin-left: 0;
    }

    .header-logo-wrapper {
      width: 150px;

      .toggle-sidebar {
        display: none;
      }
    }

    .left-header {
      padding-right: 0;
    }

    .page-body-wrapper {
      li {
        display: inline-block;
      }

      .sidebar-wrapper {
        height: 54px;
        line-height: inherit;
        top: 81px;
        background: $white;
        box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
        overflow-x: hidden;
        z-index: 1;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        border-top: 1px solid $light-semi-gray;

        .badge {
          position: absolute;
          top: 2px;
          right: 0px;
          z-index: 1;
        }

        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }

        .sidebar-main {
          text-align: left;
          position: relative;

          .left-arrow,
          .right-arrow {
            position: absolute;
            top: 0px;
            padding: 15px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;

            &.disabled {
              display: none;
            }
          }

          .left-arrow {
            left: 0;
          }

          .right-arrow {
            right: 10px;
          }

          .sidebar-links {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            text-align: left;
            display: -webkit-box !important;
            padding: 0 5px;
            transition: all 0.5s ease;
            white-space: nowrap;

            li {
              &.sidebar-list {
                a {
                  &.active {
                    .according-menu {
                      i {
                        &:before {
                          content: "-";
                        }
                      }
                    }
                  }

                  .according-menu {
                    display: none;
                  }
                }

                ul.sidebar-submenu {
                  li {
                    a {
                      &.active {
                        color: $white;

                        &:after {
                          background-color: var(--theme-color);
                        }
                      }
                    }
                  }
                }
              }
            }

            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      position: relative;
                      padding-bottom: 10px;

                      &:hover {
                        >a {
                          >span {
                            transition: all 0.3s ease;
                          }
                        }

                        a {
                          transition: all 0.3s ease;

                          svg {
                            transition: all 0.3s ease;
                          }
                        }
                      }

                      &:first-child {
                        display: none !important;
                      }

                      >a {
                        display: block;
                        color: $theme-body-font-color;
                        line-height: 23px;
                        text-decoration: none;
                        text-transform: capitalize;
                        padding: 5px 15px;
                        margin: 0 3px;
                        letter-spacing: 0.04em;
                        position: relative;
                        border-radius: 5px;
                        font-weight: 500;
                        transition: all 0.3s ease;

                        &.active {
                          background-color: lighten($primary-color, 22%);
                          transition: all 0.3s ease;

                          svg,
                          span {
                            color: var(--theme-color);
                            transition: all 0.3s ease;
                          }
                        }

                        .badge {
                          position: absolute;
                          top: -5px;
                          margin-left: 1px;
                          right: 10px;
                          padding: 0.2em 0.7em;
                        }

                        svg {
                          width: 16px;
                          vertical-align: bottom;
                          margin-right: 10px;
                          stroke-width: 2.5px;
                        }
                      }

                      .sidebar-submenu {
                        position: fixed;
                        z-index: 4;
                        box-shadow: 0 0 1px 0 $dark-gray;
                        padding: 15px 0px 15px 0px;
                        background: $white;
                        transition: all 0.5s ease;

                        li {
                          border: 0;
                          float: none;
                          position: relative;
                          display: block;

                          a {
                            background: transparent;
                            padding: 4px 30px;
                            display: $white;
                            transition: all 0.5s ease;
                            line-height: 1.9;
                            color: rgba(43, 43, 43, 0.6);
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            font-family: $public-sans;
                            transition: all 0.3s ease;

                            &:after {
                              left: 20px;
                              @extend %horizontal-after;
                            }

                            &:hover {
                              color: var(--theme-color);
                              letter-spacing: 1.5px;
                              transition: all 0.3s ease;

                              &:after {
                                background-color: var(--theme-color);
                              }
                            }
                          }
                        }
                      }

                      .mega-menu-container {
                        position: fixed;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                        z-index: 4;
                        box-shadow: 0 0 1px 0 $dark-gray;
                        padding: 30px;
                        background: white;
                        width: 95%;
                        border-radius: 4px;
                        display: block !important;

                        .mega-box {
                          width: 20%;

                          .link-section {
                            .submenu-title {
                              h5 {
                                text-transform: uppercase;
                                font-size: 14px;
                                margin-left: -13px;
                              }
                            }

                            .submenu-content {
                              box-shadow: none;
                              background-color: transparent;
                              position: relative;
                              display: block;
                              padding: 0;

                              li {
                                border: 0;
                                float: none;
                                position: relative;
                                display: block;

                                a {
                                  border: 0 !important;
                                  background: transparent;
                                  color: $theme-body-font-color;
                                  border-radius: 0 !important;
                                  text-transform: capitalize;
                                  padding: 3px 0;
                                  font-size: 13px;
                                  display: block;
                                  letter-spacing: 0.07em;
                                  line-height: 1.9;
                                  position: relative;
                                  transition: all 0.3s ease;

                                  &:hover {
                                    color: var(--theme-color);
                                    letter-spacing: 1.5px;
                                    transition: all 0.3s ease;

                                    &:after {
                                      color: var(--theme-color);
                                      transition: all 0.3s ease;
                                    }
                                  }

                                  &:after {
                                    position: absolute;
                                    content: "";
                                    left: -12px;
                                    top: 50%;
                                    width: 5px;
                                    height: 1px;
                                    background-color: $light-semi-font;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      &:hover {
                        >a {
                          &:after {
                            left: 0;
                            right: 0;
                            opacity: 1;
                          }
                        }
                      }

                      &.mega-menu {
                        position: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }

      .page-body {
        margin-top: 135px;
      }

      .sidebar-main-title {
        display: none !important;
      }

      &.scorlled {
        .sidebar-wrapper {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }

    .page-header {
      width: 100%;
      box-shadow: none;

      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
      }
    }

    &.enterprice-type {
      footer {
        margin-left: 0;
      }
    }
  }

  &.material-icon {
    .left-header {

      .level-menu,
      .mega-menu {
        .nav-link {
          border-radius: 10px;
        }
      }
    }

    .card {
      border-radius: 30px;
      overflow: hidden;
      box-shadow: 0 0 20px rgba($primary-color, 0.1);
    }
  }

  .sidebar-main-title {
    &-2 {
      margin: 10px 0 15px;
    }

    &-3 {
      margin: 0 0 15px;
    }

    >div {
      background-color: rgba($white, 0.06);
      padding: 16px;
      border-radius: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h6 {
      color: var(--theme-color);
      margin-bottom: 5px;
      letter-spacing: 0.8px;
    }

    p {
      color: $dark-color;
      font-size: 11px;
      margin-bottom: 0;
      text-transform: capitalize;
      line-height: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // left sidebar css
  &.compact-wrapper {
    .page-header {
      margin-left: 280px;
      width: calc(100% - 280px);

      @media (max-width: 1199px) {
        margin-left: 260px;
        width: calc(100% - 260px);
      }

      &.close_icon {
        margin-left: 90px;
        width: calc(100% - 90px);

        ~.page-body-wrapper {
          .page-body {
            .footer {
              width: calc(100vw - 86px);
            }
          }
        }
      }

      .header-wrapper {
        .logo-wrapper {
          display: none;
        }

        .toggle-sidebar {
          display: none;
        }
      }
    }

    .page-body-wrapper {
      .sidebar-wrapper {
        position: fixed;
        top: 0;
        z-index: 7;
        height: 100vh;
        line-height: inherit;
        background: var(--theme-color);
        width: 280px;
        text-align: left;
        transition: all 0.5s ease;
        box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
        background: linear-gradient(-45deg, #0da487, #009289, #ffa53b);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        overflow: hidden;

        @media (max-width: 1199px) {
          width: 260px;
        }

        @media (max-width: 991px) {
          left: -260px;

          [dir="rtl"] & {
            left: unset;
            right: -260px;
          }
        }

        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }

          50% {
            background-position: 100% 50%;
          }

          100% {
            background-position: 0% 50%;
          }
        }

        .logo-wrapper,
        .logo-icon-wrapper {
          height: 82px;
          display: flex;
          align-items: center;
          padding-left: 30px;
          padding-top: 10px;
          padding-bottom: 10px;

          [dir="rtl"] & {
            padding-left: 0;
            padding-right: 30px;
          }

          a {
            display: flex;
            align-items: center;
            height: 100%;

            img {
              width: 70%;
              height: 100%;
              object-fit: contain;

              &.main-logo {
                width: 100%;
              }
            }
          }
        }

        .logo-icon-wrapper {
          display: none;
        }

        .logo-sm {
          display: none;
        }

        .user-section {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 15px 0 25px;

          .user-profile {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }

          .user-content {
            span {
              font-size: 16px;
              font-weight: 500;
              margin-top: 10px;
              color: $white;
            }

            p {
              color: #eeeeee;
            }
          }
        }

        &.close_icon {
          width: 81px;

          .logo-wrapper {
            padding-left: 18px;

            @media (max-width: 991px) {
              padding-left: 30px;
            }

            a {
              display: none;

              @media (max-width: 991px) {
                display: block;
              }
            }
          }

          .sidebar-main {
            .sidebar-links {
              li {
                .sidebar-submenu {
                  display: none !important;
                  transition: all 0.5s ease;

                  &.show {
                    @media (max-width: 991px) {
                      display: block !important;
                    }
                  }
                }
              }
            }
          }

          &:hover {
            width: 280px;
            transition: all 0.3s ease;

            .logo-wrapper {
              padding-left: 30px;

              a {
                display: flex;
              }
            }

            .logo-sm {
              display: none;
            }

            .sidebar-main {
              .sidebar-links {
                li {
                  .sidebar-submenu {
                    display: block;

                    &.show {
                      display: block !important;
                    }
                  }
                }
              }
            }

            .according-menu {
              display: block !important;
            }

            .logo-wrapper {
              .toggle-sidebar {
                display: flex;
              }
            }

            .badge {
              opacity: 1;
              width: auto;
              transition: all 0.3s ease;
            }

            .according-menu {
              display: block;
            }

            .sidebar-main {
              .sidebar-links {
                li {
                  max-width: unset;
                  transition: all 0.3s ease;

                  .sidebar-submenu {
                    a {
                      display: block;
                    }
                  }
                }
              }
            }

            .sidebar-main-title {
              opacity: 1;
              display: block !important;
              transition: all 0.3s ease;
            }

            .logo-wrapper {
              display: flex;

              @media (max-width: 991px) {
                display: flex;
              }
            }

            .logo-icon-wrapper {
              display: none;
            }

            .user-section {
              .user-profile {
                width: 100px;
                height: 100px;
              }
            }

            .simplebar-offset {
              top: 18px;
            }
          }

          .logo-wrapper {
            .toggle-sidebar {
              display: none;

              @media (max-width: 991px) {
                display: flex;
              }
            }
          }

          .logo-sm {
            display: block;

            @media (max-width: 991px) {
              display: none;
            }
          }

          .simplebar-offset {
            top: 0;
          }

          .user-section {
            padding-bottom: 0;

            .user-profile {
              width: 50px;
              height: 50px;
            }
          }

          .badge {
            opacity: 0;
            width: 0;
            padding: 0;
          }

          .sidebar-main {
            .sidebar-links {
              li {
                margin: 0;
                transition: all 0.3s ease;

                .sidebar-submenu {
                  a {
                    display: none;
                  }
                }

                a {
                  padding: 12px 14px;
                  transition: all 0.3s ease;
                }
              }
            }
          }

          .sidebar-main-title {
            display: none !important;
            opacity: 0;
            transition: all 0.3s ease;
          }

          .logo-icon-wrapper {
            text-align: right;
            display: block;
            padding: 14px;
          }

          .mobile-sidebar {
            transform: translate(183px);
          }

          ~.page-body,
          .footer {
            margin-left: 80px;
            transition: 0.5s;
          }

          ~.footer-fix {
            width: calc(100% - 90px);
          }

          .sidebar-main {
            .sidebar-links {
              .sidebar-list {
                .sidebar-title {
                  &.active {
                    ~.sidebar-submenu {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .according-menu {
            display: none !important;

            @media (max-width: 991px) {
              display: block !important;
            }
          }

          footer {
            width: calc(100vw - 86px);
          }
        }

        .sidebar-main {

          .left-arrow,
          .right-arrow {
            display: none;
          }

          .sidebar-links {
            height: $sidebar-height;
            left: -300px;
            z-index: 99;
            transition: color 1s ease;
            color: rgba(0, 0, 0, 0);
            margin-top: 0;
            margin-bottom: 30px;
            overflow-y: auto;
            overflow-x: hidden;

            li {
              a {
                font-weight: 500;

                span {
                  letter-spacing: 0.7px;
                  font-family: $public-sans;
                  text-transform: capitalize;
                  color: $white;
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                }

                &.link-nav {
                  .according-menu {
                    display: block;
                  }
                }
              }

              &:last-child {
                border-bottom: none;
              }
            }

            &.opennav {
              left: 0;
            }

            .back-btn {
              display: block;
              width: 100%;
              padding: 0;

              .mobile-back {
                padding: 20px;
                color: $theme-font-color;
                font-weight: 700;
                text-transform: uppercase;
                border-bottom: 1px solid #efefef;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
            }

            li {
              display: block;
              width: 100%;
              padding: 0 15px;
              position: relative;

              &.sidebar-main-title {
                padding: 0 15px 12px;
              }

              &.sidebar-list {
                .badge {
                  position: relative;
                  right: 0;
                  top: 0;
                  padding: 0.44em 0.7em 0.32em;
                  z-index: 1;
                  width: max-content;
                }

                &:hover {
                  >a {
                    &:hover {
                      transition: all 0.3s ease;
                    }
                  }
                }
              }

              a {
                padding: 12px 15px;
                position: relative;
                transition: all 0.3s ease;
                display: flex !important;
                justify-content: space-between;

                svg {
                  width: 18px;
                  margin-right: 10px;
                  vertical-align: bottom;
                  float: none;
                  stroke-width: 1.5px;
                  color: $grey-3;
                  height: 18px;
                  transition: all 0.3s ease;
                }

                i {
                  font-size: 20px;
                  margin-right: 10px;
                  color: $white;
                  transition: all 0.3s ease;
                  line-height: 1;

                  [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 10px;
                  }
                }

                .according-menu {
                  margin-right: unset;

                  i {
                    margin-right: unset;
                  }
                }

                .sub-arrow {
                  right: 20px;
                  position: absolute;
                  top: 10px;

                  i {
                    display: none;
                  }

                  &:before {
                    display: none;
                  }
                }
              }

              &.back-btn {
                display: none;
              }

              .sidebar-submenu {
                width: 100%;
                padding: 0;
                position: relative !important;
                display: none;
                animation: 0.3s alternate sidebar-menu-open forwards;

                @keyframes sidebar-menu-open {
                  0% {
                    transform: translate(0px, -50%) scale(1, 0);
                  }

                  100% {
                    transform: translate(0px, 0%) scale(1, 1);
                  }
                }

                &.show {
                  display: block;
                }

                >li {
                  a {
                    padding-left: 40px !important;
                    font-weight: 400;
                  }
                }

                .submenu-title {
                  .according-menu {
                    top: 7px;
                  }
                }

                &.opensubmenu {
                  display: block;
                }

                li {
                  &:hover {
                    >a {
                      transition: all 0.3s ease;
                    }
                  }

                  .nav-sub-childmenu {
                    display: none;
                    position: relative !important;
                    right: 0;
                    width: 100%;
                    padding: 0;

                    &.opensubchild {
                      display: block;
                    }

                    li {
                      a {
                        padding: 6px 45px;
                        padding-left: 56px !important;

                        &:after {
                          display: none;
                        }
                      }
                    }
                  }

                  a {
                    padding: 9px 25px;
                    font-size: 14px;
                    color: $white;
                    display: block;
                    position: relative;
                    letter-spacing: 0.06em;
                    font-weight: 500;
                    font-family: $public-sans;
                    transition: all 0.3s ease;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 44px !important;

                    &.active {
                      color: $white !important;

                      &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #fff;
                        opacity: 0.1;
                        z-index: -1;
                        border-radius: 4px;
                      }
                    }

                    &:after {
                      left: 20px;
                    }

                    &:hover {
                      margin-left: 0;
                    }
                  }
                }
              }

              &.mega-menu {
                .sidebar-title {
                  &.active {
                    ~.mega-menu-container {
                      display: block !important;
                    }
                  }
                }
              }

              .mega-menu-container {
                padding: 0;
                position: relative;

                &.opensubmenu {
                  display: block;
                }

                .mega-box {
                  width: 100%;
                  padding: 0;

                  .link-section {
                    &:hover {
                      h5 {
                        color: var(--theme-color) !important;
                        transition: all 0.3s ease;

                        &:after {
                          border-top: 2px solid var(--theme-color);
                          transition: all 0.3s ease;
                        }
                      }
                    }

                    .submenu-title {
                      position: relative;

                      h5 {
                        margin-bottom: 0;
                        line-height: 1.9;
                        padding: 6px 25px 6px 45px;
                        font-size: 14px;
                        position: relative;
                        color: rgba(43, 43, 43, 0.6);
                        display: block;
                        letter-spacing: 0.06em;
                        font-weight: 500;
                        font-family: $public-sans;
                        cursor: pointer;

                        &:after {
                          left: 25px;
                          @extend %sidebar-after;
                        }
                      }

                      .according-menu {
                        top: 8px;
                      }
                    }

                    .submenu-content {
                      &.opensubmegamenu {
                        li {
                          a {
                            margin-bottom: 0;
                            line-height: 1.9;
                            padding: 6px 25px 6px 45px;
                            font-size: 14px;
                            position: relative;
                            display: block;
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            font-family: $public-sans;

                            &:after {
                              display: none;
                            }

                            &.active {
                              color: var(--theme-color);
                            }
                          }
                        }
                      }

                      ul {
                        li {
                          a {
                            line-height: 1.9;

                            &:hover {
                              margin-left: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .sidebar-link {
                border-radius: 5px;
                transition: all 0.5s ease-in-out;
                display: block;
                margin-top: 8px;
                z-index: 0;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: $white;
                  opacity: 0;
                  z-index: -1;
                }

                &:hover {
                  background-color: rgba($white, $alpha: 0.06);
                }

                &.active {
                  transition: all 0.5s ease;
                  position: relative;
                  color: $white;

                  &:before {
                    opacity: 0.1;
                  }

                  .according-menu {
                    i {
                      color: $white;
                    }
                  }

                  svg,
                  i {
                    color: $white;
                    transition: all 0.3s ease-in-out;
                  }

                  span {
                    color: $white;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }

      .mega-box {
        &.col {
          flex-basis: unset;
        }
      }

      .according-menu {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        margin-right: 15px;

        i {
          vertical-align: middle;
          color: rgba($white, 0.8);
          font-weight: 600;
        }
      }

      .main-menu {
        &.border-section {
          border: none;
        }

        .menu-left {
          .main-menu-right {
            .toggle-nav {
              position: absolute;
              z-index: 2;
              right: 145px;
              top: 6px;

              .sidebar-name {
                font-size: 18px;
                padding-left: 5px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .page-body {
        min-height: calc(100vh - 80px);
        margin-top: 90px;
        margin-left: 280px;
        background-color: $inner-bg;
        padding-top: 25px;
        padding-bottom: 44px;

        @media (max-width: 1199px) {
          margin-left: 260px;
        }
      }
    }

    &.dark-sidebar,
    &.color-sidebar {
      .toggle-sidebar {
        svg {
          stroke: var(--theme-color);
        }
      }

      div.sidebar-wrapper {
        background-color: $dark-card-background;

        .sidebar-main {
          .sidebar-links {
            li {
              a {
                span {
                  color: $dark-all-font-color;
                  font-weight: 700;
                }
              }
            }

            li {
              .mega-menu-container {
                .mega-box {
                  .link-section {
                    .submenu-title {
                      h5 {
                        color: $dark-all-font-color;
                      }
                    }

                    .submenu-content {
                      &.opensubmegamenu {
                        li {
                          a {
                            color: $dark-all-font-color;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }

                &::after {
                  background-color: $light-all-font-color;
                }
              }

              .sidebar-submenu {
                li {
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
            }

            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }

                    &.active {
                      color: var(--theme-color);
                    }
                  }
                }
              }
            }
          }
        }

        .logo-wrapper {
          .for-light {
            display: none;
          }

          &-center {
            display: flex;
            align-items: center;
          }

          .for-dark {
            display: block;
          }
        }
      }

      .according-menu {
        i {
          color: $dark-all-font-color;
        }
      }

      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }
    }

    &.color-sidebar {
      div.sidebar-wrapper {
        background-color: #2f3c4e;
      }

      .sidebar-main-title {
        p {
          display: none;
        }

        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  // compact icon css
  &.compact-sidebar {
    ~.bg-overlay1 {
      transition: 0.8s;

      &.active {
        z-index: 8;
        height: 100vh;
        width: 100vw;
        background-color: rgba($black, 0.2);
        position: fixed;
        top: 0;
      }
    }

    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }

    .sidebar-main-title {
      display: none;
    }

    .logo-wrapper {
      display: none;
    }

    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 33px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }

    .page-header {
      margin-left: 150px;
      width: calc(100% - 150px);

      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
      }

      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }

    .page-body-wrapper {
      .page-body {
        margin-top: 80px;
        margin-left: 150px;
      }

      .footer {
        margin-left: 0;
      }

      div.sidebar-wrapper {
        position: fixed;
        top: 0;
        z-index: 9;

        >div {
          height: auto;
          line-height: inherit;
          background: $white;
          width: 150px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
        }

        .sidebar-main {

          .left-arrow,
          .right-arrow {
            display: none;
          }

          .sidebar-links {
            margin: 30px 0;
            height: calc(100vh - 113px);
            overflow: auto;

            li {
              a {
                color: $dark-color;

                span {
                  letter-spacing: 0.7px;
                  font-family: $public-sans;
                  text-transform: capitalize;
                  color: $dark-color;
                  font-size: 13px;
                }

                &.active {
                  background: rgba($primary-color, 0.1);
                  color: $primary-color;
                  border-radius: 20px;
                  margin: 0 10px;

                  span {
                    color: $primary-color;
                  }

                  ~.sidebar-submenu,
                  ~.mega-menu-container {
                    animation: fadeInLeft 300ms ease-in-out;
                  }
                }
              }

              .sidebar-title {
                display: block;
                padding: 20px 0;
                border-bottom: 1px solid #f3f3f3;
              }

              .sidebar-submenu,
              .mega-menu-container {
                position: fixed;
                top: 0px;
                height: calc(100vh - 0px);
                overflow: auto;
                left: 150px;
                width: 230px;
                background-color: $white;
                box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                transition: all 0.5s ease-in-out;
                display: none;
                padding-top: 20px;
                z-index: -1;

                li {
                  a {
                    padding: 15px 15px 15px 40px;
                    display: block;
                    position: relative;
                    border-bottom: 1px solid #f1f1f1;

                    .sub-arrow {
                      display: none;
                    }

                    .according-menu {
                      position: absolute;
                      right: 20px;
                      top: 16px;
                    }

                    &.active {
                      background-color: rgba($primary-color, 0.1);
                      color: $primary-color;
                      transition: all 0.3s ease;
                    }
                  }

                  .submenu-content {
                    li {
                      a {
                        &.active {
                          background-color: $transparent-color;
                        }
                      }
                    }
                  }
                }

                >li {
                  >a {
                    &::after {
                      position: absolute;
                      content: "";
                      left: 20px;
                      top: 48%;
                      width: 6px;
                      border-top: 2px solid $white;
                    }
                  }
                }

                .mega-box {
                  flex: 0 0 100%;
                  max-width: 100%;
                  padding: 0;

                  .link-section {
                    text-align: left;

                    .submenu-title {
                      h5 {
                        position: relative;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 15px 15px 15px 40px;
                        letter-spacing: 1px;
                        margin-bottom: 0;

                        &::after {
                          position: absolute;
                          content: "";
                          left: 20px;
                          top: 48%;
                          width: 6px;
                          border-top: 2px solid $white;
                        }
                      }
                    }
                  }
                }
              }
            }

            .sidebar-list {
              position: relative;

              .badge {
                position: absolute;
                left: 53%;
                top: 7px;
                font-weight: 500;
                font-size: 9px;
              }

              .sidebar-link {
                text-align: center;

                svg {
                  display: block;
                  margin: 0 auto 6px;
                }

                >span {
                  display: block;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                }

                .according-menu {
                  display: none;
                }
              }
            }

            .mega-menu {
              text-align: center;
              padding: 0;

              svg {
                display: block;
                margin: 0 auto 6px;
              }

              .according-menu {
                position: absolute;
                right: 20px;
                top: 13px;
              }
            }
          }
        }

        &.close_icon {
          transform: translateX(-150px);

          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  }

  // compact small css
  &.compact-small {
    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }

    .sidebar-main-title,
    .logo-wrapper {
      display: none;
    }

    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 33px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }

    .page-header {
      margin-left: 90px;
      width: calc(100% - 90px);

      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
      }

      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }

    .page-body-wrapper {
      .page-body {
        margin-left: 90px;
      }

      .footer {
        margin-left: 90px;
      }

      div.sidebar-wrapper {
        >div {
          width: 90px;
        }

        .sidebar-main {
          .sidebar-links {
            li {
              .sidebar-title {
                display: block;
                padding: 15px 0;
              }

              .sidebar-submenu,
              .mega-menu-container {
                left: 90px;

                li {
                  a {
                    padding: 10px 15px 10px 40px;

                    .sub-arrow {
                      display: none;
                    }

                    .according-menu {
                      top: 11px;
                    }
                  }
                }
              }
            }

            .sidebar-list {
              .badge {
                display: none;
              }

              .sidebar-link {
                svg {
                  display: block;
                  margin: 0 auto;
                  width: 20px;
                }

                >span {
                  display: none;
                }
              }
            }

            .mega-menu {
              svg {
                margin: 0 auto;
              }

              a {
                >span {
                  display: none;
                }
              }
            }
          }
        }

        &.close_icon {
          transform: translateX(-150px);

          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  }

  // only body css
  &.only-body {
    background-color: #f2f0fa;

    .page-body-wrapper {
      background-color: #f2f0fa;
    }

    .page-header {
      position: relative;

      .nav-menus {
        .profile-nav {
          .media-body {

            >span,
            >p {
              color: $white !important;
            }
          }
        }

        >li {
          >svg {
            stroke: $white;
          }

          >a {
            >svg {
              stroke: $white;
            }
          }

          >div,
          >span {
            .lang-txt {
              color: $white;
            }

            i {
              color: $white;
            }

            svg {
              stroke: $white;
            }
          }
        }
      }

      .left-header,
      .header-logo-wrapper {
        display: none;
      }

      .header-wrapper {
        padding: 0 !important;

        .nav-right {
          position: absolute;
          right: 60px;
          top: 58px;
        }

        .search-full {
          top: 37px;
          width: calc(100% - 50px);
          left: 25px;

          .form-group {
            .Typeahead {
              .u-posRelative {
                background-color: $white;
                border-radius: 15px;
              }
            }
          }

          .Typeahead-menu {
            top: 78px;
            width: calc(100% - 30px);
            margin: 0 auto;
            right: 0;
          }
        }
      }
    }

    .page-body-wrapper {
      .breadcrumb-item+.breadcrumb-item::before {
        color: $white;
      }

      .page-title {
        padding-top: 20px;
        padding-bottom: 20px;

        .breadcrumb {
          li {
            color: $white;
          }
        }

        .breadcrumb-item {
          a {
            svg {
              color: $white;
            }
          }
        }

        .row {
          h3 {
            color: $white;
          }
        }
      }
    }

    .sidebar-wrapper,
    footer {
      display: none;
    }

    .page-body-wrapper {
      .page-body {
        margin-top: 0px;
        min-height: calc(100vh - 0px);
      }

      .page-title {
        background: linear-gradient(26deg, var(--theme-color) 0%, #a26cf8 100%);
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 30px;
        border-radius: 15px;
        margin-top: 30px;

        >.row {
          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        .breadcrumb {
          margin-top: 5px;
          justify-content: flex-start;
        }
      }
    }
  }

  // modern css
  &.modern-type {
    .ecommerce-widget {
      border: 1px solid lighten($primary-color, 22%) !important;
    }

    .theme-form input[type="text"],
    .theme-form input[type="email"],
    .theme-form input[type="search"],
    .theme-form input[type="password"],
    .theme-form input[type="number"],
    .theme-form input[type="tel"],
    .theme-form input[type="date"],
    .theme-form input[type="datetime-local"],
    .theme-form input[type="time"],
    .theme-form input[type="datetime-local"],
    .theme-form input[type="month"],
    .theme-form input[type="week"],
    .theme-form input[type="url"],
    .theme-form input[type="file"],
    .theme-form select,
    textarea {
      background-color: $input-bg-color;
    }

    .file-sidebar .pricing-plan {
      border: 1px solid lighten($primary-color, 22%) !important;
    }

    .alert-primary {
      color: $primary-color;
    }

    .project-box {
      background-color: lighten($primary-color, 22%);
    }

    .nav-tabs .nav-link.active {
      background-color: transparent;
    }

    .activity-timeline .media .activity-line {
      background-color: lighten($primary-color, 22%);
    }

    .simplebar-mask {
      top: 10px;
    }

    .chart_data_left,
    .chart_data_right {
      .card-body {
        .chart-main {
          >div {
            &:last-child {
              .media {
                border-right: none !important;
              }
            }
          }
        }
      }
    }

    .page-header {
      margin-left: 0px;
      width: calc(100% - 0px);

      &.close_icon {
        margin-left: 0px;
        width: calc(100% - 0px);
      }

      .header-wrapper {
        .header-logo-wrapper {
          display: flex;
          flex: 1 0 auto;
          align-items: center;
        }

        .logo-wrapper {
          display: block;
          margin-right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
          width: calc(94px + (120 - 94) * ((100vw - 320px) / (1920 - 320)));
        }

        .toggle-sidebar {
          display: block;
          position: relative;
          top: unset;
          right: unset;
          margin-right: 15px;
        }
      }
    }

    .page-body-wrapper {
      div.sidebar-wrapper {
        top: 108px;
        background-color: $black1;

        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }

        .sidebar-main {
          .sidebar-links {
            height: calc(100vh - 160px);
          }
        }

        &.close_icon {
          .logo-icon-wrapper {
            display: none;
          }

          ~.page-body {
            margin-left: 0px;
          }

          ~footer {
            margin-left: 0px;
          }

          &:hover {
            .logo-wrapper {
              display: none;
            }
          }
        }
      }

      .page-body {
        margin-left: 310px;
      }
    }
  }

  // material css
  &.material-type {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 300px;
      background-image: $gradient-primary;
    }

    .card {
      box-shadow: 0 0 20px rgba($black, 0.05);
    }

    .page-header {
      position: relative;
      max-width: 1560px;
      margin: 50px auto 0;
      border-radius: 15px 15px 0 0;
    }

    .page-body-wrapper {
      background-image: url(/assets/images/other-images/boxbg.jpg);
      background-blend-mode: overlay;
      background-color: rgba($white, 0.5);

      .sidebar-wrapper {
        position: relative;
        max-width: 1560px;
        margin: 0 auto;
        top: unset;
        height: unset;
      }

      .page-body {
        margin: 0 auto;
        max-width: 1560px;
        background-color: $body-color;
        box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
        border-radius: 0 0 15px 15px;
      }
    }

    .footer {
      margin-top: 40px;
    }
  }

  &.advance-layout {
    background-blend-mode: overlay;
    background-color: rgba($body-color, 0.7);

    .card {
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid $light-gray;
    }

    .page-header {
      position: relative;
      background-color: $white;
      margin: 0 auto;
    }

    .page-body-wrapper {
      background: transparent;

      .sidebar-wrapper {
        position: relative !important;
        top: unset !important;
        height: unset;
        box-shadow: none;

        border {
          top: 1px solid $light-semi-gray;
          bottom: 1px solid $light-semi-gray;
        }

        >div {
          overflow-x: hidden;
        }
      }

      .page-body {
        margin-top: 0 !important;
      }
    }
  }
}

@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }

  50% {
    opacity: 1;
  }
}

@media screen and (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {
                .mega-menu-container {
                  right: -18%;
                  width: 91%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {
                .mega-menu-container {
                  right: -54%;
                  width: 75%;
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-wrapper {
    .sidebar-main {
      .sidebar-links {
        li {
          margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {
                .mega-menu-container {
                  right: -49%;
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;

      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          >ul {
            >li {
              padding: 6px;
            }
          }
        }
      }
    }
  }

  .left-header .mega-menu-container .submenu-title {
    margin: 20px 20px 0 20px;
  }

  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid $gray-light;
      margin: 0 -15px;

      h5 {
        color: var(--theme-color);
      }

      svg {
        stroke: var(--theme-color) !important;
      }
    }
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              li {
                .mega-menu-container {
                  right: -35%;
                  width: 56%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
    }
  }

  .responsive-btn {
    display: none;
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }

            li {
              .sidebar-submenu {
                width: 200px;
                opacity: 0;
                visibility: hidden;
                border-radius: 5px;
                transition: all 0.5s ease;

                a {
                  .sub-arrow {
                    position: absolute;
                    right: 20px;
                    top: 5px;
                    color: $light-semi-font;
                    font-weight: 100;
                    transition: all 0.5s ease;
                    font-size: 15px;
                    top: 5px;
                  }
                }

                li {
                  .nav-sub-childmenu {
                    display: none;
                    border-radius: 4px;
                  }

                  &:hover {
                    .nav-sub-childmenu {
                      display: block;
                      position: absolute;
                      left: 200px;
                      background-color: $white;
                      top: -10px;
                      padding: 15px 0px 15px 0px;
                      box-shadow: 0 0 1px 0 $dark-gray;
                    }
                  }

                  a {
                    &:hover {
                      .sub-arrow {
                        right: 16px;
                        transition: all 0.5s ease;
                      }
                    }
                  }
                }
              }

              .mega-menu-container {
                opacity: 0;
                visibility: hidden;
                transition: all 0.5s ease;
              }

              &.hoverd {
                .sidebar-submenu {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 8px;
                  transition: all 0.5s ease;
                }

                .mega-menu-container {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 20px;
                  transition: all 0.5s ease;
                }
              }
            }
          }
        }

        .toggle-nav {
          display: none;
        }

        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {

  // material icon css
  .page-wrapper {
    &.material-icon {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 240px;
        background: $gradient-primary;
      }

      .toggle-sidebar {
        color: rgba($white, 0.8);

        &:hover {
          >svg {
            color: $white !important;
            stroke: $white !important;
          }
        }
      }

      .left-header {

        .mega-menu,
        .level-menu {
          .nav-link {
            background-color: rgba($white, 0.09);
            color: rgba($white, 0.7);

            svg {
              stroke: rgba($white, 0.7);
            }
          }
        }
      }

      .page-header {
        position: relative;
        background: transparent;
        padding-left: 90px;
        max-width: 1660px;
        margin: 0 auto;
        width: calc(100% - 0px);

        .header-wrapper {
          .nav-right {
            >ul {
              >li {
                color: $white;

                .notification-box,
                .cart-box {
                  svg {
                    stroke: $white;
                  }
                }

                >a {
                  >svg {
                    stroke: $white;
                  }
                }

                >svg {
                  stroke: $white;
                }
              }
            }

            .language-nav {
              .more_lang {
                color: $theme-body-font-color;
              }
            }

            .onhover-show-div {
              color: $theme-body-font-color;
            }

            &.right-header {
              ul {
                li {
                  .profile-media {
                    .media-body {
                      p {
                        color: rgba($white, 0.7);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.close_icon {
          padding-left: 0;
          margin: 0 auto !important;
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-top: 0 !important;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-left: 15px;
              margin: 0 auto !important;
            }
          }
        }

        .page-title {
          color: $white;

          .breadcrumb {
            .breadcrumb-item {
              a {
                svg {
                  color: $white;
                }
              }

              &.active {
                color: rgba($white, 0.6);
              }

              +.breadcrumb-item {
                &::before {
                  color: rgba($white, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

.active-color {
  a {
    &:active {

      svg,
      span {
        color: #0000ff !important;
      }
    }
  }
}

.cke_1 {
  border: 1px solid $light-semi-gray !important;
}

.user-round {
  width: calc(38px + (40 - 38) * ((100vw - 320px) / (1920 - 320)));
  height: calc(38px + (40 - 38) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  margin: 0 auto;

  h4 {
    font-weight: 600;
  }
}

.border-table {
  border: 1px solid #eee;
  border-radius: 8px;

  .no-data-added {
    text-align: center;
    padding: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320))) 0;

    @media (max-width: 576px) {
      text-align: left;

      [dir="rtl"] & {
        text-align: right;
      }
    }
  }
}

.sidebar-submenu {
  li {
    padding: 0 !important;
  }
}

.sidebar-links {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(200, 203, 203, 0);
    background-color: rgba(255, 255, 255, 0.34);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s ease;
    }
  }
}

.apexcharts-tooltip {

  .apexcharts-tooltip-goals-group,
  .apexcharts-tooltip-z-group {
    display: none;
  }

  .apexcharts-tooltip-series-group {
    padding-top: 11px;

    &:last-child {
      padding-bottom: 11px;
    }
  }

  .apexcharts-tooltip-y-group {
    padding: 0;
  }
}

/**=====================
      Sidebar CSS Ends
  ==========================**/