/**=====================
    Wallet CSS start
==========================**/
.wallet-sec {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 1511px) {
        display: grid;
        grid-template-columns: auto 1fr;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
    }
    input {
        width: 40%;
        @media (max-width: 1511px) {
            width: 100%;
        }
    }
    .wallet-amount {
        width: 25%;
        display: flex;
        align-items: center;
        gap: 15px;
        @media (max-width: 1511px) {
            width: auto;
        }
        h5 {
            margin-top: 5px;
        }
        h2 {
            font-size: 22px;
            font-weight: 500;
        }
    }
    .wallet-icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 40px;
            color: var(--theme-color);
        }
    }
    .btn-sec {
        display: flex;
        align-items: center;
        gap: 15px;
    }
}

.withdraw-btn {
    display: flex;
    align-items: center;
    gap: 15px;
    h3 {
        color: #555 !important;
        font-weight: 400;
        width: calc(100% - 50px - 15px);
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .plus-icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f9f9f6;
        border-radius: 5px;
        i {
            font-size: 40px;
            color: var(--theme-color);
        }
    }
}

.pending-balance-sec {
    position: relative;
    z-index: 1;
    &:after {
        content: "";
        background-image: url("../../images/withdraw-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.1;
    }
}

.wallet-search {
    display: flex;
    align-items: center;
    gap: 15px;
    .custom-select {
        width: 75%;
    }
    .btn-animation {
        width: calc(25% - 15px);
    }
}


.vendor-wallet-table {
    .custom-dt-picker {
        input {
            padding-right: 10px !important;
            width: 130px;
        }
    }
}