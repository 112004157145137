/**=====================
    Dashboard CSS start
==========================**/
.dashboard-tiles {
    margin-bottom: 30px;
    .card-tiles {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        background-color: white;
        padding: 22px 13px;
        border-radius: 10px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
            height: 35px;
            background-color: var(--theme-color);
            border-radius: 5px;
            [dir="rtl"] & {
                left: unset;
                right: 0;
            }
        }
        h6 {
            color: rgba($content-color, 0.8);
        }
        h3 {
            color: $title-color;
            margin-top: 8px;
            font-weight: 700;
            font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        .icon-box {
            width: 52px;
            height: 52px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                color: $white;
                font-size: 22px;
            }
        }
    }
    .row {
        > div {
            &:first-child {
                .card-tiles {
                    background: rgba(#0DA487, 0.1);
                    &:after {
                        background-color: #0DA487;
                    }
                    .icon-box {
                        background: #0DA487;
                    }
                }
            }
            &:nth-child(2) {
                .card-tiles {
                    background: rgba(#FFA53B, 0.1);
                    &:after {
                        background-color: #FFA53B;
                    }
                    .icon-box {
                        background: #FFA53B;
                    }
                }
            }
            &:nth-child(3) {
                .card-tiles {
                    background: rgba(#A04AFF, 0.1);
                    &:after {
                        background-color: #A04AFF;
                    }
                    .icon-box {
                        background: #A04AFF;
                    }
                }
            }
            &:nth-child(4) {
                .card-tiles {
                    background: rgba(#FF6161, 0.1);
                    &:after {
                        background-color: #FF6161;
                    }
                    .icon-box {
                        background: #FF6161;
                    }
                }
            }
        }
    }
}

.review-box {
    border: 1px solid #eee;
    border-radius: 8px;
    table {
        width: 100%;
        tr {
            + tr {
                border-top: 1px dashed #eee;
            }
        }
    }
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 15px;
        }
        .rating-sec {
            span {
                font-size: 16px;
            }
        }
        + li {
            margin-top: 7px;
            padding-top: 7px;
            border-top: 1px dashed #eee;
        }
        >div {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .review-content {
        display: flex;
        align-items: center;
        gap: 10px;
        span{
            width: calc(100% - 5px - 40px);
        }
    }

    td {
        padding: 8px;
    }

    tr {
       td {
        &:nth-child(2) {
            width: auto;
            min-width: 190px;
            text-align: center;
        }
        &:nth-child(1) {
            width: auto;
            min-width: 250px;
        } 
       }
    }

    .img-box {
        width: 40px;
        height: 40px;        
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E9EAEA;
        padding: 2px;
        img {
            object-fit: contain;
            height: 100%;
        }
    }
    .rating-sec {
        font-size: 17px;
        gap: 0px;
    }
}

.apexcharts-toolbar {
    z-index: 1 !important;
}

.top-selling-table {
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 8px;
    table {
        width: 100%;
        tr {
            + tr {
                td {
                    border-top: 1px solid #eee;
                }
            }
        }
    }
    td {
        padding: 10px;
    }
    .img-info {
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 192px;
        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
        }
    }
    h6 {
        color: rgba($theme-body-font-color, 0.7);
    }

    h5 {
        color: $theme-body-font-color;
        margin-top: 5px;
        font-weight: 600;
    }
}

.blog-box {
    .blog-img {
        img {
            height: 180px;
            border-radius: 8px;
            margin-bottom: 10px;
            object-fit: cover;
            width: 100%;
        }
    }
    .blog-content {
        a {
            color: $title-color;
            font-size: 16px;
            margin-bottom: 4px;
            display: block;
            font-weight: 500;
            letter-spacing: 0.01em;
        }
        h6 {
            color: rgba($content-color, 0.7);
        }
    }
}